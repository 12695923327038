var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    [
      _c(
        "v-list",
        { staticClass: "parcela-stages" },
        _vm._l(_vm.stages, function (stage) {
          return _c(
            "v-list-item",
            { key: stage.chave },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [_vm._v(_vm._s(stage.chave))]),
                  _c("v-list-item-subtitle", { staticClass: "text-left" }, [
                    _c("pre", [
                      _c("code", [_vm._v(_vm._s(_vm.filterEmpty(stage)))]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }