
import { Component, Vue, Prop } from 'vue-property-decorator'
import MenuApp from './MenuApp.vue'
import AccountInfo from './AccountInfo.vue'

@Component({
  components: { MenuApp, AccountInfo }
})
export default class Navigation extends Vue {
  @Prop()
  public drawer!: boolean
}
