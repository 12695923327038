var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "red--text",
                          attrs: { small: "", icon: "" },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: _vm.parcela.adimplente ? "green" : "orange ",
                          },
                        },
                        [_vm._v("mdi-account-cash")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _vm.parcela.pagamento
            ? _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$n(_vm.parcela.pagamento.valor, "currency")
                        ) +
                        " "
                    ),
                  ]),
                  _c("v-card-subtitle", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(_vm.parcela.pagamento.data, "L")
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("input-upload", {
                                attrs: {
                                  id: _vm.parcela.id,
                                  model:
                                    "\\Desize\\PlanoRecuperacao\\Models\\PlanoValoresParcelas",
                                  colecao: "arquivos",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [_vm._v(" Cancelar ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.submit()
                            },
                          },
                        },
                        [_vm._v(" Estornar ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }