var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "hide-overlay": "", transition: "dialog-bottom-transition" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "ml-2",
                      attrs: { color: "primary", dark: "" },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" Arquivos ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v("Arquivos")]),
            ],
            1
          ),
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _vm.messagemErro
                      ? _c("v-alert", { attrs: { type: "error" } }, [
                          _vm._v(" " + _vm._s(_vm.messagemErro) + " "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _c("dashboard", {
                      attrs: {
                        uppy: _vm.uppy,
                        plugins: [],
                        props: { ..._vm.options },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  [
                    _vm.arquivos
                      ? _c("v-data-table", {
                          staticClass: "elevation-1",
                          attrs: {
                            headers: _vm.headers,
                            items: _vm.arquivos,
                            "items-per-page": 20,
                            "item-key": "name",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: `item.file_name`,
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: item.url,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.file_name) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: `item.actions`,
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "red--text",
                                        attrs: {
                                          small: "",
                                          icon: "",
                                          target: "_blank",
                                          title: "Excluir",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deletar(item)
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-delete")])],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }