
import { Component, Prop, Vue } from "vue-property-decorator";
import InputUpload from "@/components/form/InputUpload.vue";
import planoRecuperacaoService from "@/services/plano-recuperacao/PlanoRecuperacaoService";
@Component({
  components: { InputUpload },
})
export default class ModalPagar extends Vue {
  @Prop()
  public dialog;

  @Prop()
  public parcela;

  @Prop()
  public valores;

  public async submit() {
    const estorno = await planoRecuperacaoService.estornar(
      this.parcela.pagamento
    );
    this.valores.parcelas = estorno.context;
    this.dialog = false;
  }
}
