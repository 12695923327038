/** @format */

import { Bar, mixins } from "vue-chartjs";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ["options"],
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.addPlugin(ChartJSPluginDatalabels);
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    chartData () {
      this.$data._chart.update()
    }
  }
};
