
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import SelectClasses from '@/components/form/select/SelectClasses.vue'
import { Processo } from '@/services/processo/ProcessoTypes'
import { DefaultSelect } from '@/common/Type'
import SelectAPartirDe from '@/components/form/select/SelectAPartirDe.vue'
import SelectTipoIndiceJuros from '@/components/form/select/SelectTipoIndiceJuros.vue'
import SelectPeriodicidades from '@/components/form/select/SelectPeriodicidades.vue'
import SelectVencimento from '@/components/form/select/SelectVencimento.vue'
import FormatterHelper from '@/helper/FormatterHelper'
import PlanoRecuperacaoService from '@/services/plano-recuperacao/PlanoRecuperacaoService'
import {eventBus} from '@/main'
import SelectCondicoes from '@/components/form/select/SelectCondicoes.vue'
import DateHelper from '@/helper/DateHelper'
import RDate from '@/components/form/Date.vue'
import moment from "moment";

@Component({
  components: {RDate, SelectCondicoes, SelectPeriodicidades, SelectTipoIndiceJuros, SelectAPartirDe, SelectClasses, SelectVencimento},
})
export default class FormCondicaoPagamento extends Vue {

  @Prop()
  public value!: any

  @Prop({default: null})
  public condicaoUpdate!: any

  @Prop()
  public processo!: Processo

  public metadataRampa : any[] = []

  public lazy: boolean = false
  public showSelectSaldo: boolean = false
  public apartir: string = ''
  public apartirJuros: string = ''
  public apartirCorrecao: string = ''

  public datasAPartirDe: string[] = [];

  public showDataManualParcelas: boolean = false
  public showDataManualJuros: boolean = false
  public showDataManualCorrecao: boolean = false

  //public condicaoDefault: any = {...this.condicao}
  public condicaoDefault: any = {
    data_parcelas: '',
    data_juros: '',
    data_correcao: '',
    descricao: '',
    prazo: 1,
    carencia: 1,
    parcelas: 1,
    periodicidade_id: null,
    dia_vencimento: 'homologacao',
    id_condicao: null,
    desagio: 0.00,
    tp_juros_indice: '',
    juros_indice: 0.00,
    juros_mes: 0.00,
    apartir: '',
    juros_indice_apartir: '',
    juros_mensal_apartir: '',
    classes: [],
    pagamento: {
      tipo: 'price',
      metadata: {
        rampa: {},
        bonusAdimplencia: {
          tipo: '',
          percentual: 0,
          parcelas: []
        }
      }
    },
    vl_limite_ate: 0,
    vl_limite_entre_inicio: 0,
    vl_limite_entre_fim: 0,
    vl_limite_acima: 0,
    id_condicao_saldo_limite: null,
    operador: '',
    valor_unico: true,
  }

  public condicao: any = {...this.condicaoDefault}

  public calendario: any = []

  public periodicidadeSelecionada: string = ''
  public vencimentoSelecionada: string = ''



  public operadoresPagamento: DefaultSelect[] = [
    { label: 'Até', value: 'Até' },
    { label: 'Entre', value: 'Entre' },
    { label: 'Acima de', value: 'Acima de' },
  ]

  public tiposPagamento: DefaultSelect[] = [
    { label: 'Price', value: 'price' },
    { label: 'SAC', value: 'sac' },
    { label: 'Escalonado', value: 'rampa' },
  ]

  public bonusAdimplencia: DefaultSelect[] = [
    { label: 'Sem bônus', value: 0 },
    { label: 'Saldo devedor', value: 1 },
    { label: 'Parcela saldo devedor', value: 2 },
  ]

  public rules: any = {
    classes: [
      (v: DefaultSelect[]) : any => v.length > 0 || 'Selecione pelo menos uma Classe',
    ],
    periodicidade_id: [
      (v: number) : any => !!v || 'Selecione uma Periodicidade',
    ],
    dia_vencimento: [
      (v: number) : any => !!v || 'Selecione um dia de vencimento',
    ],
    id_condicao: [
      (v: number) : any => !!v || 'Selecione uma Condição de Pagamento Alternativa',
    ],
    descricao: [
      (v: string) : any => !!v || 'Informe a Descrição do Plano',
      (v: string) : any => String(v).length <= 255 || 'Máximo de 255 caracteres',
    ],
    prazo: [
      (v: number) : any => !!v || 'Informe o Prazo em Meses',
      (v: number) : any => (!isNaN(parseFloat(v.toString())) && v >= 1 && v <= 999) || 'Apenas números entre 1 e 999',
    ],
    carencia: [
      (v: number) : any => !!v || 'Informe a Carência em Meses',
      (v: number) : any => (!isNaN(parseFloat(v.toString())) && v >= 0 && v <= 999) || 'Apenas números entre 0 e 999',
    ],
    parcelas: [
      (v: number) : any => !!v || 'Informe a quantidade de Parcelas',
      (v: number) : any => (!isNaN(parseFloat(v.toString())) && v >= 1 && v <= 999) || 'Apenas números entre 1 e 999',
    ],
    /*
    tp_juros_indice: [
      (v: string) : any => !!v || 'Selecione um Tipo de Índice',
    ],
     */
    juros_indice: [
      (v: string) : any => !!v || 'Informe o Juros Percentual',
      (v: string) : any => {let f = FormatterHelper.valorBrToDecimal(v ?? '0'); return (!isNaN(f) && f >= 0 && f <= 100 || 'Percentual inválido')},
    ],
    juros_mes: [
      (v: string) : any => !!v || 'Informe o Índice de Juros Mensal',
      (v: string) : any => {let f = FormatterHelper.valorBrToDecimal(v ?? '0'); return (!isNaN(f) && f >= 0 && f <= 100 || 'Percentual inválido')},
    ],
    desagio: [
      (v: string) : any => !!v || 'Informe o Índice de Deságio',
      (v: string) : any => {let f = FormatterHelper.valorBrToDecimal(v ?? '0'); return (!isNaN(f) && f >= 0 && f <= 100 || 'Percentual inválido')},
    ],
    gerenic_date: [
      (v: string) : any => !!v || 'Informe uma data',
      (v: string) : any => DateHelper.isValidDate(moment(v, 'DD/MM/YYYY').format('YYYY-MM-DD')) || 'Data inválida'
    ],
  }
  public created() : void {
    eventBus.$on('resetFormCondicao', this.resetForm)
  }

  public mounted(): void {
    this.atribuirPropriedadeForm()
    this.form()
    this.$nextTick(() => {
      this.resetForm()
    });
    
  }

  public atribuirPropriedadeForm(): void {
    for (const prop in this.condicao) {
      Vue.set(this.value, prop, this.condicao[prop])
    }
  }

  public calcularPrazo(): void {
    this.condicao.parcelas = this.condicao.prazo - this.condicao.carencia
  }

  public periodicidadeChanged(periodicidade: DefaultSelect[]): void {
    this.periodicidadeSelecionada = periodicidade[0].label
  }

  public vencimentoChanged(vencimento: DefaultSelect[]): void {
    this.vencimentoSelecionada = vencimento[0].label
  }
  

  public montarTextoPlanoConfiguracao(): string {

    const nomeDatas = [
      'data do pedido',
      'data do deferimento',
      'data da concessão'
    ];

    const jurosMesApartir = this.apartir === 'NA' ? 'de ' + moment(this.condicao.apartir).format("LL") : 'da ' + nomeDatas[this.datasAPartirDe.indexOf(this.apartir)]

    const condicionaisString: any = [
      {
        condicao: this.condicao.carencia > 0,
        mensagem: 'Após transcorridos ' + this.condicao.carencia + ' meses de carência'
      },
      {
        condicao: this.condicao.parcelas > 0,
        mensagem: ', o pagamento será realizado em ' + this.condicao.parcelas + ' parcelas mensais, iguais e sucessivas'
      },
      {
        condicao: this.condicao.juros_mes !== 0.00,
        mensagem: '. Sobre o saldo devedor incidirão juros de ' + FormatterHelper.numberToPercent(this.condicao.juros_mes) + ' ao mês'
      },
      {
        condicao: !(this.condicao.apartir === '' && this.condicao.apartir !== null),
        mensagem: ', computados a partir ' + jurosMesApartir
      },
      {
        condicao: this.periodicidadeSelecionada !== '',
        mensagem: '. O adimplemento dos juros será de forma ' + this.periodicidadeSelecionada + ', inclusive durante o período de carência.'
      }
    ]

    let str = ''
    for (const i of condicionaisString) {
      if (i.condicao) {
        str += i.mensagem
      }
    }

    return str
  }

  @Watch('condicao.descricao')
  public onChangeDescricao(descricao: string): void {
    this.onChangeForm('descricao', descricao)
  }

  @Watch('condicao.classes')
  public onChangeClasses(classes: DefaultSelect): void {
    this.onChangeForm('classes', classes)
  }

  @Watch('condicao.parcelas')
  public onChangeParcelas(parcelas: number): void {
    this.onChangeForm('parcelas', parcelas)
  }

  @Watch('condicao.prazo')
  public onChangePrazo(prazo: number): void {
    this.onChangeForm('prazo', +prazo)
  }

  @Watch('condicao.carencia')
  public onChangeCarencia(carencia: number): void {
    this.onChangeForm('carencia', +carencia)
  }

  @Watch('condicao.juros_mes')
  public onChangeJurosMes(juros_mes: number): void {
    this.onChangeForm('juros_mes', juros_mes / 100)
  }

  @Watch('condicao.desagio')
  public onChangeDesagio(desagio: number): void {
    this.onChangeForm('desagio', desagio / 100)
  }

  @Watch('condicao.juros_indice')
  public onChangeJurosIndice(juros_indice: number): void {
    this.onChangeForm('juros_indice', juros_indice / 100)
  }

  @Watch('condicao.tp_juros_indice')
  public onChangeTPJurosIndice(tp_juros_indice: string): void {
    this.onChangeForm('tp_juros_indice', tp_juros_indice)
  }

  @Watch('condicao.periodicidade_id')
  public onChangePeriodicidade(periodicidade_id: number): void {
    this.onChangeForm('periodicidade_id', periodicidade_id)
  }

  @Watch('condicao.dia_vencimento')
  public onChangeDiaVencimento(dia_vencimento: number): void {
    this.onChangeForm('dia_vencimento', dia_vencimento)
  }

  @Watch('condicao.dia_vencimento')
  public onChangeVencimento(vencimento: number): void {
    this.onChangeForm('dia_vencimento', vencimento)
  }

  @Watch('condicao.id_condicao')
  public onChangeCondicaoAlternativa(id_condicao: number): void {
    this.onChangeForm('id_condicao', id_condicao)
  }

  @Watch('condicao.vl_limite_ate')
  public onChangeLimitePagamento(vl_limite_ate: string): void {
    this.onChangeForm('vl_limite_ate', vl_limite_ate)
  }

  @Watch('condicao.id_condicao_saldo_limite')
  public onChangeIdCondicaoSaldoLimite(id_condicao_saldo_limite: number): void {
    this.onChangeForm('id_condicao_saldo_limite', id_condicao_saldo_limite)
  }

  @Watch('condicao.vl_limite_entre_inicio')
  public onChangeVlLimiteEntreInicio(vl_limite_entre_inicio: number): void {
    this.onChangeForm('vl_limite_entre_inicio', vl_limite_entre_inicio)
  }

  @Watch('condicao.vl_limite_entre_fim')
  public onChangeVlLimiteEntreFim(vl_limite_entre_fim: number): void {
    this.onChangeForm('vl_limite_entre_fim', vl_limite_entre_fim)
  }

  @Watch('condicao.vl_limite_acima')
  public onChangeVlLimiteAcima(vl_limite_acima: number): void {
    this.onChangeForm('vl_limite_acima', vl_limite_acima)
  }

  @Watch('condicao.operador')
  public onChangeOperador(operador: number): void {
    this.onChangeForm('operador', operador)
  }

  //TODO verificar quem ativa o watcher quando edita uma condição
  @Watch('condicao.apartir')
  public onChangeJurosMesAPartir(newValue: string, oldValue: any): void {
    const key = 'apartir';
    if (newValue === '' && oldValue !== '') {
      newValue = (this.datasAPartirDe.includes(oldValue) ? '' : oldValue)
      this.condicao[key] = newValue;
    }
    this.onChangeForm(key, newValue)
  }

  //TODO verificar quem ativa o watcher quando edita uma condição
  @Watch('condicao.juros_indice_apartir')
  public onChangeJurosIndiceAPartir(newValue: string, oldValue: any): void {
    const key = 'juros_indice_apartir';
    if (newValue === '' && oldValue !== '') {
      newValue = (this.datasAPartirDe.includes(oldValue) ? '' : oldValue)
      this.condicao[key] = newValue;
    }
    this.onChangeForm(key, newValue)
  }

  //TODO verificar quem ativa o watcher quando edita uma condição
  @Watch('condicao.juros_mensal_apartir')
  public onChangeJurosIndicePercentualAPartir(newValue: string, oldValue: any): void {
    const key = 'juros_mensal_apartir';
    if (newValue === '' && oldValue !== '') {
      newValue = (this.datasAPartirDe.includes(oldValue) ? '' : oldValue)
      this.condicao[key] = newValue;
    }
    this.onChangeForm(key, newValue)
  }

  @Watch('condicao.pagamento.tipo')
  public onChangePagamento(): void {
    this.onChangeForm('pagamento', this.condicao.pagamento)
  }

  @Watch('condicao.valor_unico')
  public onChangeValorUnico(valor_unico: boolean): void {
    this.onChangeForm('valor_unico', valor_unico)
  }

  @Watch('metadataRampa', { deep: true })
  public onChangeMetadataRampa(metadataRampa : any[])
  {
    this.condicao.pagamento.metadata.rampa = this.arrayToMetadata(this.metadataRampa);
    this.onChangeForm('pagamento', this.condicao.pagamento)

    if(metadataRampa.length > 0) {
      this.$nextTick(() => {
        (this.$refs.form as Vue & { validate: () => boolean }).validate()
      });
    }
  }
  
  public setParcelas(): void {
    this.condicao.pagamento.metadata.bonusAdimplencia.parcelas = this.parcelas
  }

  public onChangeForm(prop: string, value: string | any): void {
    Vue.set(this.value, prop, value)
    this.getCalendario()
  }

  //Computed
  get sumOfMetadata() : number {
    return this.metadataRampa.reduce((p, c) => p + c.valor, 0);
  }

  public atualizaTipoPagamento(): void {
    if (this.condicao.pagamento.tipo !== 'rampa') {
      this.condicao.pagamento.metadata.rampa = {}
      this.metadataRampa = [];
      return
    }

    let date = moment(this.condicao.apartir)
      .add(parseInt(this.condicao.carencia), 'months')
      .startOf('month')

    let first = date.year()
    let last = date.clone().add(parseInt(this.condicao.parcelas), 'months').year()

    let years: string[] = Array.from({ length: (last - first + 1) },(v, k) => (first + k).toString())

    for (const year of Object.keys(this.condicao.pagamento.metadata.rampa)) {
      if (!years.includes(year)) {
        delete this.condicao.pagamento.metadata.rampa[year]
      }
    }
    for (const year of years) {
      if (typeof this.condicao.pagamento.metadata.rampa[year] === 'undefined') {
        this.condicao.pagamento.metadata.rampa[year] = 0
      }
    }
    this.metadataRampa = this.metadataToArray(this.condicao.pagamento.metadata.rampa);
  }

  @Emit()
  public form(): any {
    return this.$refs.form
  }

  public arrayToMetadata(array: any[]): any {
    let object = {};
    array.forEach(function(item) {
      object[item.ano] = item.valor / 100
    })
    return object;
  }


  public metadataToArray(metadata: {[key: number]: any}): any[] {
    return Object.entries(metadata).map(([ano, valor]) => {
      return {
        ano: ano,
        valor: valor * 100
      }
    })
  }

  public resetForm(): void {

    if (this.condicaoUpdate !== null) {
      this.condicao = {
        ...this.condicaoUpdate,
        pagamento: {
          ...this.condicaoUpdate.pagamento
        }
      }
    } else {
      this.condicao = {
        ...this.condicaoDefault,
        pagamento: {
          ...this.condicaoDefault.pagamento
        }
      }
    }

    this.metadataRampa = this.metadataToArray(this.condicao.pagamento.metadata.rampa);
    

    this.condicao.desagio = this.condicao.desagio * 100;
    this.condicao.juros_mes = this.condicao.juros_mes * 100;
    this.condicao.juros_indice = this.condicao.juros_indice * 100;

    this.rules.select_a_partir_de = [
      (v: any) => [this.processo.data_pedido, this.processo.data, this.processo.data_concessao, 'NA'].includes(v) || 'Campo obrigatório',
    ]

    this.datasAPartirDe = [
      moment(this.processo.data_pedido).format('YYYY-MM-DD'),
      moment(this.processo.data).format('YYYY-MM-DD'),
      moment(this.processo.data_concessao).format('YYYY-MM-DD'),
      ''
    ];

    this.apartir = (this.datasAPartirDe.includes(this.condicao.apartir) ? this.condicao.apartir : 'NA')
    this.apartirJuros = (this.datasAPartirDe.includes(this.condicao.juros_mensal_apartir) ? this.condicao.juros_mensal_apartir : 'NA')
    this.apartirCorrecao = (this.datasAPartirDe.includes(this.condicao.juros_indice_apartir) ? this.condicao.juros_indice_apartir : 'NA')

    this.showDataManualParcelas = (this.apartir === 'NA')
    this.showDataManualJuros = (this.apartirJuros === 'NA')
    this.showDataManualCorrecao = (this.apartirCorrecao === 'NA')
  }

  public changeApartirDeParcelas(selected: string = ''): void {
    if(selected !== '' && selected !== 'NA') {
      this.atualizaTipoPagamento();
    }

    this.showDataManualParcelas = (this.apartir === 'NA')
    if (this.showDataManualParcelas) {
      this.condicao.apartir = selected === 'NA' ? '' : selected
    } else {
      this.condicao.apartir = this.apartir
    }
  }

  public changeApartirDeJuros(selected: string = ''): void {
    this.showDataManualJuros = (this.apartirJuros === 'NA')
    if (this.showDataManualJuros) {
      this.condicao.juros_mensal_apartir = selected === 'NA' ? '' : selected
    } else {
      this.condicao.juros_mensal_apartir = this.apartirJuros
    }
  }

  public changeApartirDeCorrecao(selected: string = ''): void {
    this.showDataManualCorrecao = (this.apartirCorrecao === 'NA')
    if (this.showDataManualCorrecao) {
      this.condicao.juros_indice_apartir = selected === 'NA' ? '' : selected
    } else {
      this.condicao.juros_indice_apartir = this.apartirCorrecao
    }
  }

  public async getCalendario(): Promise<void> {
    this.calendario = await PlanoRecuperacaoService.calendario(this.condicao)

  }


  public formatDate(date: string) {
    return moment(date).format('L')
  }
  get parcelas(): any {
    let parcela = 0
    return Object.keys(this.calendario)
      .filter((key) => this.calendario[key].parcelas !== undefined)
      .map((key) => ({
        label: ++parcela + ' - ' + moment(this.calendario[key].parcelas).format('L'),
        value: this.calendario[key].parcelas,
        bonus: false,
        percentual: 0,
      }));
  }
  get ultimaParcelasAno(): any {
    let parcelasAgrupas = this.parcelas.reduce(function (carry, parcelas) {
      const year = moment(parcelas.value).year()
      carry[year] = carry[year] || [];
      carry[year].push(parcelas);
      return carry;
    }, Object.create(null))

    return Object.keys(parcelasAgrupas)
      .map((key) => parcelasAgrupas[key].pop());
  }

  get parcelasAno(): any {
    return this.parcelas.reduce(function (carry, parcelas) {
      const year = moment(parcelas.value).year()
      carry[year] = carry[year] || [];
      carry[year].push(parcelas);
      return carry;
    }, Object.create(null))
  }
}
