import http from '@/common/HttpRequest'
import { DefaultSelect } from '@/common/Type'
import { CondicaoData, ResultAddPlanoData, ResultDeletePlanoData, ResultPutPlanoData } from './CondicaoTypes'

class CondicaoService {

  public readonly PLANO_INATIVO: number = 0
  public readonly PLANO_ATIVO: number = 1

  private readonly endpointBase: string = 'plano-recuperacao/planos'

  public readonly TIPO_STATUS_PLANO: DefaultSelect[] = [
    { label: 'Ativo', value: 1 } ,
    { label: 'Inativo', value: 0 }
  ]

  public readonly APARTIR_DE: string[] = [
    'Data do Pedido',
    'Data de Deferimento RJ',
    'Data de Concessão RJ',
  ]

  public async get(): Promise<CondicaoData[]> {
    return await http
      .get<CondicaoData[]>(this.endpointBase)
      .then((response: any ) => response.data.data)
  }

  public async getCondicoesAtivas(): Promise<CondicaoData[]> {
    const planos = await this.get()
    return planos.filter(p => p.ativo === this.PLANO_ATIVO)
  }

  public async getCondicoesById(id: number): Promise<CondicaoData> {
    return await http
      .get<CondicaoData>(this.endpointBase + '/' + id)
      .then((response: any) => response.data.data)
  }

  public async add(plano: CondicaoData): Promise<ResultAddPlanoData> {
    return await http
      .post<ResultAddPlanoData>(this.endpointBase, plano)
      .then(response => response.data)
  }

  public async put(plano: CondicaoData): Promise<ResultPutPlanoData> {
    return await http
      .put<ResultPutPlanoData>(this.endpointBase + '/' + plano.id, plano)
      .then(response => response.data)
  }

  public async delete(id: number): Promise<ResultDeletePlanoData> {
    return await http
      .delete<ResultDeletePlanoData>(this.endpointBase + '/' + id)
      .then(response => response.data)
  }

}

const condicaoService = new CondicaoService()
export default condicaoService
