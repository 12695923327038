import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: ""
  },
  mutations: {
    messageError (state, message) {
      state.error = message
    },
    clearError (state) {
      state.error = ""
    }
  },
  actions: {
  },
  modules: {
  }
})
