var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "p-content",
    { attrs: { titulo: "Gestão dos Planos de Recuperação" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("modal-plano-recuperacao-add", {
                        attrs: { "plano-update": _vm.planoSelecionado },
                        on: {
                          addedPlano: _vm.addPlano,
                          cleared: function ($event) {
                            _vm.planoSelecionado = null
                          },
                        },
                        model: {
                          value: _vm.showModalEdit,
                          callback: function ($$v) {
                            _vm.showModalEdit = $$v
                          },
                          expression: "showModalEdit",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _vm.loading
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-progress-linear", {
                            attrs: {
                              active: _vm.loading,
                              indeterminate: "",
                              height: "2",
                              bottom: "",
                              color: "#9e7830",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("select-processos", {
                                    model: {
                                      value: _vm.filtro.id_processo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filtro, "id_processo", $$v)
                                      },
                                      expression: "filtro.id_processo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.isProcessoSelecionado()
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("select-processo-empresas", {
                                        attrs: {
                                          "id-processo": _vm.filtro.id_processo,
                                          multiple: "",
                                        },
                                        model: {
                                          value: _vm.filtro.id_empresa,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filtro,
                                              "id_empresa",
                                              $$v
                                            )
                                          },
                                          expression: "filtro.id_empresa",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("br"),
                          _c(
                            "v-data-table",
                            {
                              staticClass: "elevation-1 row-pointer",
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.planosFiltrados,
                                search: _vm.search,
                                "single-line": "",
                                "item-key": "id",
                                "calculate-widths": "",
                              },
                              on: { "click:row": _vm.clickPlano },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: `item.created_at`,
                                    fn: function ({ item }) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm
                                                .$moment(item.created_at)
                                                .format("DD/MM/YYYY")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: `item.plano_condicoes`,
                                    fn: function ({ item }) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.plano_condicoes
                                                .map(
                                                  (condicao) =>
                                                    condicao.descricao || ""
                                                )
                                                .join(", ")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: `item.acoes`,
                                    fn: function ({ item }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "blue--text",
                                            attrs: { small: "", icon: "" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.editPlano(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-pencil"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "red--text",
                                            attrs: { small: "", icon: "" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.deletePlano(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-delete"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(
                                " // eslint-disable-next-line vue/valid-v-slot "
                              ),
                            ]
                          ),
                          _c("modal-plano-detalhes", {
                            attrs: { plano: _vm.planoSelecionado },
                            model: {
                              value: _vm.showModalPlano,
                              callback: function ($$v) {
                                _vm.showModalPlano = $$v
                              },
                              expression: "showModalPlano",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }