<!-- @format -->

<template>

<container class="assembleia-grafico text-center">
      <h2>{{ titulo }}</h2>
     
        <v-row>
          <v-col>
                  <vue-speedometer
              :value="dados.presentes"
              :segments="400"
              :maxSegmentLabels="1"
              :maxValue="total"
              :currentValueText="formatValue(dados.presentes)"
            />
          </v-col>
        </v-row>
  </container>   
</template>

<script>
// import the component
import VueSpeedometer from "vue-speedometer";
// and use it in your component like
export default {
  components: { VueSpeedometer },
  props: {
    titulo: String,
    resultado: Object,
    graficoPercentual: Boolean,
  },
  computed: {
    dados() {
      return this.graficoPercentual ? this.porPercentual : this.porInteiro;
    },

    total() {
      return this.graficoPercentual ? 100 : this.resultado.total.creditos;
    },

    porInteiro() {
      return {
        presentes: this.resultado.presente.creditos,
        total: this.resultado.total.creditos,
      };
    },

    porPercentual() {
      return {
        presentes:
          Math.round(this.resultado.presente.creditos_perc * 100 * 100) / 100,
        total: 100,
      };
    },
  },
  methods: {
    formatValue(value) {
      return this.graficoPercentual
        ? this.$n(value, "decimal2") + "%"
        : this.$n(value, "currency");
    },
  },
};
</script>