var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "green--text",
                        attrs: { small: "", icon: "" },
                        on: {
                          click: function ($event) {
                            _vm.show = true
                          },
                        },
                      },
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-cash")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Ver Parcelas de Configuração")])]
      ),
      _c("modal", {
        attrs: { titulo: "Visualização de Parcelas", width: "1500" },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-subtitle", [
                                      _vm._v("Identificador do Plano"),
                                    ]),
                                    _c("v-list-item-title", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.parcelas.plano_id
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-subtitle", [
                                      _vm._v("Processo:"),
                                    ]),
                                    _c("v-list-item-title", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.parcelas.themis),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-subtitle", [
                                      _vm._v("Empresa:"),
                                    ]),
                                    _c("v-list-item-title", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.parcelas.empresa.nm_empresa
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-subtitle", [
                                      _vm._v("Empresa Principal?"),
                                    ]),
                                    _c("v-list-item-title", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getEmpresaPrincipal()
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { accordion: "" } },
                        _vm._l(_vm.parcelas.classes, function (classe, i) {
                          return _c(
                            "v-expansion-panel",
                            { key: i },
                            [
                              _c("v-expansion-panel-header", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      classe.nm_classe +
                                        " | Total a receber: R$ " +
                                        _vm.getValorTotalClasse(classe)
                                    ) +
                                    " "
                                ),
                              ]),
                              _c(
                                "v-expansion-panel-content",
                                _vm._l(classe.empresas, function (empresa, j) {
                                  return _c(
                                    "v-row",
                                    { key: j },
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              empresa.nm_empresa +
                                                " | Valor Total: R$ " +
                                                _vm.getValorTotalEmpresa(
                                                  empresa
                                                )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-data-table", {
                                            attrs: {
                                              items: empresa.credores,
                                              headers: _vm.headersCredores,
                                              "calculate-widths": "",
                                              "single-expand": _vm.single,
                                              dense: "",
                                              expanded: _vm.expanded,
                                              "show-expand": "",
                                              "hide-default-footer": "",
                                              "item-key": "descricao",
                                            },
                                            on: {
                                              "update:expanded": function (
                                                $event
                                              ) {
                                                _vm.expanded = $event
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item.valor",
                                                  fn: function ({ item }) {
                                                    return [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "toCurrencyBRL"
                                                            )(item.valor)
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.nm_credor",
                                                  fn: function ({ item }) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.nm_credor
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.valor_saldo",
                                                  fn: function ({ item }) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "toCurrencyBRL"
                                                            )(item.valor_saldo)
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "expanded-item",
                                                  fn: function ({
                                                    headers,
                                                    item,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            colspan:
                                                              headers.length,
                                                          },
                                                        },
                                                        [
                                                          _c("v-data-table", {
                                                            staticClass:
                                                              "mt-3 mb-3",
                                                            attrs: {
                                                              headers:
                                                                _vm.headersParcelas,
                                                              items:
                                                                item.parcelas,
                                                              dense: "",
                                                              "items-per-page":
                                                                item.parcelas
                                                                  .length,
                                                              "hide-default-footer":
                                                                "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "item.valor",
                                                                  fn: function ({
                                                                    item,
                                                                  }) {
                                                                    return [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "toCurrencyBRL"
                                                                            )(
                                                                              item.valor
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                                {
                                                                  key: "item.saldo",
                                                                  fn: function ({
                                                                    item,
                                                                  }) {
                                                                    return [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "toCurrencyBRL"
                                                                            )(
                                                                              item.saldo
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                                {
                                                                  key: "item.acoes",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              bottom:
                                                                                "",
                                                                            },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "activator",
                                                                                  fn: function ({
                                                                                    on,
                                                                                  }) {
                                                                                    return [
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        _vm._g(
                                                                                          {
                                                                                            staticClass:
                                                                                              "green--text",
                                                                                            attrs:
                                                                                              {
                                                                                                small:
                                                                                                  "",
                                                                                                icon: "",
                                                                                              },
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  _vm.show = true
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-arrow-down"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                              ],
                                                                              null,
                                                                              true
                                                                            ),
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Efetuar baixa de parcela"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ]
            },
            proxy: true,
          },
          {
            key: "action",
            fn: function () {
              return [
                _c("div", { staticClass: "flex-grow-1" }),
                _c(
                  "v-btn",
                  {
                    staticClass: "bg-plano-orange white--text",
                    attrs: { small: "" },
                    on: {
                      click: function ($event) {
                        _vm.show = false
                      },
                    },
                  },
                  [
                    _c("v-icon", [_vm._v("mdi-close")]),
                    _vm._v(" Fechar Visualização "),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.show,
          callback: function ($$v) {
            _vm.show = $$v
          },
          expression: "show",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }