<template>
  <v-container fluid v-if="resumo">
    <v-row>
      <v-col
        v-for="(resultado, classe) in resumo"
        :key="classe"
        class="ma-0 pa-0"
      >
        <v-card>
          <v-card-text>
            <assembleia-classe
              :resultado="resultado"
              :titulo="classe"
            ></assembleia-classe>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import AssembleiaClasse from "./AssembleiaClasse.vue";

export default {
  components: { AssembleiaClasse },
  props: { resumo: Object },
  data: () => ({
    grafico: false,
  }),
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Classe");
    },
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
};
</script>