var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _vm.loading
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-progress-linear", {
                        attrs: {
                          active: _vm.loading,
                          indeterminate: "",
                          height: "2",
                          bottom: "",
                          color: "#9e7830",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-simple-table", {
                        attrs: { dense: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("#"),
                                      ]),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("Credor"),
                                      ]),
                                      _c("th", { staticClass: "text-right" }, [
                                        _vm._v("Crédito"),
                                      ]),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("Ações"),
                                      ]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.credores, function (c, index) {
                                      return _c("tr", { key: index }, [
                                        _c("td", [_vm._v(_vm._s(c.credor.id))]),
                                        _c("td", [
                                          _vm._v(_vm._s(c.credor.nome)),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "text-right" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$n(
                                                    c.credor.valor,
                                                    "currency"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          [
                                            c.plano_credores_condicoes.length >
                                            0
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "blue--text",
                                                    attrs: {
                                                      small: "",
                                                      icon: "",
                                                      to: {
                                                        name: "credores-detalhes",
                                                        params: {
                                                          id: c
                                                            .plano_credores_condicoes[0]
                                                            .id,
                                                        },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-magnify"),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1033332599
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }