var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.credores,
          "items-per-page": _vm.limiteSemPaginacao,
          "multi-sort": "",
          height: "100%",
          dense: "",
          "fixed-header": "",
        },
        on: {
          "update:itemsPerPage": function ($event) {
            _vm.limiteSemPaginacao = $event
          },
          "update:items-per-page": function ($event) {
            _vm.limiteSemPaginacao = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.presente`,
              fn: function ({ item }) {
                return [_c("action-presenca", { attrs: { credor: item } })]
              },
            },
            {
              key: `item.nome`,
              fn: function ({ item }) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "text-truncate",
                      staticStyle: { "max-width": "300px" },
                      attrs: { title: item.nome },
                    },
                    [_vm._v(" " + _vm._s(item.nome) + " ")]
                  ),
                ]
              },
            },
            {
              key: `item.observacao`,
              fn: function ({ item }) {
                return [_c("action-observacao", { attrs: { credor: item } })]
              },
            },
            {
              key: `item.voto`,
              fn: function ({ item }) {
                return [_c("action-voto", { attrs: { credor: item } })]
              },
            },
            {
              key: `item.email`,
              fn: function ({ item }) {
                return [
                  _c("div", { staticClass: "text-center" }, [
                    _vm._v(" " + _vm._s(item.email) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(item.senha) + " "),
                  ]),
                ]
              },
            },
            {
              key: `item.total`,
              fn: function ({ item }) {
                return [
                  _c("span", [_vm._v(_vm._s(_vm.$n(item.total, "currency")))]),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }