
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ClasseCredor } from "@/services/processo/ProcessoTypes";
import { DefaultHeaderTable } from "@/common/Type";
import Modal from "@/components/modal/Modal.vue";
import { CondicaoPagamento } from "@/services/condicao/CondicaoTypes";
import DistribuicaoCondicaoValorService from "@/services/condicao/DistribuicaoCondicaoValorService";

interface CondicaoOperacao {
  isCondicao: (condicao: any) => boolean;
  regraValor: (credor: any, condicao: any) => number;
}

@Component({
  components: { Modal },
})
export default class ModaldistribuirValores extends Vue {
  @Prop()
  public classes!: ClasseCredor[];

  @Prop()
  public condicoes!: CondicaoPagamento[];

  public show: boolean = false;
  public loading: boolean = false;
  public credores: any[] = [];

  public headers: DefaultHeaderTable[] = [
    { text: "Credor", value: "nome" },
    { text: "Classe", value: "classe" },
    { text: "Valor Total a Receber", value: "valor" },
    { text: "Planos de Pagamento", value: "planos", sortable: false },
  ];

  public distribuirValores(): void {
    this.loading = true;
    const distribuicaoService = new DistribuicaoCondicaoValorService(
      this.classes,
      this.condicoes
    );
    this.credores = [];

    setTimeout(() => {
      this.credores = distribuicaoService.montarDistribuicaoValores();
      this.loading = false;
    }, 500);
  }

  @Watch("show")
  public onChangeShow(show: boolean): void {
    if (show) {
      this.distribuirValores();
    }
  }

  public getDescricao(condicaoUuid : string) {
    const condicao = this.condicoes.find((condicao) => condicao.uuid === condicaoUuid)

    if(condicao !== undefined)
      return condicao.descricao

    return "Sem plano"
  }
}
