var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.resumo
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            _vm._l(_vm.resumo, function (resultado, classe) {
              return _c(
                "v-col",
                { key: classe, staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("assembleia-classe", {
                            attrs: { resultado: resultado, titulo: classe },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }