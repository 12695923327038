var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.credor.voto && _vm.credor.presente && !_vm.loading
      ? _c(
          "div",
          [
            _c(
              "v-radio-group",
              {
                staticClass: "compact-form",
                attrs: { row: "" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.votar()
                  },
                },
                model: {
                  value: _vm.credor.voto,
                  callback: function ($$v) {
                    _vm.$set(_vm.credor, "voto", $$v)
                  },
                  expression: "credor.voto",
                },
              },
              [
                _c("v-radio", {
                  staticClass: "voto-input",
                  attrs: { label: "Sim", value: "S" },
                }),
                _c("v-radio", {
                  staticClass: "voto-input",
                  attrs: { label: "Não", value: "N" },
                }),
                _c("v-radio", {
                  staticClass: "voto-input",
                  attrs: { label: "Abstem", value: "A" },
                }),
                _c("v-radio", {
                  staticClass: "voto-input",
                  attrs: { label: "Indefinido", value: "I" },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm.loading
      ? _c(
          "div",
          { staticClass: "text-center" },
          [
            _c("v-progress-circular", {
              attrs: { color: "primary", indeterminate: "" },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }