var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          headers: _vm.headersPresenca,
                          items: _vm.ultimosPresentes,
                          "items-per-page": 20,
                          height: "100%",
                          dense: "",
                          "fixed-header": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: `item.voto`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-truncate",
                                      staticStyle: { "max-width": "300px" },
                                      attrs: { title: item.voto },
                                    },
                                    [
                                      _c("voto-icon", {
                                        attrs: { voto: item.voto },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.classe`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " Classe " + _vm._s(item.classe) + " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.total`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$n(item.total, "currency")) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.data`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formatDateFromNow(item.data)) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }