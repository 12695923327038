var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-row",
                    [_c("v-col", [_vm._v(_vm._s(_vm.credor.nome))])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-subtitle", [
                _vm._v(_vm._s(_vm.credor.documento || "Sem documento") + " "),
                _c("br"),
                _vm._v(" #" + _vm._s(_vm.credor.id) + " "),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _vm.valores
                ? _c(
                    "v-card-text",
                    [_c("card-valores", { attrs: { valores: _vm.valores } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }