var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    [
      _c("v-simple-table", {
        attrs: { dense: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "text-left" }, [
                      _vm._v("Descrição"),
                    ]),
                    _c("th", { staticClass: "text-left" }, [_vm._v("Classes")]),
                    _c("th", { staticClass: "text-left" }, [
                      _vm._v("Modo de Pagamento"),
                    ]),
                    _c("th", { staticClass: "text-left" }, [
                      _vm._v("Divide Valor"),
                    ]),
                    _c("th", { staticClass: "text-left" }, [_vm._v("Ações")]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.condicoes, function (condicao, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(condicao.descricao))]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.getClasses(condicao.classes))),
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.getModoPagamento(condicao))),
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(condicao.valor_unico ? "Não" : "Sim")),
                      ]),
                      _c(
                        "td",
                        [
                          _c("modal-condicao-pagamento", {
                            attrs: {
                              "is-update": true,
                              "index-update": index,
                              processo: _vm.processo,
                              condicao: condicao,
                            },
                          }),
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                title: "Remover condição",
                                small: "",
                                color: "red",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteCondicao(index)
                                },
                              },
                            },
                            [_vm._v("mdi-delete")]
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }