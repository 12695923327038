
import { Component, Vue, Prop } from "vue-property-decorator";
import { AssembleiaCredor } from "../../services/assembleia/AssembleiaTypes";
import { assembleiaStore } from "@/store/assembleia/";

@Component({
  components: {},
})
export default class ActionVoto extends Vue {
  @Prop()
  public credor!: AssembleiaCredor;

  public loading: boolean = false;

  public async votar(): Promise<void> {
    this.loading = true;
    try {
      
      const result = await assembleiaStore.votar({
        id: this.credor.id,
        opcao: this.credor.voto,
      });
    } finally {
      this.loading = false;
    }
  }
}
