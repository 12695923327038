var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.voto === "I"
        ? _c("v-icon", { attrs: { title: "Indefinido" } }, [
            _vm._v(" mdi-vote "),
          ])
        : _vm.voto === "S"
        ? _c(
            "v-icon",
            { attrs: { color: "green darken-2", title: "Aprova" } },
            [_vm._v(" mdi-thumb-up-outline ")]
          )
        : _vm.voto === "N"
        ? _c(
            "v-icon",
            { attrs: { color: "red darken-2", title: "Não aprova" } },
            [_vm._v(" mdi-thumb-down-outline ")]
          )
        : _vm.voto === "A"
        ? _c(
            "v-icon",
            { attrs: { color: "yellow darken-2", title: "Abstem" } },
            [_vm._v(" mdi-thumbs-up-down ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }