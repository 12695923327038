
import { Component, Vue } from 'vue-property-decorator'
import PContent from '@/components/layout/PContent.vue'
import { DefaultHeaderTable, Status } from '@/common/Type'
import { CondicaoData } from '@/services/condicao/CondicaoTypes'
import condicaoService from '@/services/condicao/CondicaoService'

@Component({
  components: { PContent }
})
export default class Condicao extends Vue {

  public loading: boolean = true
  public headers: DefaultHeaderTable[] = [
    { value: 'descricao', text: 'Descrição', align: 'start' },
    { value: 'prazo', text: 'Prazo', align: 'center' },
    { value: 'carencia', text: 'Carência', align: 'center' },
    { value: 'parcelas', text: 'Número de Parcelas', align: 'center' },
    { value: 'desagio', text: 'Indice Deságio', align: 'center' },
    { value: 'juros_indice', text: 'Índice Juros', align: 'start' },
    { value: 'juros_indice_percentual', text: 'Índice Juros %', align: 'center' },
    { value: 'juros_mes', text: 'Juros Mês', align: 'center' },
    { value: 'acao', text: 'Ações', sortable: false, align: 'center' },
  ]
  public search: string = ''

  public status: Status[] = [
    { label: 'Ativo', value: 1 },
    { label: 'Inativo', value: 0 }
  ]

  public planos: CondicaoData[] = []

  public mounted(): void {
    this.getPlanos()
  }

  public async getPlanos(): Promise<void> {
    this.loading = true
    this.planos = await condicaoService.get()
    this.loading = false
  }

  public returnAction(): void {
    this.getPlanos()
  }

}
