
import { Component, Prop, Vue } from "vue-property-decorator";
import PlanoStages from "@/components/plano-recuperacao/PlanoCredores/PlanoStages.vue";
import ConversaoText from "@/components/plano-recuperacao/PlanoCredores/ConversaoText.vue";
import ModalPagar from "@/components/plano-recuperacao/ModalPagar.vue";

@Component({
  components: { PlanoStages, ModalPagar, ConversaoText },
})
export default class PlanoParcela extends Vue {
  @Prop()
  public parcela: any;

  @Prop()
  public parcelaEstrangeiro: any;

  @Prop()
  public mostrarCalculos;

  @Prop()
  public numero!: string;

  public dialogPagar: boolean = false;

  get saldo(): number {
    return this.parcela.saldo;
  }

  get isCarencia(): boolean {
    return this.parcela.tipo !== "Parcelas";
  }
}
