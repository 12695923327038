
import { Component, Prop, Vue } from "vue-property-decorator";
import RDate from "@/components/form/Date.vue";
import InputUpload from "@/components/form/InputUpload.vue";
import planoRecuperacaoService from "@/services/plano-recuperacao/PlanoRecuperacaoService";

@Component({
  components: { InputUpload, RDate },
})
export default class ModalPagar extends Vue {
  @Prop()
  public dialog;

  @Prop()
  public parcela;

  @Prop()
  public valores;

  public validation = false;

  public pagamento = {
    plano_id: this.valores.plano_id,
    credor_id: this.valores.credor_id,
    plano_valores_parcelas_id: this.parcela.id,
    data: new Date(),
    valor: this.valorParcela,
    maximo: this.parcela.pagamento_valor,
    user_id: 1,
  };

  public async submit() {
    const form: any = this.$refs.form
    this.validation = form.validate()

    if(!this.validation)
      return false

    const pagamento = await planoRecuperacaoService.pagar(this.pagamento);
    this.valores.parcelas = pagamento.context;
    this.dialog = false;
  }

  get valorParcela() : number {
    return Math.round((this.parcela.pagamento_valor - this.desconto)*100)/100
  }

  get desconto() : number {
    if(this.parcela.bonus_adimplencia === 0) {
      return 0;
    }

    if(!this.bonus) {
      return 0;
    }
    return this.parcela.pagamento_valor * (this.parcela.bonus_percentual/100)
  }   
  get emDia(): boolean {
    return this.parcela.bonus_adimplencia
  }

  get bonus() : boolean {
    return !(this.valores.parcelas.filter((parcela) => !parcela.adimplente).length > 0)
  }
}
