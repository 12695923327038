var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c("modal", {
        attrs: { titulo: "Distribuição de Valores", width: "1200" },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mt-0 mb-0 subtitle-1",
                        attrs: { cols: "12" },
                      },
                      [
                        _c("v-data-table", {
                          attrs: {
                            "loading-text": "Distribuindo...",
                            headers: _vm.headers,
                            loading: _vm.loading,
                            items: _vm.credores,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "item.planos",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "p",
                                    { staticStyle: { "font-size": "13px" } },
                                    _vm._l(item.planos, function (plano, p) {
                                      return _c("span", { key: p }, [
                                        _c("b", [_vm._v("Plano: ")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getDescricao(plano.condicao)
                                            ) +
                                            " | "
                                        ),
                                        _c("b", [_vm._v("Valor: ")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("toCurrencyBRL")(
                                                plano.valor
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c("br"),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.classe",
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.tipo.classe_assembleia.nome) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.valor",
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("toCurrencyBRL")(item.valor)
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.show,
          callback: function ($$v) {
            _vm.show = $$v
          },
          expression: "show",
        },
      }),
      _c(
        "v-btn",
        {
          staticClass: "white--text mt-3",
          attrs: { small: "", color: "#3f8242" },
          on: {
            click: function ($event) {
              _vm.show = true
            },
          },
        },
        [
          _vm._v(" Visualizar Distribuição de Valores "),
          _c("v-icon", [_vm._v("mdi-eye")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }