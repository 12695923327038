/** @format */

import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import mixin from "@/mixin";
import store from "@/store";
import "@/filter";
import { sync } from "vuex-router-sync";
import money from "v-money";
import i18n from "./i18n";
import JsonCSV from "vue-json-csv";
import VueMoment from "vue-moment";
import moment from "moment";
import "@/plugins/quill";

moment.locale("pt");
Vue.use(VueMoment, { moment });

Vue.use(money, { precision: 4 });
Vue.component("downloadCsv", JsonCSV);

Vue.mixin(mixin);
sync(store, router);

export const eventBus = new Vue();

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
