
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Modal extends Vue {

  @Prop()
  public value!: boolean

  @Prop()
  public titulo?: string

  @Prop()
  public width?: number | string

  @Prop()
  public persistant?: boolean

  @Prop({ default: false} )
  public loading?: boolean

  public tituloModal: string = this.titulo || 'Título'

  public maxWidth: number | string = this.width || 700
  public isPersistant: boolean = this.persistant || false
  public isLoading: boolean | undefined = this.loading

  get show() {
    return this.value
  }

  set show(value: boolean) {
    this.$emit('input', value)
    this.emitOnChangeShow(value)
  }

  @Watch('titulo')
  onChangeTitulo(titulo: string): void {
    this.tituloModal = titulo
  }

  @Watch('value')
  onChangeValue(value: boolean): void {
    this.emitOnChangeShow(value)
  }

  @Watch( 'loading')
  onChangeLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  public emitOnChangeShow(value: boolean): void {
    this.$emit('onChangeShow', value)
  }

  public closeModal(): void {
    this.show = false
  }
}
