var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-app-bar",
        { attrs: { app: "" } },
        [
          _c("v-toolbar-title", [_vm._v("Plano de Recuperação")]),
          _c("v-spacer"),
          _c("span", [_vm._v("admin@admin.com")]),
          _c(
            "v-btn",
            { attrs: { icon: "", link: "" }, on: { click: _vm.logout } },
            [
              _c("v-icon", { attrs: { size: 21 } }, [
                _vm._v("mdi-logout-variant"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _vm.titulo
            ? _c(
                "v-col",
                { staticClass: "mg-top-content", attrs: { cols: "12" } },
                [
                  _c(
                    "span",
                    { staticClass: "headline font-weight-light title-page" },
                    [_vm._v(_vm._s(_vm.titulo ? _vm.titulo : "Título Área"))]
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12 mg-top-content" } },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }