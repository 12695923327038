
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import SelectProcessos from "@/components/form/select/SelectProcessos.vue";
import SelectProcessoEmpresas from "@/components/form/select/SelectProcessoEmpresas.vue";
import SelectCondicoes from "@/components/form/select/SelectCondicoes.vue";
import Condicoes from "./Condicoes.vue";
import { CondicaoPagamento } from "@/services/condicao/CondicaoTypes";
import { commonModule } from "@/store/common";
import Modal from "@/components/modal/Modal.vue";
import { eventBus } from "@/main";
import {
  ClasseCredor,
  Processo,
  ProcessoCredor,
} from "@/services/processo/ProcessoTypes";
import ModalCredores from "./ModalCredores.vue";
import ModalCondicaoPagamento from "./ModalCondicaoPagamento.vue";
import ModalDistribuicaoValores from "./ModalDistribuicaoValores.vue";
import DistribuicaoCondicaoValorService from "@/services/condicao/DistribuicaoCondicaoValorService";
import ModalValidacaoPlano from "@/components/plano-recuperacao/ModalValidacaoPlano.vue";
import { PlanoRecuperacaoData } from "@/services/plano-recuperacao/PlanoRecuperacaoTypes";
import planoRecuperacaoService from "@/services/plano-recuperacao/PlanoRecuperacaoService";

@Component({
  components: {
    ModalValidacaoPlano,
    ModalDistribuicaoValores,
    ModalCondicaoPagamento,
    ModalCredores,
    SelectProcessoEmpresas,
    Modal,
    Condicoes,
    SelectCondicoes,
    SelectProcessos,
  },
})
export default class ModalPlanoRecuperacaoAdd extends Vue {
  public loading: boolean = false;
  public show: boolean = false;
  public showModalValidacaoPlano: boolean = false;
  public credores: ProcessoCredor[] = [];
  public processo: Processo | null = null;
  public classes: ClasseCredor[] = [];
  public condicoes: CondicaoPagamento[] = [];

  public planoDefault: any = {
    id_processo: null,
    id_empresa: [],
    empresa: [],
    condicoes: [],
    credores: [],
  }

  @Prop({default: null})
  public planoUpdate!: any

  @Prop({default: false})
  public value: boolean = false;

  public plano: any = {...this.planoDefault}

  public mounted(): void {
    this.addEventoGetAdicionarCondicao();
    this.addEventoEmpresaSelecionada();

    this.$nextTick(() => {
      this.reset()
    });
  }

  public addEventoGetAdicionarCondicao(): void {
    eventBus.$on("adicionarCondicao", (condicao: any) => {
      this.adicionarCondicao(condicao);
      eventBus.$emit("condicaoAdicionada", this.condicoes);
    });
  }

  public addEventoEmpresaSelecionada(): void {
    eventBus.$on("empresaSelecionada", (processo: Processo) => {
      this.credores = processo.credores_assembleia;
      this.agruparClasses(this.credores);
      this.processo = processo;

      if (this.classes.length === 0 && this.show) {
        commonModule.showMessage({
          message: "NENHUMA CLASSE ENCONTRADA PARA ESTE PROCESSO",
          type: "error",
          timeout: 2000,
        });
      }
    });
  }

  public agruparClasses(credores: ProcessoCredor[]): void {
    const filter = (classe: string) => (classes: any) =>
      classes.nome === classe;
    this.classes = [];

    for (var credor of credores) {
      const classeObj = credor.classe_estrategica ? credor.classe_estrategica : credor.tipo.classe_assembleia;
      const classe: string = classeObj.nome
      let index = this.classes.findIndex(filter(classe));

      if (index < 0) {
        this.classes.push({ nome: classe, credores: [], order: classeObj.order });
      }

      index = this.classes.findIndex(filter(classe));
      this.classes[index].credores.push({ ...credor });
    }
    this.classes.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
    
  }

  public isProcessoSelecionado(): boolean {
    return this.plano.id_processo !== null;
  }

  public isEmpresaSelecionada(): boolean {
    return this.plano.id_empresa.length > 0;
  }

  public limparDadosPlano(): void {
    this.plano.id_processo = null;
    this.plano.id_empresa = [];
    this.plano.empresa = [];
    this.condicoes = [];
    this.$emit('cleared', null);
  }

  public adicionarCondicao(condicao: any): void {
    const form = { ...condicao.form };
    const condicoes = [...this.condicoes];

    if (condicao.isUpdate) {
      condicoes[condicao.indexUpdate] = form;
    } else {
      condicoes.push(form);
    }

    this.condicoes = [...condicoes];
  }


  @Watch("show")
  public onChangeShow(show: boolean): void {
    if (!show) {
      this.limparDadosPlano();
    }
    this.$emit('input', show);
  }

  @Watch("value")
  public onChangeValue(value: boolean) : void {
    this.show = value;
  }

  @Watch("planoUpdate")
  public onChangePlanoUpdate(plano: any) : void {
    this.reset();
  }

  public async getCredoresPlano(): Promise<any> {
    const credores = await planoRecuperacaoService.getCredores(this.plano.id);

    return credores.map((item) => {
      let credor = item.credor
      const classeObj = credor.estrategica > 0 ? credor.classe_estrategica : credor.tipo.classe_assembleia;
      credor.condicoes = item.plano_credores_condicoes.map(condicao => condicao.condicao);
      credor.classe = classeObj.nome
      credor.plano_credores_condicoes = item.plano_credores_condicoes

      return credor
    })
  }

  public reset(): void {
    if (this.planoUpdate !== null) {
      this.loading = true;
      this.plano = JSON.parse(JSON.stringify(this.planoUpdate))

      this.plano.id_processo = this.plano.processo_id
      this.plano.id_empresa = this.plano.empresas.map((empresa) => (empresa.id));

      this.condicoes = this.plano.plano_condicoes.map((condicao) => {
        return {
          ...condicao,
          pagamento: {
            tipo: condicao.plano_condicoes_pagamento.tipo,
            metadata: condicao.plano_condicoes_pagamento.config.metadata
          },
          classes: [...condicao.plano_classes]
        }
      })

      this.loading = false

    } else {
      this.plano = JSON.parse(JSON.stringify(this.planoDefault))
    }
  }

  public async addPlano(): Promise<void> {
    if (this.planoIsValid()) {
      this.loading = true;
      const distribuicaoService = new DistribuicaoCondicaoValorService(
        this.classes,
        this.condicoes
      );

      if (this.processo) {
        const plano: PlanoRecuperacaoData = {
          id: 0,
          processo_id: this.processo.id,
          empresas: this.plano.id_empresa,
          condicoes: this.condicoes,
          credores: distribuicaoService.montarDistribuicaoValores(),
        };

        const plano_id = this.planoUpdate ? this.planoUpdate.id : undefined;
        await planoRecuperacaoService.save(plano, plano_id);

        this.planoUpdate = null;

        this.$emit("addedPlano");
        this.show = false;
        this.$emit('input', this.show)
      }
      this.loading = false;
    }
  }

  public planoIsValid(): boolean {
    if (this.plano.id_processo === null) {
      commonModule.showMessage({
        message: "Selecione um processo",
      });
      return false;
    }

    if (this.plano.id_empresa.length === 0) {
      commonModule.showMessage({
        message: "Selecione pelo menos uma empresa",
      });
      return false;
    }

    if (this.condicoes.length === 0) {
      commonModule.showMessage({
        message: "O plano não possui nenhuma condição de pagamento",
      });
      return false;
    }
    const distribuicaoService = new DistribuicaoCondicaoValorService(
      this.classes,
      this.condicoes
    );
    const distribuicao = distribuicaoService.montarDistribuicaoValores();


    if (distribuicao.length !== this.credores.length) {
      this.showModalValidacaoPlano = true;

      commonModule.showMessage({
        message: "O plano possui credores que não receberam nenhum plano",
      });

      return false;
    }

    return true;
  }
}
