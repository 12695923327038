var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "p-content",
    { attrs: { titulo: "Plano Credores" } },
    [_c("plano-credores", { attrs: { planoId: _vm.$route.params.id } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }