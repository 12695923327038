var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { attrs: { height: "100%", disable: _vm.isCarencia } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-card-text", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(this.parcela.tipo) + " "),
                _c("br"),
                _vm._v(
                  " " + _vm._s(_vm.$n(_vm.parcela.saldo, "decimal2")) + " "
                ),
                _c("br"),
                _vm.parcelaEstrangeiro
                  ? _c(
                      "div",
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.parcelaEstrangeiro.conversao.codigo) +
                            " " +
                            _vm._s(
                              _vm.$n(_vm.parcelaEstrangeiro.saldo, "decimal2")
                            ) +
                            " "
                        ),
                        _c("br"),
                        _c("conversao-text", {
                          attrs: {
                            conversao: _vm.parcelaEstrangeiro.conversao,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("v-card-subtitle", [_vm._v("Saldo")]),
              _c("v-card-text", { staticClass: "blue--text text--darken-3" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.numero) +
                    " " +
                    _vm._s(_vm._f("moment")(_vm.parcela.date, "L"))
                ),
              ]),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _vm.parcela.pagamento_valor > 0
            ? _c(
                "v-col",
                [
                  _c("v-card-title", { attrs: { small: "" } }, [
                    _vm._v(
                      _vm._s(_vm.$n(_vm.parcela.pagamento_valor, "decimal2")) +
                        " "
                    ),
                  ]),
                  _c("v-card-subtitle", [_vm._v("Pagamento")]),
                  _c(
                    "v-card-text",
                    [_c("modal-pagar", { attrs: { dialog: _vm.dialogPagar } })],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("v-card-text"),
      _c("v-divider"),
      _vm.mostrarCalculos
        ? _c("plano-stages", {
            attrs: { stages: _vm.parcela.stages, title: "BRL" },
            on: {
              click: function ($event) {
                _vm.mostrarCalculos = !_vm.mostrarCalculos
              },
            },
          })
        : _vm._e(),
      _vm.parcelaEstrangeiro && _vm.mostrarCalculos
        ? _c("plano-stages", {
            attrs: {
              stages: _vm.parcelaEstrangeiro.stages,
              title: _vm.parcelaEstrangeiro.conversao.codigo,
            },
            on: {
              click: function ($event) {
                _vm.mostrarCalculos = !_vm.mostrarCalculos
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }