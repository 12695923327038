
import { Component, Prop, Vue } from "vue-property-decorator";
import ConversaoText from "@/components/plano-recuperacao/PlanoCredores/ConversaoText.vue";

@Component({
  components: { ConversaoText },
})
export default class CardValores extends Vue {
  @Prop()
  public valores: any;
}
