
import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/components/modal/Modal.vue'
import FormCondicaoPagamento from './FormCondicaoPagamento.vue'
import { DefaultHeaderTable } from '@/common/Type'
import { PlanoRecuperacaoCredoresPlanos } from '@/services/plano-recuperacao/PlanoRecuperacaoTypes'

@Component({
  components: { FormCondicaoPagamento, Modal },
})
export default class ModalCondicaoCredor extends Vue {
  @Prop()
  public planos!: PlanoRecuperacaoCredoresPlanos[]

  public show: boolean = false
  public headers: DefaultHeaderTable[] = [
    { value: 'descricao', align: 'start', text: 'Condição de Pagamento' },
    { value: 'valor', align: 'start', text: 'Limite de Pagamento' },
  ]
}
