<!-- @format -->

<template>
<div>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>  
          <v-data-table
            :headers="headersPresenca"
            :items="ultimosPresentes"
            :items-per-page="20"
            class="elevation-1"
            height="100%"
            dense
            fixed-header
          >
          <template v-slot:[`item.voto`]="{ item }">
            <div class="text-truncate" style="max-width: 300px" :title="item.voto">
              <voto-icon :voto="item.voto"/>
            </div>
          </template>
            <template v-slot:[`item.classe`]="{ item }">
              Classe {{ item.classe }}
            </template>
            <template v-slot:[`item.total`]="{ item }">
              {{ $n(item.total, "currency") }}
            </template>

            <template v-slot:[`item.data`]="{ item }">
              {{ formatDateFromNow(item.data) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</div>  
</template>

<script>
import _ from "lodash";
import Format from "@/services/format";
import VotoIcon from "@/components/assembleia/VotoIcon.vue";
import router from "@/router";
import { broadcast } from "@/plugins/pusher";
import { assembleiaStore } from "@/store/assembleia/";
import moment from 'moment';

export default {
  components: { VotoIcon },
  data: () => ({
    presentes: 1,
    search: "",
    headersPresenca: [
      {
        text: "NOME",
        align: "start",
        value: "nome",
      },
      {
        text: "VALOR",
        align: "end",
        value: "total",
      },
      {
        text: "CLASSE",
        align: "start",
        value: "classe",
      },
      {
        text: "VOTO",
        align: "start",
        sortable: true,
        value: "voto",
      },
      {
        text: "HORA",
        align: "start",
        sortable: false,
        value: "data",
      },
    ],
  }),
  computed: {
    credorSort() {
      return _.sortBy(this.credorfilter, ["classe", "nome"]);
    },

    credorfilter() {
      let credores = [];
      if (this.presentes == 0) {
        //todos
        credores = this.credores;
      } else {
        const presente = this.presentes == 1;
        credores = this.credores.filter(
          (credor) => credor.presente == presente
        );
      }
      if (this.search === "") {
        return credores;
      }

      return (
        credores.filter(
          (credor) =>
            Format.search(credor.nome, this.search) ||
            Format.search(credor.email, this.search) ||
            Format.search(
              Format.documentoLimpo(credor.documento),
              Format.documentoLimpo(this.search)
            )
        ) || []
      );
    },
    ultimosPresentes() {
      return this.credores
        .filter((credor) => credor.presente)
        .sort((one, two) => (one.data > two.data ? -1 : 1))
        .slice(0, 10);
    },
    credores() {
      return assembleiaStore.credores;
    },
  },
  methods: {
    descricaoPresente(presente) {
      return presente ? "Sim" : "Não";
    },
    formatDateFromNow(date) {
      return moment(date).fromNow();
    }
  },
  async mounted() {
    this.loading = true;
    const assembleiaId = Number(router.currentRoute.params.id);
    await assembleiaStore.getAssembleia(assembleiaId);
    await assembleiaStore.getCredores(assembleiaId);
    broadcast.subscribeAssembleia(10 * 1000);
    this.loading = false;
  },

};
</script>

<style scoped>
span.small {
  font-size: 8;
  line-height: 9;
}
</style>
