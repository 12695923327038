/** @format */
import Pusher from "pusher-js";
import { assembleiaStore } from "@/store/assembleia/";
import router from "@/router";
import _ from "lodash";

Pusher.logToConsole = true;

const pusher = new Pusher("f1f90b027eeda521b0b5", {
  cluster: "us2",
  forceTLS: true,
});



class Broadcast {
  subscribeAssembleia(wait:number): void {
    const channel = pusher.subscribe("assembleia");
    const assembleiaId = Number(router.currentRoute.params.id)
    const getCredores = _.throttle(() => assembleiaStore.getCredores(assembleiaId), wait);
    
    channel.unbind("voto.atualizado");
    channel.bind("voto.atualizado", function (data) {
      if (assembleiaId > 0) {
        getCredores();
      }
    });
  }
}


const broadcast = new Broadcast();
export { pusher, broadcast };
