var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": _vm.maxWidth, persistent: _vm.isPersistant },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title" },
            [
              _vm._v(" " + _vm._s(_vm.tituloModal) + " "),
              _c("div", { staticClass: "flex-grow-1" }),
              _c("v-icon", { on: { click: _vm.closeModal } }, [
                _vm._v("mdi-close"),
              ]),
            ],
            1
          ),
          _c("v-progress-linear", {
            attrs: {
              active: _vm.isLoading,
              indeterminate: "",
              height: "2",
              color: "#9e7830",
            },
          }),
          !_vm.isLoading ? _c("hr", { staticClass: "hr-modal" }) : _vm._e(),
          _c(
            "v-card-text",
            { staticClass: "content-modal" },
            [_vm._t("text")],
            2
          ),
          _c("hr", { staticClass: "hr-modal" }),
          _c(
            "v-card-actions",
            { staticStyle: { height: "58px", padding: "16px 24px 10px" } },
            [_vm._t("action")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }