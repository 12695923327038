var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "v-app",
        { attrs: { id: "inspire", loading: _vm.loading } },
        [
          _c(
            "v-app-bar",
            { attrs: { app: "", dark: "", "clipped-left": "" } },
            [
              _c("v-app-bar-nav-icon", {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.drawer = !_vm.drawer
                  },
                },
              }),
              _c(
                "v-toolbar-title",
                { staticClass: "ml-0 pl-4", staticStyle: { width: "300px" } },
                [
                  _c("span", { staticClass: "hidden-sm-and-down" }, [
                    _vm._v("Assembleias"),
                  ]),
                ]
              ),
              _c("v-text-field", {
                attrs: {
                  flat: "",
                  "solo-inverted": "",
                  "hide-details": "",
                  "prepend-inner-icon": "mdi-magnify",
                  label: "Search",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
          _c(
            "v-main",
            [
              _c("v-progress-linear", {
                attrs: {
                  active: _vm.loading,
                  indeterminate: _vm.loading,
                  absolute: "",
                  top: "",
                },
              }),
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.assembleiasFiltro,
                      "items-per-page": 20,
                      height: "100%",
                      dense: "",
                      "fixed-header": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.data`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("moment")(item.data, "DD/MM/Y hh:mm")
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.acao`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "blue--text mr-1",
                                  attrs: {
                                    small: "",
                                    icon: "",
                                    to: {
                                      name: "assembleia",
                                      params: { id: item.id },
                                    },
                                    title: "Lista de presença",
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-format-list-checks"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "blue--text",
                                  attrs: {
                                    small: "",
                                    icon: "",
                                    title: "Gráfico da assembléia",
                                    target: "_blank",
                                    to: {
                                      name: "assembleia-dashboard",
                                      params: { id: item.id },
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-chart-bar")])],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "blue--text",
                                  attrs: {
                                    small: "",
                                    icon: "",
                                    href: `assembleias/${item.id}/dashboard-presenca`,
                                    target: "_blank",
                                    title: "Gráfico de presença",
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-gauge")])],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "blue--text",
                                  attrs: {
                                    small: "",
                                    icon: "",
                                    href: `assembleias/${item.id}/dashboard-recuperanda`,
                                    target: "_blank",
                                    title: "Recuperanda",
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-bank")])],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }