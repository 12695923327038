var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-sheet", { staticClass: "text--white bg-plano-black pa-4" }, [
    _c("img", {
      staticStyle: {
        height: "130px",
        "margin-left": "20px",
        "margin-bottom": "-10px",
      },
      attrs: {
        src: require("@/assets/logo-pagina-login.png"),
        alt: "Imagem logo",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }