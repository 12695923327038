
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import periodicidadeService from '@/services/periodicidade/PeriodicidadeService'
import DateHelper from '@/helper/DateHelper'
import { commonModule } from '@/store/common'
import { PeriodicidadeData, TimeInterval } from '@/services/periodicidade/PeriodicidadeTypes'

@Component({
  components: { },
})
export default class ModalPeriodicidade extends Vue {

  @Prop()
  public tipo!: string

  @Prop()
  public isAdd!: boolean

  @Prop()
  public isUpdate!: boolean

  @Prop()
  public isDelete!: boolean

  @Prop()
  public idPeriodo?: number | null

  public loading: boolean = false
  public loadingbtn: boolean = false
  public show: boolean = false
  public lazy: boolean = false

  public rules: any = {
    anos: [
      (v: any) => !!v || 'Informe a quantidade de anos',
      (v: any) => (+v >= 0) || 'Informe a quantidade de anos'
    ],
    meses: [
      (v: any) => !!v || 'Informe a quantidade de meses',
      (v: any) => (+v >= 0 && +v < 12) || 'Informe a quantidade de meses corretamente'
    ],
    dias: [
      (v: any) => !!v || 'Informe a quantidade de dias',
      (v: any) => (+v >= 0 && +v < 32) || 'Informe a quantidade de dias corretamente'
    ],
    descricao: [
      (v: any) => !!v || 'Informe a Descrição do Período',
    ],
  }

  public periodo: PeriodicidadeData = {id: null, descricao: '', anos: 1, meses: 1, dias: 1}

  @Watch('show')
  public onChangeShow(val: boolean): void {
    if (!val) {
      this.resetForm()
    }
  }

  public async getPeriodoById(): Promise<void> {
    this.loading = true
    this.show = true
    try {
      if (this.idPeriodo) {
        const periodo = await periodicidadeService.getPeriodoById(this.idPeriodo)

        if (periodo && periodo.interval) {
          const interval: TimeInterval = DateHelper.parseIntervalStringToTimeInterval(periodo.interval)
          this.periodo = {
            descricao: periodo.descricao,
            dias: interval.dias,
            meses: interval.meses,
            anos: interval.anos,
          }
        }
      }
    } catch (e) {
      commonModule.showMessage({message: 'Erro ao buscar período', type: 'error'})
    }

    this.loading = false
  }

  public async savePeriodo(): Promise<void> {
    const form: any = this.$refs.form
    this.loadingbtn = true

    if (form.validate()) {
      try {
        await periodicidadeService.save(this.periodo)
        commonModule.showMessage({message: 'Período cadastrado com sucesso.'})
        this.resetForm()
        this.$emit('added')
        this.show = false
      } catch (error) {
        commonModule.showMessage({
          message: 'Algum erro salvar o período.',
          type: 'error',
        })
      }
    }

    this.loadingbtn = false
  }

  public async updatePeriodo(): Promise<void> {
    const form: any = this.$refs.form
    this.loadingbtn = true

    if (form.validate()) {
      try {
        await periodicidadeService.update(this.periodo, (this.idPeriodo ? this.idPeriodo : null))
        commonModule.showMessage({ message: 'Período editado com sucesso.' })
        this.resetForm()
        this.$emit('updated')
        this.show = false
      } catch (error) {
        this.loading = false
        commonModule.showMessage({
          message: 'Algum erro salvar o período.',
          type: 'error',
        })
      }
    }

    this.loadingbtn = false
  }

  public async deletePeriodo(): Promise<void> {
    try {
      if (this.idPeriodo) {
        await periodicidadeService.delete(this.idPeriodo)
        commonModule.showMessage({ message: 'Período removido com sucesso.' })
        this.$emit('deleted')
      }
    } catch (e) {
      commonModule.showMessage({ message: 'Algum erro ao remover o período.', type: 'error' })
    }
  }

  public resetForm(): void {
    this.periodo = { descricao: '', anos: 1, meses: 1, dias: 1 }
    const form: any = this.$refs.form
    form.reset()
  }
}
