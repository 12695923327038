var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      flat: "",
                      "solo-inverted": "",
                      "hide-details": "",
                      "prepend-inner-icon": "mdi-magnify",
                      label: "Search",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn-toggle",
                    {
                      attrs: { mandatory: "" },
                      model: {
                        value: _vm.votoSelect,
                        callback: function ($$v) {
                          _vm.votoSelect = $$v
                        },
                        expression: "votoSelect",
                      },
                    },
                    [
                      _c("v-btn", { attrs: { value: "T" } }, [
                        _vm._v(" Todos "),
                      ]),
                      _c("v-btn", { attrs: { value: "S" } }, [
                        _vm._v(" Aprova "),
                      ]),
                      _c("v-btn", { attrs: { value: "N" } }, [
                        _vm._v(" Não aprova "),
                      ]),
                      _c("v-btn", { attrs: { value: "A" } }, [
                        _vm._v(" Abstenção "),
                      ]),
                      _c("v-btn", { attrs: { value: "I" } }, [
                        _vm._v(" Indefinido "),
                      ]),
                      _c("v-btn", { attrs: { value: "presentes" } }, [
                        _vm._v(" Presentes "),
                      ]),
                      _c("v-btn", { attrs: { value: "ausentes" } }, [
                        _vm._v(" Ausentes "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.headers,
              items: _vm.credorSort,
              "multi-sort": "",
              height: "100%",
              dense: "",
              "fixed-header": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.voto`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "text-truncate",
                          staticStyle: { "max-width": "300px" },
                          attrs: { title: item.voto },
                        },
                        [_c("voto-icon", { attrs: { voto: item.voto } })],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: `item.nome`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "text-truncate",
                          staticStyle: { "max-width": "300px" },
                          attrs: { title: item.nome },
                        },
                        [_vm._v(" " + _vm._s(item.nome) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: `item.presente`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "text-truncate",
                          staticStyle: { "max-width": "300px" },
                          attrs: { title: item.presente },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.descricaoPresente(item.presente)) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: `item.total`,
                  fn: function ({ item }) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$n(item.total, "currency"))),
                      ]),
                    ]
                  },
                },
                {
                  key: `item.data`,
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("moment")(item.data, "DD/MM/YYYY HH:mm:ss")
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }