
import { Prop, Vue, Component } from "vue-property-decorator";
import { assembleiaStore } from "@/store/assembleia/";

@Component({
  components: {},
})
export default class DialogConfig extends Vue {
  @Prop()
  assembleiaId!: number;
  public loading: boolean = false;
  public dialog: boolean = false;
  public classe: number = 0;

  public async salvarPresenca(presente): Promise<void> {
    if (confirm("Confirma acão para todos os credores?")) {
      this.loading = true;
      const classeAssembleia = this.classe;
      await assembleiaStore.marcarPresencaTodos({presente, classeAssembleia});
      this.loading = false;
    }
  }

  public async salvarVoto(opcao: string): Promise<void> {
    if (confirm("Confirma acão para todos os credores?")) {
      this.loading = true;
      const classeAssembleia = this.classe;
      await assembleiaStore.votarTodos({opcao, classeAssembleia});
      this.loading = false;
    }
  }
}
