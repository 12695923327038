
import {Component, Vue} from 'vue-property-decorator'
import LoadingApp from '@/components/layout/LoadingApp.vue'
import PageRouter from '@/components/layout/PageRouter.vue'
import Message from '@/components/layout/Message.vue'

@Component({
  components: { Message, PageRouter, LoadingApp }
})
export default class App extends Vue {
}
