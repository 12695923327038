<!-- @format -->

<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="credores"
      :items-per-page.sync="limiteSemPaginacao"
      multi-sort
      class="elevation-1"
      height="100%"
      dense
      fixed-header
    >
      <template v-slot:[`item.presente`]="{ item }">
        <action-presenca :credor="item"></action-presenca>
      </template>

      <template v-slot:[`item.nome`]="{ item }">
        <div class="text-truncate" style="max-width: 300px" :title="item.nome">
          {{ item.nome }}
        </div>
      </template>

      <template v-slot:[`item.observacao`]="{ item }">
        <action-observacao :credor="item"></action-observacao>
      </template>

      <template v-slot:[`item.voto`]="{ item }">
        <action-voto :credor="item"></action-voto>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <div class="text-center">
          {{ item.email }}
          <br />
          {{ item.senha }}
        </div>
      </template>

      <template v-slot:[`item.total`]="{ item }">
        <span>{{ $n(item.total, "currency") }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ActionVoto from "./ActionVoto";
import ActionPresenca from "./ActionPresenca.vue";
import ActionObservacao from "./ActionObservacao.vue";


export default {
  props: {
    credores: Array,
  },
  components: { ActionVoto, ActionPresenca, ActionObservacao },
  data: () => ({
    headers: [
      {
        text: "PRESENÇA",
        align: "start",
        sortable: false,
        value: "presente",
      },

      {
        text: "VOTO",
        align: "start",
        sortable: true,
        value: "voto",
      },
      { text: "CLASSE", sortable: true, value: "classe" },
      {
        text: "NOME",
        align: "start",
        sortable: true,
        value: "nome",
      },

      { text: "VALOR", value: "total", sortable: true, align: "end" },
      { text: "LOGIN", value: "email", sortable: true },

      {
        text: "PROCURADOR",
        align: "start",
        sortable: false,
        value: "observacao",
      },
    ],
  }),
  computed: {
    limiteSemPaginacao(){
      return this.credores.length > 1000 ? 15 : -1
    }
  }
};
</script>

<style scoped>
span.small {
  font-size: 8;
  line-height: 9;
}
</style>
