
import { Component, Prop, Vue } from 'vue-property-decorator'
import loginService from '@/services/login/LoginService'
import Bar from "./Bar.vue";

@Component({
  components: { Bar },
})
export default class PContent extends Vue {
  public me: any;

  @Prop()
  public readonly titulo?: string | null

  public logout(): void {
    loginService.logout()
  }


}
