<template>
  <v-menu
    ref="nowMenu"
    v-model="datepicker"
    :nudge-right="40"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="290px" offset-y
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :hide-details="hideDetailsInput"
        :disabled="disabledField"
        color="#015b41"
        :rules="rules"
        v-model="computedDateFormatted"
        :label="label"
        dense
        append-icon="mdi-calendar"
        readonly
        outlined
        v-on="on"
      />
    </template>
    <v-date-picker
        v-model="editableDate"
        locale="pt-br"
        no-title
        @input="datepicker=false"
    />
  </v-menu>
</template>
<script>
import moment from 'moment'
export default {
  name: 'r-date',
  props: {
    value: {
      default: null,
    },
    label: String,
    rules: Array,
    disabled: Boolean,
    hideDetails: Boolean,
  },
  data: (vm) => ({
    datepicker: false,
    nowMenu: false,
    disabledField: false,
    hideDetailsInput: false,
  }),
  computed: {
    computedDateFormatted: {
      get() {
        if(!this.value)
          return null;

        return moment(this.value).format("L")
      },
      set(date) {
        this.$emit('input', moment(date).format("YYYY-MM-DD"))
      }
    },
    editableDate: {
      get() {
        if(!this.value)
          return null;

        return moment(this.value).format("YYYY-MM-DD")
      },
      set(date) {
        this.$emit('input', moment(date).format("YYYY-MM-DD"))
      }
    },

  },
}
</script>
<style scoped>

</style>
