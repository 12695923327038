
import { Component, Prop, Vue } from "vue-property-decorator";
import PlanoParcela from "@/components/plano-recuperacao/PlanoCredores/PlanoParcela.vue";
import ModalPagar from "@/components/plano-recuperacao/ModalPagar.vue";

@Component({
  components: { PlanoParcela, ModalPagar },
})
export default class GrupoParcelas extends Vue {
  @Prop()
  public valores: any;

  @Prop()
  public mostrarCalculos!: boolean;


}
