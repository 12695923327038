var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _vm.isAdd
        ? [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "white--text bg-plano-orange",
                                attrs: { small: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.show = true
                                  },
                                },
                              },
                              on
                            ),
                            [
                              _vm._v(" Novo Período "),
                              _c("v-icon", { attrs: { right: "", dark: "" } }, [
                                _vm._v(" mdi-plus "),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3700431342
                ),
              },
              [_c("span", [_vm._v("Adicionar novo Período")])]
            ),
          ]
        : _vm.isUpdate
        ? [
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "blue--text",
                              attrs: { small: "", icon: "" },
                              on: { click: _vm.getPeriodoById },
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("mdi-comment-edit")])],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              },
              [_c("span", [_vm._v("Editar Período")])]
            ),
          ]
        : _vm.isDelete
        ? [
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "red--text",
                              attrs: { small: "", icon: "" },
                              on: { click: _vm.deletePeriodo },
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("mdi-comment-remove")])],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              },
              [_c("span", [_vm._v("Remover Período")])]
            ),
          ]
        : _vm._e(),
      [
        _c(
          "v-dialog",
          {
            attrs: { persistent: "", "max-width": "1500" },
            model: {
              value: _vm.show,
              callback: function ($$v) {
                _vm.show = $$v
              },
              expression: "show",
            },
          },
          [
            _vm.loading
              ? _c(
                  "v-card",
                  [
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "12" } }, [
                              _c(
                                "div",
                                { staticClass: "text-center mt-4" },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      width: "2",
                                      size: "50",
                                      color: "#015b41",
                                      indeterminate: "",
                                    },
                                  }),
                                  _c("p", [_vm._v("Carregando Informações")]),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "v-card",
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "headline" },
                      [
                        _c("v-col", { attrs: { cols: "11" } }, [
                          _vm._v(" Cadastro de Período "),
                        ]),
                        _c(
                          "v-col",
                          { staticClass: "text-right", attrs: { cols: "1" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", color: "grey" },
                                on: {
                                  click: function ($event) {
                                    _vm.show = false
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-close")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-form",
                          {
                            ref: "form",
                            attrs: { "lazy-validation": _vm.lazy },
                          },
                          [
                            _c(
                              "fieldset",
                              { staticClass: "fieldset-plano" },
                              [
                                _c("legend", [
                                  _vm._v("Informações do Período"),
                                ]),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Descrição",
                                            type: "text",
                                            dense: "",
                                            outlined: "",
                                            rules: _vm.rules.descricao,
                                          },
                                          model: {
                                            value: _vm.periodo.descricao,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.periodo,
                                                "descricao",
                                                $$v
                                              )
                                            },
                                            expression: "periodo.descricao",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "4" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Anos",
                                            type: "number",
                                            rules: _vm.rules.anos,
                                            dense: "",
                                            outlined: "",
                                          },
                                          model: {
                                            value: _vm.periodo.anos,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.periodo, "anos", $$v)
                                            },
                                            expression: "periodo.anos",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "4" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Meses",
                                            type: "number",
                                            rules: _vm.rules.meses,
                                            dense: "",
                                            outlined: "",
                                          },
                                          model: {
                                            value: _vm.periodo.meses,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.periodo,
                                                "meses",
                                                $$v
                                              )
                                            },
                                            expression: "periodo.meses",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "4" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Dias",
                                            type: "number",
                                            rules: _vm.rules.dias,
                                            dense: "",
                                            outlined: "",
                                          },
                                          model: {
                                            value: _vm.periodo.dias,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.periodo, "dias", $$v)
                                            },
                                            expression: "periodo.dias",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      [
                        _c("div", { staticClass: "flex-grow-1" }),
                        _vm.isAdd
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "ml-2 bg-plano-cinza white--text",
                                attrs: { small: "" },
                                on: { click: _vm.resetForm },
                              },
                              [
                                _c("v-icon", [_vm._v("mdi-eraser")]),
                                _vm._v(" Limpar Dados "),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isAdd
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "bg-plano-orange white--text",
                                attrs: { small: "", loading: _vm.loadingbtn },
                                on: { click: _vm.savePeriodo },
                              },
                              [
                                _c("v-icon", [_vm._v("mdi-plus")]),
                                _vm._v(" Cadastrar Período "),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isUpdate
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "bg-plano-orange white--text",
                                attrs: { small: "", loading: _vm.loadingbtn },
                                on: { click: _vm.updatePeriodo },
                              },
                              [
                                _c("v-icon", [_vm._v("mdi-comment-edit")]),
                                _vm._v(" Atualizar Período "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }