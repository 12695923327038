
import { Component, Vue } from 'vue-property-decorator'
import {commonModule} from '@/store/common'
import { UserCredentials } from '@/services/user/UserTypes'
import router from '@/router'
import loginService from '@/services/login/LoginService'

@Component
export default class Login extends Vue {

  public lazy: boolean = false
  public loading: boolean = false

  public rulesAuth: any = {
    email: [
      (v: any) => !!v || 'Preencha o e-mail',
      (v: any) => /.+@.+\..+/.test(v) || 'E-mail inválido',
    ],
    password: [(v: any) => !!v || 'Preencha a Senha'],
  }

  public auth: UserCredentials = {
    email: '',
    password: '',
  }

  public async authentication(): Promise<void> {
    const form: any = this.$refs.form

    if (form.validate()) {
      this.lazy = true
      this.loading = true

      try {
        const autenticate = await loginService.autenticate(this.auth)
        commonModule.setAuthDataUser(autenticate)
        await router.push({ name: 'home' })
      } catch (error) {
        this.loading = false
      }
    }
  }

}
