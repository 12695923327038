var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          attrs: {
            "max-width": "240",
            outlined: "",
            loading: _vm.loading,
            disabled: _vm.loading,
          },
        },
        [
          _c(
            "v-list-item",
            { attrs: { "three-line": "" } },
            [
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    { staticClass: "headline mb-1" },
                    [
                      _vm._v(" Planos Ativos "),
                      _c("v-icon", { attrs: { "x-large": "" } }, [
                        _vm._v("mdi-currency-usd"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-subtitle",
                    { staticStyle: { "font-size": "25px" } },
                    [_vm._v(_vm._s(_vm.qtdPlanosAtivos))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    block: "",
                    link: "",
                    to: { name: "plano" },
                  },
                },
                [_vm._v(" Ver Planos ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }