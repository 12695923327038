
import { Component, Prop, Watch, Vue} from 'vue-property-decorator'
import { DefaultSelect } from '@/common/Type'

@Component
export default class SelectTipoIndiceJuros extends Vue {

  @Prop()
  public rules?: []

  @Prop()
  public hideDetails?: any

  @Prop()
  public multiple?: any

  @Prop()
  public value: any

  public selecionado: any = this.value

  public selectOptions = {
    multiple: false,
    hideDetails: true,
    rules: [],
  }

  public status: DefaultSelect[] = [
    { label: 'Sem correção', value: '' },
    { label: 'SELIC', value: 'SELIC' },
    { label: 'CDI', value: 'CDI' },
    { label: 'TR', value: 'TR' },
    { label: 'IPCA', value: 'IPCA' },
    { label: 'IGPM', value: 'IGP_M' },
    { label: 'CDI MENSAL', value: 'CDI_MENSAL' },
  ]

  public mounted(): void {
    this.checarPropsOpcionais()
  }

  @Watch('value')
  public onValueChanged(val: any): void {
    this.selecionado = val
  }

  @Watch('selecionado')
  public onSelecionadoChanged(val: any): void {
    this.$emit('input', val)
    this.checarErrosValidacao(val)
  }

  public checarErrosValidacao(val: any): void {
    if (this.rules && this.rules.length > 0) {
      this.$emit('errors', val)
    }
  }

  public checarPropsOpcionais(): void {
    if (typeof this.multiple !== 'undefined') {
      this.selectOptions.multiple = true
    }

    if (typeof this.rules !== 'undefined') {
      this.selectOptions.rules = this.rules
    }

    if (typeof this.hideDetails !== 'undefined') {
      this.selectOptions.hideDetails = false
    }
  }
}
