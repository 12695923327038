
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Modal from "@/components/modal/Modal.vue";
import { DefaultHeaderTable } from "@/common/Type";
import planoRecuperacaoService from "@/services/plano-recuperacao/PlanoRecuperacaoService";
import { PlanoRecuperacaoData } from "@/services/plano-recuperacao/PlanoRecuperacaoTypes";
import ModalCondicaoCredor from "./ModalCondicaoCredor.vue";
import {
  ClasseCredor,
  Processo,
  ProcessoCredor,
} from "@/services/processo/ProcessoTypes";
import { CondicaoPagamento } from "@/services/condicao/CondicaoTypes";
import FormatterHelper from "@/helper/FormatterHelper";
import TabelaParcelas from "@/components/plano-recuperacao/PlanoCredores/TabelaParcelas.vue";
import { Credor } from "@/services/credor/CredorTypes";

@Component({
  components: { TabelaParcelas, ModalCondicaoCredor, Modal },
})
export default class ModalPlanoDetalhes extends Vue {
  @Prop()
  public plano!: PlanoRecuperacaoData;

  public formatter = FormatterHelper;

  @Prop()
  public value: boolean = false;

  public credores: ProcessoCredor[] = [];
  public processo: Processo | null = null;
  public classes: ClasseCredor[] = [];
  public condicoes: CondicaoPagamento[] = [];
  public searchCredores: string = "";

  public condicao: CondicaoPagamento = {} as CondicaoPagamento;
  public credor: Credor = {} as Credor;

  public headersCondicoes: DefaultHeaderTable[] = [
    { value: "descricao", text: "Nome", align: "start" },
    { value: "apartir", text: "Prazo", align: "start" },
    { value: "desagio", text: "Deságio", align: "start" },
    { value: "carencia", text: "Carência", align: "start" },
    { value: "juros_indice", text: "Correção e Juros", align: "start" },
    { value: "parcelas", text: "Parcelas", align: "start" },
  ];

  public headersCredores: DefaultHeaderTable[] = [
    { value: "nome", text: "Nome", align: "start" },
    { value: "valor", text: "Valor", align: "end" },
    { value: "desagio", text: "Deságio", align: "end" },
    { value: "pago_valor", text: "Valor Pago", align: "end" },
  ];

  public step: number = 1;

  get show() {
    return this.value;
  }

  set show(value) {
    this.$emit("input", value);
  }
  get currentButtonIcon() {
    switch (this.step) {
      case 1:
        return "mdi-close";
      default:
        return "mdi-chevron-left";
    }
  }
  get currentButtonText() {
    switch (this.step) {
      case 1:
        return "Fechar Visualização";
      default:
        return "Voltar";
    }
  }

  get currentTitle() {
    switch (this.step) {
      case 1:
        return "Condições do Plano";
      case 2:
        return "Credores da Condição";
      case 3:
        return "Parcelas do Credor";
      default:
        return "";
    }
  }

  get credoresSelecionados() {
    let credores: ProcessoCredor[] = [];
    if (this.credores.length > 0 && !this.isEmpty(this.condicao)) {
      credores = this.credores.filter((credor) =>
        credor.condicoes.includes(this.condicao.uuid)
      );
    }
    return credores;
  }

  public mounted(): void {}

  @Watch("show")
  public onChangeShow(show: boolean): void {
    if (show) {
      this.getCredores();
    } else {
      this.credores = [];
      this.condicao = {} as CondicaoPagamento;
      this.credor = {} as Credor;
      this.step = 1;
    }
  }

  public previousStep() {
    switch (this.step) {
      case 1:
        this.show = false;
        return;
      case 2:
        this.condicao = {} as CondicaoPagamento;
        break;
      case 3:
        this.credor = {} as Credor;
        break;
    }
    this.step--;
  }

  public clickCondicao(condicao: any) {
    this.condicao = condicao;
    this.step++;
  }

  public clickCredor(credor: any) {
    const condicaoId = this.condicao.id;
    const credorCondicao = credor.plano_credores_condicoes.find(
      (item) => condicaoId == item.plano_condicoes_id
    );
    this.getCredor(credorCondicao.id);
    this.step++;
  }

  public async getCredores(): Promise<void> {
    //this.loading = true;
    const credores = await planoRecuperacaoService.getCredores(this.plano.id);
    this.credores = credores.map((item) => {
      let credor = item.credor;
      credor.condicoes = item.plano_credores_condicoes.map(
        (condicao) => condicao.condicao
      );
      credor.plano_credores_condicoes = item.plano_credores_condicoes;
      return credor;
    });
    //this.loading = false;
  }

  public isEmpty(obj: any) {
    for (const i in obj) return false;

    return true;
  }
  public async getCredor(id): Promise<void> {
    //this.loading = true;
    this.credor = await planoRecuperacaoService.getCredor(id);
    //this.loading = false;
  }
}
