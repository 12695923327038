var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "blue--text",
                          attrs: { small: "", icon: "" },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm.parcela.bonus_adimplencia === 1
                        ? _c(
                            "v-icon",
                            { attrs: { color: _vm.bonus ? "blue" : "red" } },
                            [_vm._v("mdi-tag-arrow-down-outline")]
                          )
                        : _c(
                            "v-icon",
                            {
                              attrs: {
                                color: _vm.parcela.adimplente
                                  ? "blue"
                                  : "orange ",
                              },
                            },
                            [_vm._v("mdi-cash-register")]
                          ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                { ref: "form", attrs: { "v-model": _vm.validation } },
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Pagar Parcela "),
                    ]),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { md: "6" } },
                                [
                                  _c("r-date", {
                                    attrs: { label: "Data" },
                                    model: {
                                      value: _vm.pagamento.data,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.pagamento, "data", $$v)
                                      },
                                      expression: "pagamento.data",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "text-right display-1" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$n(_vm.pagamento.valor)) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm.parcela.bonus_adimplencia === 1 &&
                                  _vm.bonus
                                    ? _c(
                                        "v-container",
                                        { staticClass: "text-right" },
                                        [
                                          _vm.desconto
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-decoration-line-through",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$n(
                                                        _vm.parcela
                                                          .pagamento_valor,
                                                        "decimal2"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  "mdi-tag-arrow-down-outline"
                                                ),
                                              ]),
                                              _vm._v(
                                                " -" +
                                                  _vm._s(
                                                    _vm.$n(
                                                      _vm.desconto,
                                                      "decimal2"
                                                    )
                                                  )
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("hr"),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$n(
                                                  _vm.valorParcela,
                                                  "decimal2"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("input-upload", {
                                    attrs: {
                                      id: _vm.parcela.id,
                                      model:
                                        "\\Desize\\PlanoRecuperacao\\Models\\PlanoValoresParcelas",
                                      colecao: "arquivos",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [_vm._v(" Cancelar ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.submit()
                            },
                          },
                        },
                        [_vm._v(" Pagar ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }