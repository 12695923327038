var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-form", { ref: "form", attrs: { "lazy-validation": _vm.lazy } }, [
    _c(
      "fieldset",
      { staticClass: "fieldset-plano" },
      [
        _c("legend", [_vm._v("INFORMAÇÕES DO PLANO")]),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-text-field", {
                  attrs: {
                    rules: _vm.rules.descricao,
                    outlined: "",
                    dense: "",
                    label: "Nome da Condição",
                  },
                  model: {
                    value: _vm.condicao.descricao,
                    callback: function ($$v) {
                      _vm.$set(_vm.condicao, "descricao", $$v)
                    },
                    expression: "condicao.descricao",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("select-classes", {
                  attrs: {
                    rules: _vm.rules.classes,
                    processo: _vm.processo,
                    multiple: true,
                    "hide-details": false,
                  },
                  model: {
                    value: _vm.condicao.classes,
                    callback: function ($$v) {
                      _vm.$set(_vm.condicao, "classes", $$v)
                    },
                    expression: "condicao.classes",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "fieldset",
      { staticClass: "fieldset-plano" },
      [
        _c("legend", [_vm._v("REGRA DE PARCELAMENTOS")]),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "4" } },
              [
                _c("v-currency-field", {
                  attrs: {
                    label: "Prazo em Meses (Prazo Total: Parcelas + Carência)",
                    rules: _vm.rules.prazo,
                    type: "number",
                    dense: "",
                    outlined: "",
                    "decimal-length": 0,
                  },
                  on: {
                    input: _vm.calcularPrazo,
                    blur: _vm.atualizaTipoPagamento,
                  },
                  model: {
                    value: _vm.condicao.prazo,
                    callback: function ($$v) {
                      _vm.$set(_vm.condicao, "prazo", _vm._n($$v))
                    },
                    expression: "condicao.prazo",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "4" } },
              [
                _c("v-currency-field", {
                  attrs: {
                    label: "Carência em Meses",
                    rules: _vm.rules.carencia,
                    type: "number",
                    dense: "",
                    outlined: "",
                    "decimal-length": 0,
                  },
                  on: {
                    input: _vm.calcularPrazo,
                    blur: _vm.atualizaTipoPagamento,
                  },
                  model: {
                    value: _vm.condicao.carencia,
                    callback: function ($$v) {
                      _vm.$set(_vm.condicao, "carencia", _vm._n($$v))
                    },
                    expression: "condicao.carencia",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "4" } },
              [
                _c("v-currency-field", {
                  attrs: {
                    label: "Número de Parcelas",
                    rules: _vm.rules.parcelas,
                    type: "number",
                    dense: "",
                    outlined: "",
                    "decimal-length": 0,
                    readonly: "",
                  },
                  model: {
                    value: _vm.condicao.parcelas,
                    callback: function ($$v) {
                      _vm.$set(_vm.condicao, "parcelas", _vm._n($$v))
                    },
                    expression: "condicao.parcelas",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: _vm.showDataManualParcelas ? 4 : 6 } },
              [
                _c("select-a-partir-de", {
                  attrs: {
                    processo: _vm.processo,
                    rules: _vm.rules.select_a_partir_de,
                  },
                  on: { input: _vm.changeApartirDeParcelas },
                  model: {
                    value: _vm.apartir,
                    callback: function ($$v) {
                      _vm.apartir = $$v
                    },
                    expression: "apartir",
                  },
                }),
              ],
              1
            ),
            _vm.showDataManualParcelas
              ? _c(
                  "v-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    _vm.showDataManualParcelas
                      ? _c("r-date", {
                          attrs: {
                            label: "Data",
                            rules: _vm.rules.gerenic_date,
                          },
                          on: { input: _vm.changeApartirDeParcelas },
                          model: {
                            value: _vm.condicao.apartir,
                            callback: function ($$v) {
                              _vm.$set(_vm.condicao, "apartir", $$v)
                            },
                            expression: "condicao.apartir",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-col",
              { attrs: { cols: "12", md: _vm.showDataManualParcelas ? 4 : 6 } },
              [
                _c("select-periodicidades", {
                  attrs: {
                    rules: _vm.rules.periodicidade_id,
                    "hide-details": false,
                  },
                  on: { label: _vm.periodicidadeChanged },
                  model: {
                    value: _vm.condicao.periodicidade_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.condicao, "periodicidade_id", $$v)
                    },
                    expression: "condicao.periodicidade_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12" } },
              [
                _c("select-vencimento", {
                  attrs: {
                    rules: _vm.rules.dia_vencimento,
                    "hide-details": false,
                  },
                  on: { label: _vm.vencimentoChanged },
                  model: {
                    value: _vm.condicao.dia_vencimento,
                    callback: function ($$v) {
                      _vm.$set(_vm.condicao, "dia_vencimento", $$v)
                    },
                    expression: "condicao.dia_vencimento",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "fieldset",
      { staticClass: "fieldset-plano" },
      [
        _c("legend", [_vm._v("JUROS E CORREÇÕES")]),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: _vm.showDataManualJuros ? 3 : 4 } },
              [
                _c("v-currency-field", {
                  attrs: {
                    suffix: "%",
                    label: "Percentual de Deságio",
                    rules: _vm.rules.desagio,
                    type: "number",
                    dense: "",
                    outlined: "",
                  },
                  model: {
                    value: _vm.condicao.desagio,
                    callback: function ($$v) {
                      _vm.$set(_vm.condicao, "desagio", _vm._n($$v))
                    },
                    expression: "condicao.desagio",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: _vm.showDataManualJuros ? 3 : 4 } },
              [
                _c("v-currency-field", {
                  attrs: {
                    suffix: "%",
                    label: "Juros Mensal",
                    rules: _vm.rules.juros_mes,
                    type: "number",
                    dense: "",
                    outlined: "",
                  },
                  model: {
                    value: _vm.condicao.juros_mes,
                    callback: function ($$v) {
                      _vm.$set(_vm.condicao, "juros_mes", _vm._n($$v))
                    },
                    expression: "condicao.juros_mes",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: _vm.showDataManualJuros ? 3 : 4 } },
              [
                _c("select-a-partir-de", {
                  attrs: {
                    processo: _vm.processo,
                    rules: _vm.rules.select_a_partir_de,
                  },
                  on: { input: _vm.changeApartirDeJuros },
                  model: {
                    value: _vm.apartirJuros,
                    callback: function ($$v) {
                      _vm.apartirJuros = $$v
                    },
                    expression: "apartirJuros",
                  },
                }),
              ],
              1
            ),
            _vm.showDataManualJuros
              ? _c(
                  "v-col",
                  { attrs: { cols: "12", md: "3" } },
                  [
                    _vm.showDataManualJuros
                      ? _c("r-date", {
                          attrs: {
                            label: "Data",
                            rules: _vm.rules.gerenic_date,
                          },
                          on: { input: _vm.changeApartirDeJuros },
                          model: {
                            value: _vm.condicao.juros_mensal_apartir,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.condicao,
                                "juros_mensal_apartir",
                                $$v
                              )
                            },
                            expression: "condicao.juros_mensal_apartir",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-col",
              { attrs: { cols: "12", md: _vm.showDataManualCorrecao ? 3 : 4 } },
              [
                _c("select-tipo-indice-juros", {
                  attrs: {
                    rules: _vm.rules.tp_juros_indice,
                    "hide-details": false,
                  },
                  model: {
                    value: _vm.condicao.tp_juros_indice,
                    callback: function ($$v) {
                      _vm.$set(_vm.condicao, "tp_juros_indice", $$v)
                    },
                    expression: "condicao.tp_juros_indice",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: _vm.showDataManualCorrecao ? 3 : 4 } },
              [
                _c("v-currency-field", {
                  attrs: {
                    suffix: "%",
                    locale: "pt-br",
                    label: "Correção de Índice",
                    rules: _vm.rules.juros_indice,
                    type: "number",
                    dense: "",
                    outlined: "",
                  },
                  model: {
                    value: _vm.condicao.juros_indice,
                    callback: function ($$v) {
                      _vm.$set(_vm.condicao, "juros_indice", _vm._n($$v))
                    },
                    expression: "condicao.juros_indice",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: _vm.showDataManualCorrecao ? 3 : 4 } },
              [
                _c("select-a-partir-de", {
                  attrs: {
                    processo: _vm.processo,
                    rules: _vm.rules.select_a_partir_de,
                  },
                  on: { input: _vm.changeApartirDeCorrecao },
                  model: {
                    value: _vm.apartirCorrecao,
                    callback: function ($$v) {
                      _vm.apartirCorrecao = $$v
                    },
                    expression: "apartirCorrecao",
                  },
                }),
              ],
              1
            ),
            _vm.showDataManualCorrecao
              ? _c(
                  "v-col",
                  { attrs: { cols: "12", md: "3" } },
                  [
                    _vm.showDataManualCorrecao
                      ? _c("r-date", {
                          attrs: {
                            label: "Data",
                            rules: _vm.rules.gerenic_date,
                          },
                          on: { input: _vm.changeApartirDeCorrecao },
                          model: {
                            value: _vm.condicao.juros_indice_apartir,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.condicao,
                                "juros_indice_apartir",
                                $$v
                              )
                            },
                            expression: "condicao.juros_indice_apartir",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "fieldset",
      { staticClass: "fieldset-plano" },
      [
        _c("legend", [_vm._v("CONFIGURAÇÕES DE LANÇAMENTO")]),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-select", {
                  attrs: {
                    label: "Operador de Pagamento",
                    dense: "",
                    outlined: "",
                    "item-text": "label",
                    "item-value": "value",
                    items: _vm.operadoresPagamento,
                  },
                  model: {
                    value: _vm.condicao.operador,
                    callback: function ($$v) {
                      _vm.$set(_vm.condicao, "operador", $$v)
                    },
                    expression: "condicao.operador",
                  },
                }),
                _c("v-checkbox", {
                  attrs: {
                    label: "Sem dividir o valor do crédito",
                    required: "",
                  },
                  model: {
                    value: _vm.condicao.valor_unico,
                    callback: function ($$v) {
                      _vm.$set(_vm.condicao, "valor_unico", $$v)
                    },
                    expression: "condicao.valor_unico",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _vm.condicao.operador === "Até"
                  ? [
                      _c("v-currency-field", {
                        attrs: {
                          prefix: "R$",
                          locale: "pt-br",
                          type: "number",
                          dense: "",
                          outlined: "",
                          label: "Valor de Pagamento Até",
                        },
                        model: {
                          value: _vm.condicao.vl_limite_ate,
                          callback: function ($$v) {
                            _vm.$set(_vm.condicao, "vl_limite_ate", _vm._n($$v))
                          },
                          expression: "condicao.vl_limite_ate",
                        },
                      }),
                    ]
                  : _vm.condicao.operador === "Entre"
                  ? [
                      _c("v-currency-field", {
                        attrs: {
                          prefix: "R$",
                          locale: "pt-br",
                          type: "number",
                          dense: "",
                          outlined: "",
                          label: "Valor de Pagamento Início",
                        },
                        model: {
                          value: _vm.condicao.vl_limite_entre_inicio,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.condicao,
                              "vl_limite_entre_inicio",
                              _vm._n($$v)
                            )
                          },
                          expression: "condicao.vl_limite_entre_inicio",
                        },
                      }),
                      _c("v-currency-field", {
                        attrs: {
                          prefix: "R$",
                          locale: "pt-br",
                          type: "number",
                          dense: "",
                          outlined: "",
                          label: "Valor de Pagamento Fim",
                        },
                        model: {
                          value: _vm.condicao.vl_limite_entre_fim,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.condicao,
                              "vl_limite_entre_fim",
                              _vm._n($$v)
                            )
                          },
                          expression: "condicao.vl_limite_entre_fim",
                        },
                      }),
                    ]
                  : _vm.condicao.operador === "Acima de"
                  ? [
                      _c("v-currency-field", {
                        attrs: {
                          prefix: "R$",
                          locale: "pt-br",
                          type: "number",
                          dense: "",
                          outlined: "",
                          label: "Valor de Pagamento Acima",
                        },
                        model: {
                          value: _vm.condicao.vl_limite_acima,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.condicao,
                              "vl_limite_acima",
                              _vm._n($$v)
                            )
                          },
                          expression: "condicao.vl_limite_acima",
                        },
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "v-col",
              { staticClass: "subtitle-2 text-center", attrs: { cols: "12" } },
              [_c("p", [_vm._v(_vm._s(_vm.montarTextoPlanoConfiguracao()))])]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "fieldset",
      { staticClass: "fieldset-plano" },
      [
        _c("legend", [_vm._v("CONDIÇÕES DE PAGAMENTO")]),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "4" } },
              [
                _c("v-select", {
                  attrs: {
                    label: "Tipo",
                    dense: "",
                    outlined: "",
                    "item-text": "label",
                    "item-value": "value",
                    items: _vm.tiposPagamento,
                  },
                  on: { change: _vm.atualizaTipoPagamento },
                  model: {
                    value: _vm.condicao.pagamento.tipo,
                    callback: function ($$v) {
                      _vm.$set(_vm.condicao.pagamento, "tipo", $$v)
                    },
                    expression: "condicao.pagamento.tipo",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.condicao.pagamento.tipo === "rampa" && _vm.metadataRampa.length > 0
          ? _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "12" } },
                  [
                    _c("v-simple-table", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-left",
                                        staticStyle: { width: "10em" },
                                      },
                                      [_vm._v(" Ano ")]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        class:
                                          _vm.sumOfMetadata !== 100
                                            ? "error--text text-left"
                                            : "text-left",
                                      },
                                      [
                                        _vm._v(
                                          " Valor (" +
                                            _vm._s(_vm.sumOfMetadata) +
                                            " / 100) " +
                                            _vm._s(
                                              _vm.sumOfMetadata !== 100
                                                ? "O somatório dos valores de rampa deve resultar em 100%"
                                                : ""
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.metadataRampa, function (item) {
                                    return _c("tr", { key: item.ano }, [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(item.ano) +
                                            " - " +
                                            _vm._s(
                                              _vm.parcelasAno[item.ano].length
                                            ) +
                                            "/12"
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          _c("v-currency-field", {
                                            attrs: {
                                              "hide-details": "",
                                              suffix: "%",
                                              rules: [
                                                () =>
                                                  _vm.sumOfMetadata === 100 ||
                                                  "O valor total precisa ser exatamente 100%",
                                              ],
                                              type: "number",
                                              dense: "",
                                              outlined: "",
                                            },
                                            model: {
                                              value: item.valor,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "valor",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "item.valor",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4155091220
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "fieldset",
      { staticClass: "fieldset-plano" },
      [
        _c("legend", [_vm._v("BÔNUS DE ADIMPLÊNCIA")]),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "4" } },
              [
                _c("v-select", {
                  attrs: {
                    label: "Tipo",
                    dense: "",
                    outlined: "",
                    "item-text": "label",
                    "item-value": "value",
                    items: _vm.bonusAdimplencia,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.getCalendario()
                    },
                  },
                  model: {
                    value:
                      _vm.condicao.pagamento.metadata.bonusAdimplencia.tipo,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.condicao.pagamento.metadata.bonusAdimplencia,
                        "tipo",
                        $$v
                      )
                    },
                    expression:
                      "condicao.pagamento.metadata.bonusAdimplencia.tipo",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              [
                _c("v-currency-field", {
                  attrs: {
                    suffix: "%",
                    locale: "pt-br",
                    label: "Percentual Mensal",
                    rules: _vm.rules.juros_indice,
                    type: "number",
                    dense: "",
                    outlined: "",
                  },
                  model: {
                    value:
                      _vm.condicao.pagamento.metadata.bonusAdimplencia
                        .percentual,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.condicao.pagamento.metadata.bonusAdimplencia,
                        "percentual",
                        _vm._n($$v)
                      )
                    },
                    expression:
                      "condicao.pagamento.metadata.bonusAdimplencia.percentual",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.condicao.pagamento.metadata.bonusAdimplencia.tipo === 1
          ? _c(
              "v-row",
              _vm._l(_vm.parcelas, function (parcela) {
                return _c(
                  "v-col",
                  { key: parcela.id, attrs: { cols: "2" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-card-title",
                          [
                            _c("v-checkbox", {
                              key: parcela.value,
                              attrs: {
                                label: parcela.label,
                                dense: "",
                                outlined: "",
                                row: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setParcelas()
                                },
                              },
                              model: {
                                value: parcela.bonus,
                                callback: function ($$v) {
                                  _vm.$set(parcela, "bonus", $$v)
                                },
                                expression: "parcela.bonus",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          [
                            _c("v-currency-field", {
                              attrs: {
                                suffix: "%",
                                locale: "pt-br",
                                label: "Percentual",
                                rules: _vm.rules.juros_indice,
                                type: "number",
                                dense: "",
                                outlined: "",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setParcelas()
                                },
                              },
                              model: {
                                value: parcela.percentual,
                                callback: function ($$v) {
                                  _vm.$set(parcela, "percentual", _vm._n($$v))
                                },
                                expression: "parcela.percentual",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            )
          : _vm._e(),
        _vm.condicao.pagamento.metadata.bonusAdimplencia.tipo === 2
          ? _c(
              "v-row",
              _vm._l(_vm.ultimaParcelasAno, function (parcela) {
                return _c(
                  "v-col",
                  { key: parcela.id, attrs: { cols: "2" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-card-title",
                          [
                            _c("v-checkbox", {
                              key: parcela.value,
                              attrs: {
                                label: parcela.label,
                                dense: "",
                                outlined: "",
                                row: "",
                              },
                              model: {
                                value: parcela.bonus,
                                callback: function ($$v) {
                                  _vm.$set(parcela, "bonus", $$v)
                                },
                                expression: "parcela.bonus",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          [
                            _c("v-currency-field", {
                              attrs: {
                                suffix: "%",
                                locale: "pt-br",
                                label: "Percentual",
                                rules: _vm.rules.juros_indice,
                                type: "number",
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value: parcela.percentual,
                                callback: function ($$v) {
                                  _vm.$set(parcela, "percentual", _vm._n($$v))
                                },
                                expression: "parcela.percentual",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }