var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "p-content",
    { attrs: { titulo: "Gerenciamento de Períodos" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("modal-periodicidade", {
                        attrs: { "is-add": true, "is-update": false },
                        on: { added: _vm.getPeriodos },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _vm.loading
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-progress-linear", {
                            attrs: {
                              active: _vm.loading,
                              indeterminate: "",
                              height: "2",
                              bottom: "",
                              color: "#9e7830",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mb-2",
                            attrs: {
                              "append-icon": "mdi-magnify",
                              label: "Procurar",
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.periodos,
                              search: _vm.search,
                              "single-line": "",
                              "item-key": "id",
                              "calculate-widths": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: `item.acao`,
                                  fn: function ({ item }) {
                                    return [
                                      _c("modal-periodicidade", {
                                        attrs: {
                                          "is-add": false,
                                          "is-update": true,
                                          "id-periodo": item.id,
                                        },
                                        on: { updated: _vm.getPeriodos },
                                      }),
                                      _c("modal-periodicidade", {
                                        attrs: {
                                          "is-add": false,
                                          "is-update": false,
                                          "id-periodo": item.id,
                                          "is-delete": true,
                                        },
                                        on: { deleted: _vm.getPeriodos },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }