export default new class FormatterHelper {
  public onlyNumbers(value: string) {
    return value.replace(/\D/g, '')
  }

  public valorBrToDecimal(value: string | any): number {
    return typeof value === 'number' ? value : parseFloat(value.replace(/\./g, '').replace(',', '.'))
  }

  public valorDecimalToBr(value: number | null): string {
    return value === null ? '' : value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }

  public currencyBRL(value: number | null): string {
    return value === null ? '' : value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  public valueBRLToNumber(value: string): number {
    return +value
      .replace('R$ ', '')
      .replace(/\./gi, '')
      .replace(',', '.')
  }

  public numberToPercent(value: number | null): string {
    return value === null ? '' : value.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'
  }

}()
