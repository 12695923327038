var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("modal", {
    attrs: { titulo: _vm.currentTitle, width: "1500" },
    scopedSlots: _vm._u([
      {
        key: "text",
        fn: function () {
          return [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-subtitle", [
                                  _vm._v("Identificador do Plano"),
                                ]),
                                _c("v-list-item-title", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.plano ? _vm.plano.id : "N/A"
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-subtitle", [
                                  _vm._v("Processo"),
                                ]),
                                _c("v-list-item-title", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.plano ? _vm.plano.processo_id : "N/A"
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-subtitle", [
                                  _vm._v("Empresas"),
                                ]),
                                _c("v-list-item-title", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.plano && _vm.plano.empresas
                                        ? _vm.plano.empresas
                                            .map(
                                              (empresa) => empresa.nome || ""
                                            )
                                            .join(", ")
                                        : "N/A"
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.step > 1
                          ? _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-subtitle", [
                                      _vm._v("Condição"),
                                    ]),
                                    _c("v-list-item-title", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.condicao.descricao
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.step > 2
                                  ? _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-subtitle", [
                                          _vm._v("Credor"),
                                        ]),
                                        _c("v-list-item-title", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.credor.plano_credor
                                                ? _vm.credor.plano_credor.credor
                                                    .nome
                                                : ""
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-window",
              {
                model: {
                  value: _vm.step,
                  callback: function ($$v) {
                    _vm.step = $$v
                  },
                  expression: "step",
                },
              },
              [
                _c(
                  "v-window-item",
                  { attrs: { value: 1 } },
                  [
                    _c(
                      "v-data-table",
                      {
                        staticClass: "elevation-1 row-pointer",
                        attrs: {
                          headers: _vm.headersCondicoes,
                          items: _vm.plano ? _vm.plano.plano_condicoes : [],
                          search: _vm.searchCredores,
                          "single-line": "",
                          "item-key": "id",
                          "calculate-widths": "",
                        },
                        on: { "click:row": _vm.clickCondicao },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "top",
                              fn: function () {
                                return [
                                  _c("v-text-field", {
                                    staticClass: "mx-4",
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      label: "Pesquisar",
                                    },
                                    model: {
                                      value: _vm.searchCredores,
                                      callback: function ($$v) {
                                        _vm.searchCredores = $$v
                                      },
                                      expression: "searchCredores",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: `item.apartir`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .$moment(item.apartir)
                                          .format("DD/MM/YYYY")
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.desagio`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatter.valorDecimalToBr(
                                          item.desagio * 100
                                        )
                                      ) +
                                      " % "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.juros_indice`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatter.valorDecimalToBr(
                                          item.juros_indice * 100
                                        )
                                      ) +
                                      " % "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.parcelas`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(item.parcelas) + " vezes "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_vm._v(" // eslint-disable-next-line vue/valid-v-slot ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-window-item",
                  { attrs: { value: 2 } },
                  [
                    _c(
                      "v-data-table",
                      {
                        staticClass: "elevation-1 row-pointer",
                        attrs: {
                          headers: _vm.headersCredores,
                          items: _vm.credoresSelecionados,
                          search: _vm.searchCredores,
                          "sort-by": "nm_credor",
                          "single-line": "",
                          "item-key": "id",
                          "calculate-widths": "",
                        },
                        on: { "click:row": _vm.clickCredor },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "top",
                              fn: function () {
                                return [
                                  _c("v-text-field", {
                                    staticClass: "mx-4 mt-2",
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      label: "Pesquisar",
                                    },
                                    model: {
                                      value: _vm.searchCredores,
                                      callback: function ($$v) {
                                        _vm.searchCredores = $$v
                                      },
                                      expression: "searchCredores",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: `item.valor`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatter.currencyBRL(
                                          parseFloat(
                                            item.plano_credores_condicoes.find(
                                              (item) =>
                                                _vm.condicao.id ==
                                                item.plano_condicoes_id
                                            ).valor || 0
                                          )
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.desagio`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatter.currencyBRL(
                                          item.valor *
                                            (1 - _vm.condicao.desagio)
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.pago_valor`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatter.currencyBRL(
                                          item.pago_valor
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_vm._v(" // eslint-disable-next-line vue/valid-v-slot ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-window-item",
                  { attrs: { value: 3 } },
                  [
                    _c("tabela-parcelas", {
                      attrs: {
                        valores: _vm.credor.valores,
                        condicao: _vm.condicao,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "action",
        fn: function () {
          return [
            _c("div", { staticClass: "flex-grow-1" }),
            _c(
              "v-btn",
              {
                staticClass: "bg-plano-orange white--text",
                attrs: { small: "" },
                on: { click: _vm.previousStep },
              },
              [
                _c("v-icon", [_vm._v(_vm._s(_vm.currentButtonIcon))]),
                _vm._v(" " + _vm._s(_vm.currentButtonText) + " "),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v
      },
      expression: "show",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }