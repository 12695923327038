var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.loading
        ? _c("v-checkbox", {
            on: {
              change: function ($event) {
                return _vm.presenca()
              },
            },
            model: {
              value: _vm.credor.presente,
              callback: function ($$v) {
                _vm.$set(_vm.credor, "presente", $$v)
              },
              expression: "credor.presente",
            },
          })
        : _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }