<template>
  <div class="grid grid-rows-2 grid-flow-col">
    <div>
      <quill-editor
        class="quill-full-page mb-12"
        ref="myQuillEditor"
        v-model="texto"
        :options="editorOption"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
      />
    </div>
    {{ texto }}
  </div>
</template>

<script>
// You can also register Quill modules in the component
import editorOption from "@/plugins/quill";
import "@/tailwind.css";

export default {
  data() {
    return {
      texto: "<h2>Texto 1</h2>",
      editorOption,
    };
  },
  methods: {
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  mounted() {
    console.log("this is current quill instance object", this.editor);
  },
};
</script>
<style scoped>
.quill-full-page {
  width: 832px;
  height: 625px;
}
</style>
