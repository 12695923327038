var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-simple-table", {
                attrs: { dense: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("thead", [
                          _c("th", [_vm._v(_vm._s(_vm.titulo))]),
                          _c("th", [_vm._v("Qtd.")]),
                          _c("th", [_vm._v("%")]),
                          _c("th", [_vm._v("Valor")]),
                          _c("th", [_vm._v("%")]),
                        ]),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [_vm._v("Aprova")]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.resultado.aprova.credores)),
                            ]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$n(
                                      _vm.resultado.aprova.credores_perc,
                                      "percent"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$n(
                                      _vm.resultado.aprova.creditos,
                                      "currency"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$n(
                                      _vm.resultado.aprova.creditos_perc,
                                      "percent"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", { attrs: { classe: "header-coll" } }, [
                              _vm._v("Não aprova"),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.resultado.naoAprova.credores)),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$n(
                                    _vm.resultado.naoAprova.credores_perc,
                                    "percent"
                                  )
                                )
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$n(
                                    _vm.resultado.naoAprova.creditos,
                                    "currency"
                                  )
                                )
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$n(
                                    _vm.resultado.naoAprova.creditos_perc,
                                    "percent"
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", { attrs: { classe: "header-coll" } }, [
                              _vm._v("Abstem"),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.$n(_vm.resultado.abstem.credores))
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$n(
                                    _vm.resultado.abstem.credores_perc,
                                    "percent"
                                  )
                                )
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$n(
                                    _vm.resultado.abstem.creditos,
                                    "currency"
                                  )
                                )
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$n(
                                    _vm.resultado.abstem.creditos_perc,
                                    "percent"
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", { attrs: { classe: "header-coll" } }, [
                              _vm._v("Ausente"),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.$n(_vm.resultado.ausente.credores))
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$n(
                                    _vm.resultado.ausente.credores_perc,
                                    "percent"
                                  )
                                )
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$n(
                                    _vm.resultado.ausente.creditos,
                                    "currency"
                                  )
                                )
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$n(
                                    _vm.resultado.ausente.creditos_perc,
                                    "percent"
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c("tr", { staticClass: "h3" }, [
                            _c("td", { attrs: { classe: "header-coll" } }, [
                              _vm._v("Presente"),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.$n(_vm.resultado.presente.credores))
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$n(
                                    _vm.resultado.presente.credores_perc,
                                    "percent"
                                  )
                                )
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$n(
                                    _vm.resultado.presente.creditos,
                                    "currency"
                                  )
                                )
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$n(
                                    _vm.resultado.presente.creditos_perc,
                                    "percent"
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("v-progress-linear", {
                attrs: { color: "primary" },
                model: {
                  value: _vm.precentualVotos,
                  callback: function ($$v) {
                    _vm.precentualVotos = $$v
                  },
                  expression: "precentualVotos",
                },
              }),
              _c("v-progress-linear", {
                model: {
                  value: _vm.credoresAprovaPerc,
                  callback: function ($$v) {
                    _vm.credoresAprovaPerc = $$v
                  },
                  expression: "credoresAprovaPerc",
                },
              }),
              _c("v-progress-linear", {
                attrs: { color: "amber" },
                model: {
                  value: _vm.creditosAprovaPerc,
                  callback: function ($$v) {
                    _vm.creditosAprovaPerc = $$v
                  },
                  expression: "creditosAprovaPerc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }