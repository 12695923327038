/** @format */

import { DateTimeFormat } from "vue-i18n";

export interface AssembleiaCredorCreate {
  nome: string;
  documento: string;
  empresa : string;
  total: number;
  classe: string;
  presente: boolean;
  voto: string;
  data: Date;
  email: string;
  senha: string;
  observacao: string;
}

export interface AssembleiaCredor extends AssembleiaCredorCreate {
  id: number;
}

export interface AssembleiaCredorCsv {
  nome: string;
  empresa: string;
  valor: string;
  classe: string;
  presente: string;
  voto: string;
  ip: string;
  email: string;
  senha: string;
  observacao: string;
}


export interface AssembleiaVotoCreate {
  id: number;
  opcao: string;
}
export interface AssembleiaVoto extends AssembleiaVotoCreate {
  ip: string;
  data: Date;
}

export interface AssembleiaPresencaCreate {
  voto_id: number;
  presente: boolean;
}

export interface AssembleiaPresenca extends AssembleiaPresencaCreate {
  ip: string;
  data: Date;
}

export interface AssembleiaObservacaoCreate {
  voto_id: number;
  observacao: string;
}

export interface AssembleiaObservacao extends AssembleiaObservacaoCreate {
  ip: string;
  data: Date;
}

export interface AssembleiaPresencaTodosCreate {
  assembleia_id: number;
  presente: boolean;
  classe: number;
}

export interface AssembleiaVotoTodosCreate {
  assembleia_id: number;
  opcao: string;
  classe: number;
}

export interface AssembleiaResultado {
  credores: number;
  creditos: number;
  credores_perc: number;
  creditos_perc: number;
}

export interface AssembleiaResumo {
  aprova: AssembleiaResultado;
  naoAprova: AssembleiaResultado;
  abstem: AssembleiaResultado;
  indefinido: AssembleiaResultado;
  presente: AssembleiaResultado;
  ausente: AssembleiaResultado;
  total: AssembleiaResultado;
}
export interface AssembleiaBase {
  id: number;
  titulo: string;
  numero: number;
  empresa: string;
  data: string;
  processo_id: number;
}
