import { commonModule } from '@/store/common'
import router from '@/router'
import http from '@/common/HttpRequest'
import { AuthDataUser, UserCredentials } from '@/services/user/UserTypes'

class LoginService {
  public saveAuthDataUser(): void {
    localStorage.setItem('authData', JSON.stringify(commonModule.auth))
  }

  public async autenticate(auth: UserCredentials): Promise<AuthDataUser> {
    return await http
      .post<AuthDataUser>('auth/login', auth)
      .then(response => response.data)
  }

  public async me(): Promise<any> {
    return await http
      .post<AuthDataUser>('auth/me', commonModule.auth)
      .then(response => response.data)
  }

  public tryRestoreAuthDataUser(): void {
    const authData = localStorage.getItem('authData')



    if (authData && (authData !== JSON.stringify(commonModule.auth))) {
      commonModule.setAuthDataUser(JSON.parse(authData))
    }
  }

  public logout(): void {
    commonModule.setAuthDataUser(null)
    localStorage.clear()
    router.push({name: 'login'}, () => {
    })
  }
}

const loginService = new LoginService()
export default loginService
