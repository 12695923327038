<template>
  <div>
    
    <v-icon
      v-if="voto === 'I'" title="Indefinido"
    >
      mdi-vote
    </v-icon>
    <v-icon v-else-if="voto === 'S'" color="green darken-2" title="Aprova">
      mdi-thumb-up-outline
    </v-icon>
    <v-icon v-else-if="voto === 'N'" color="red darken-2" title="Não aprova">
      mdi-thumb-down-outline
    </v-icon>
    <v-icon v-else-if="voto === 'A'" color="yellow darken-2" title="Abstem">
      mdi-thumbs-up-down
    </v-icon>
  </div>
</template>

<script>

export default {
  props: {
    voto: {
      type: String,
      required: true,
    },
  },
}
</script>