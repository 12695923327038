import { SnackbarMessage, VMoneyOrPercentType } from '@/common/Type'
import { commonModule } from '@/store/common'

const mixin = {
  methods: {
    loadingApp(): boolean {
      return commonModule.loadingApp
    },
    snackbarMessage(): SnackbarMessage | null {
      return commonModule.message
    },
    fecharMensagem(): void {
      commonModule.closeMessage()
    },
    getMaskPercent(): VMoneyOrPercentType {
      return {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      }
    },
  },
}

export default mixin
