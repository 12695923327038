
import {Component, Prop, Vue} from 'vue-property-decorator'
import Modal from '@/components/modal/Modal.vue'
import FormCondicaoPagamento from './FormCondicaoPagamento.vue'
import {DefaultHeaderTable} from '@/common/Type'
import {
  PlanoRecuperacaoClassesView,
  PlanoRecuperacaoEmpresaView,
  PlanoRecuperacaoView
} from '@/services/plano-recuperacao/PlanoRecuperacaoTypes'
import ModalCondicaoCredor from './ModalCondicaoCredor.vue'
import FormatterHelper from '@/helper/FormatterHelper'

@Component({
  components: {ModalCondicaoCredor, FormCondicaoPagamento, Modal},
})
export default class ModalPlanoRecuperacao extends Vue {
  @Prop()
  public plano!: PlanoRecuperacaoView

  public show: boolean = false

  public headersCredores: DefaultHeaderTable[] = [
    {value: 'nm_credor', text: 'Nome do Credor', align: 'start'},
    {value: 'valor', text: 'Valor a Receber', align: 'start'},
    {value: 'acoes', text: 'Ações', sortable: false, align: 'center'},
  ]

  public getTotalClasses(classe: PlanoRecuperacaoClassesView): string {
    let valor = 0.00

    for (const i of classe.empresas) {
      valor += i.credores.map((e) => e.valor).reduce((accum, value) => accum + value)
    }

    return FormatterHelper.valorDecimalToBr(valor)
  }

  public getValorTotalEmpresa(empresa: PlanoRecuperacaoEmpresaView): string {
    const valor = empresa.credores.map((e) => e.valor).reduce((accum, value) => accum + value)
    return FormatterHelper.valorDecimalToBr(valor)
  }
}
