
import { Vue, Component } from "vue-property-decorator";
import MainApp from "./MainApp.vue";
import Navigation from "./Navigation.vue";
import Bar from "./Bar.vue";

@Component({
  components: { Bar, Navigation, MainApp },
})
export default class PageRouter extends Vue {
  public drawer: boolean = true;

  public drawerChanged(drawer: boolean): void {
    this.drawer = drawer;
  }
}
