
import { assembleiaStore } from "@/store/assembleia/";
import { Component, Vue } from "vue-property-decorator";
import VotoService from "@/services/assembleia/voto";
import AssembleiaGraficosClasse from "./AssembleiaGraficosClasse.vue";
import AssembleiaService from "@/services/assembleia/AssembleiaService";

import router from "@/router";
import { broadcast } from "@/plugins/pusher";
import {
  AssembleiaCredor,
  AssembleiaResumo,
} from "../../services/assembleia/AssembleiaTypes";
import Print from "@/services/print";

@Component({
  components: { AssembleiaGraficosClasse },
})
export default class Dashboard extends Vue {
  public loading: boolean = true;
  public showTable: boolean = true;
  public showTotal: boolean = false;
  public empresa: string = "";
  public mostraDashboard: string = "plano";

  get credores(): AssembleiaCredor[] {
    return assembleiaStore.credores;
  }

  get credoresFiltro(): AssembleiaCredor[] {
    let credores = assembleiaStore.credores;

    if (this.empresa !== "") {
      credores = credores.filter((credor) => credor.empresa === this.empresa);
    }
    return credores;
  }

  get resumo(): AssembleiaResumo[] {
    return VotoService.resumo(this.credoresFiltro);
  }

  get assembleia(): any {
    return assembleiaStore.assembleiaBase;
  }

  get empresas(): Array<string> {
    return AssembleiaService.getEmpresas(this.credoresFiltro);
  }

  get empresaSelect(): Array<any> {
    let empresas = AssembleiaService.getEmpresas(this.credores)
      .sort()
      .map((empresa) => ({ value: empresa, text: empresa }));
    return [{ value: "", text: `Todas empresas` }, ...empresas];
  }

  get resumoMostrar() {
    const allowed =
      this.mostraDashboard == "suspensao" ? ["Total"] : ["1", "2", "3", "4"];

    return Object.keys(this.resumo)
      .filter((key) => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.resumo[key];
        return obj;
      }, {});
  }
  async mounted(): Promise<void> {
    this.loading = true;
    const assembleiaId = Number(router.currentRoute.params.id);
    await assembleiaStore.getAssembleia(assembleiaId);
    await assembleiaStore.getCredores(assembleiaId);
    broadcast.subscribeAssembleia(10 * 1000);
    this.loading = false;
  }
  public async print(): Promise<void> {
    Print.el(this.$refs.printMe as HTMLCanvasElement);
  }
}
