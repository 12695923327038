import {
  PeriodicidadeData,
  ResponseDeletePeriodo,
  ResponseEditPeriodo,
  ResponseSavePeriodo
} from '@/services/periodicidade/PeriodicidadeTypes'
import http from '@/common/HttpRequest'
import DateHelper from '@/helper/DateHelper'

class PeriodicidadeService {

  private readonly endpointBase: string = 'plano-recuperacao/periodicidades'

  public async getPeriodos(): Promise<PeriodicidadeData[]> {
    return await http
      .get<PeriodicidadeData[]>(this.endpointBase)
      .then((response: any) => response.data.data)
  }

  public async getPeriodoById(id: number): Promise<PeriodicidadeData> {
    return await http
      .get<PeriodicidadeData>(this.endpointBase + '/' + id)
      .then((response: any) => response.data.data)
  }

  public async save(periodoData: PeriodicidadeData): Promise<ResponseSavePeriodo> {
    const periodo = {
      descricao: periodoData.descricao,
      interval: DateHelper.createIntervalString({
        anos: periodoData.anos,
        meses: periodoData.meses,
        dias: periodoData.dias,
      })
    }

    return await http
      .post<ResponseSavePeriodo>(this.endpointBase, periodo)
      .then(response => response.data)
  }

  public async update(periodoData: PeriodicidadeData, id: number | null): Promise<ResponseEditPeriodo> {
    const descricao: string = periodoData.descricao
    const interval: string = DateHelper.createIntervalString({
      anos: periodoData.anos,
      meses: periodoData.meses,
      dias: periodoData.dias,
    })

    return await http
      .put<ResponseEditPeriodo>(this.endpointBase + '/' + id, { descricao, interval })
      .then(response => response.data)
  }

  public async delete(id: number | null): Promise<ResponseDeletePeriodo> {
    return await http
      .delete<ResponseDeletePeriodo>(this.endpointBase + '/' + id)
      .then(response => response.data)
  }
}

const periodicidadeService = new PeriodicidadeService()
export default periodicidadeService
