import { render, staticRenderFns } from "./SelectPeriodicidades.vue?vue&type=template&id=328ce93b&scoped=true"
import script from "./SelectPeriodicidades.vue?vue&type=script&lang=ts"
export * from "./SelectPeriodicidades.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "328ce93b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/projetos/admjudicial-api-l8/releases/424/frontend/plano-recuperacao-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('328ce93b')) {
      api.createRecord('328ce93b', component.options)
    } else {
      api.reload('328ce93b', component.options)
    }
    module.hot.accept("./SelectPeriodicidades.vue?vue&type=template&id=328ce93b&scoped=true", function () {
      api.rerender('328ce93b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/form/select/SelectPeriodicidades.vue"
export default component.exports