var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-container grid grid-rows-2 grid-flow-col" },
    [
      _c(
        "div",
        [
          _c("quill-editor", {
            ref: "myQuillEditor",
            staticClass: "quillEditorHalf",
            attrs: { options: _vm.editorOption },
            on: {
              blur: function ($event) {
                return _vm.onEditorBlur($event)
              },
              focus: function ($event) {
                return _vm.onEditorFocus($event)
              },
              ready: function ($event) {
                return _vm.onEditorReady($event)
              },
            },
            model: {
              value: _vm.texto,
              callback: function ($$v) {
                _vm.texto = $$v
              },
              expression: "texto",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("quill-editor", {
            ref: "myQuillEditor",
            staticClass: "quillEditorHalf",
            attrs: { options: _vm.editorOption },
            on: {
              blur: function ($event) {
                return _vm.onEditorBlur($event)
              },
              focus: function ($event) {
                return _vm.onEditorFocus($event)
              },
              ready: function ($event) {
                return _vm.onEditorReady($event)
              },
            },
            model: {
              value: _vm.texto2,
              callback: function ($$v) {
                _vm.texto2 = $$v
              },
              expression: "texto2",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-span-2" },
        [
          _c("quill-editor", {
            ref: "myQuillEditor",
            staticClass: "quillEditor",
            attrs: { options: _vm.editorOption },
            on: {
              blur: function ($event) {
                return _vm.onEditorBlur($event)
              },
              focus: function ($event) {
                return _vm.onEditorFocus($event)
              },
              ready: function ($event) {
                return _vm.onEditorReady($event)
              },
            },
            model: {
              value: _vm.texto3,
              callback: function ($$v) {
                _vm.texto3 = $$v
              },
              expression: "texto3",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }