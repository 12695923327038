var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "v-app",
        { attrs: { id: "inspire" } },
        [
          _c(
            "v-app-bar",
            { attrs: { app: "", dark: "", "clipped-left": "" } },
            [
              _c(
                "v-toolbar-title",
                { staticClass: "ml-0 pl-4", staticStyle: { width: "300px" } },
                [
                  _c("span", { staticClass: "hidden-sm-and-down" }, [
                    _vm._v("RMA"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-main",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c("v-form", { ref: "form" }, [
                    _c(
                      "fieldset",
                      { staticClass: "fieldset-plano" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    label: "Título",
                                  },
                                  model: {
                                    value: _vm.titulo,
                                    callback: function ($$v) {
                                      _vm.titulo = $$v
                                    },
                                    expression: "titulo",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    items: _vm.layouts,
                                    label: "Layouts",
                                  },
                                  model: {
                                    value: _vm.layoutsSelect,
                                    callback: function ($$v) {
                                      _vm.layoutsSelect = $$v
                                    },
                                    expression: "layoutsSelect",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "6", md: "6" } },
                              [
                                _vm.layoutsSelect == "Layout1"
                                  ? _c("layout-1")
                                  : _vm._e(),
                                _vm.layoutsSelect == "Layout2"
                                  ? _c("layout-2")
                                  : _vm._e(),
                                _vm.layoutsSelect == "Layout3"
                                  ? _c("layout-3")
                                  : _vm._e(),
                                _vm.layoutsSelect == "Layout4"
                                  ? _c("layout-4")
                                  : _vm._e(),
                                _vm.layoutsSelect == "Layout5"
                                  ? _c("layout-5")
                                  : _vm._e(),
                                _vm.layoutsSelect == "Layout6"
                                  ? _c("layout-6")
                                  : _vm._e(),
                                _vm.layoutsSelect == "Layout7"
                                  ? _c("layout-7")
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("v-col", { attrs: { cols: "6", md: "6" } }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }