/** @format */

import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

const numberFormats: any = {
  pt: {
    currency: {
      style: "currency",
      currency: "BRL",
      currencyDisplay: "symbol",
    },
    percent: {
      style: "percent",
      minimumFractionDigits: 2,
    },
    percent4: {
      style: "percent",
      minimumFractionDigits: 4,
    },
    decimal2: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    decimal4: {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    },

    csv: {
      useGrouping: false,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "pt",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "pt",
  messages: loadLocaleMessages(),
  numberFormats,
});
