
import {Component, Vue} from 'vue-property-decorator'
import PContent from '@/components/layout/PContent.vue'
import {DefaultHeaderTable} from '@/common/Type'
import {PeriodicidadeData} from '@/services/periodicidade/PeriodicidadeTypes'
import periodicidadeService from '@/services/periodicidade/PeriodicidadeService'
import ModalPeriodicidade from '@/components/periodicidade/ModalPeriodicidade.vue'

@Component({
  components: {ModalPeriodicidade, PContent }
})
export default class Periodicidade extends Vue {

  public loading: boolean = true
  public search: string = ''
  public headers: DefaultHeaderTable[] = [
    { text: 'Descrição', value: 'descricao' },
    { text: 'Dias', value: 'dias' },
    { text: 'Meses', value: 'meses' },
    { text: 'Anos', value: 'anos' },
    { text: 'Cadastrado em', value: 'created_at' },
    { text: 'Ações', value: 'acao' },
  ]

  public periodos: PeriodicidadeData[] = []

  public mounted(): void {
    this.getPeriodos()
  }

  public async getPeriodos(): Promise<void> {
    this.loading = true
    this.periodos = await periodicidadeService.getPeriodos()
    this.loading = false
  }
}
