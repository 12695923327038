
import { Component, Vue, Prop } from "vue-property-decorator";
import { AssembleiaResumo } from "../../services/assembleia/AssembleiaTypes";

@Component({
  components: {},
})
export default class AssembleiaClasse extends Vue {
  @Prop()
  titulo!: string;
  @Prop()
  resultado!: AssembleiaResumo;

  get credoresAprovaPerc() {
    return this.resultado.aprova.credores_perc * 100;
  }
  get creditosAprovaPerc() {
    return this.resultado.aprova.creditos_perc * 100;
  }

  get presentesVotaram(): number {
    return (
      this.resultado.aprova.credores +
      this.resultado.naoAprova.credores +
      this.resultado.abstem.credores
    );
  }
  get precentualVotos(): number {
    return this.presentesVotaram / this.resultado.presente.credores;
  }
}
