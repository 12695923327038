var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.conversao
    ? _c(
        "span",
        { staticClass: "font-italic caption", attrs: { title: _vm.title } },
        [
          _vm._v(
            " " + _vm._s(_vm.$n(_vm.conversao.conversao, "currency")) + " "
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }