var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("modal", {
    attrs: {
      titulo: "Lista de Credores Sem Planos de Pagamento",
      width: "900",
    },
    scopedSlots: _vm._u([
      {
        key: "text",
        fn: function () {
          return [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c("v-data-table", {
                      attrs: {
                        items: _vm.credoresSemPLano,
                        headers: _vm.headersCredores,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "item.valor",
                          fn: function ({ item }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("toCurrencyBRL")(item.valor))
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v
      },
      expression: "showModal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }