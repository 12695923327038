var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "inspire", loading: _vm.loading } },
    [
      _c(
        "v-app-bar",
        { attrs: { app: "", dark: "", "clipped-left": "" } },
        [
          _c(
            "v-toolbar-title",
            { staticClass: "ml-0 pl-4", staticStyle: { width: "300px" } },
            [
              _vm.assembleia
                ? _c("span", { staticClass: "hidden-sm-and-down" }, [
                    _vm._v(
                      "#" +
                        _vm._s(_vm.assembleia.id) +
                        " " +
                        _vm._s(_vm.assembleia.titulo) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _c("v-select", {
            staticClass: "ml-0 pl-4 mt-3",
            attrs: { items: _vm.empresaSelect, label: "Empresa" },
            model: {
              value: _vm.empresa,
              callback: function ($$v) {
                _vm.empresa = $$v
              },
              expression: "empresa",
            },
          }),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "ma-2 black--text",
              attrs: { color: "white", title: "Imprimir" },
              on: {
                click: function ($event) {
                  return _vm.print()
                },
              },
            },
            [_vm._v(" Imprimir ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "ma-2 black--text",
              attrs: { color: "white", title: "Quantidade" },
              on: {
                click: function ($event) {
                  _vm.graficoPercentual = false
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-numeric")])],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "ma-2 black--text",
              attrs: { color: "white", title: "Percentual" },
              on: {
                click: function ($event) {
                  _vm.graficoPercentual = true
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-percent")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c("v-progress-linear", {
            attrs: {
              active: _vm.loading,
              indeterminate: _vm.loading,
              absolute: "",
              top: "",
            },
          }),
          _c(
            "div",
            { ref: "printMe" },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    _vm._l(_vm.resumo, function (resultado, classe) {
                      return _c(
                        "v-col",
                        { key: classe },
                        [
                          classe !== "Total"
                            ? _c("assembleia-graficos-presenca", {
                                attrs: {
                                  titulo: "Classe " + classe,
                                  resultado: resultado,
                                  graficoPercentual: _vm.graficoPercentual,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.ultimosPresentes,
                  "items-per-page": 20,
                  height: "100%",
                  dense: "",
                  "fixed-header": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.classe`,
                      fn: function ({ item }) {
                        return [_vm._v(" Classe " + _vm._s(item.classe) + " ")]
                      },
                    },
                    {
                      key: `item.total`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.$n(item.total, "currency")) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.data`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("moment")(item.data, "HH:mm:ss")) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
          _c("img", { attrs: { src: _vm.output } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }