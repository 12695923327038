
import { Component, Vue } from "vue-property-decorator";
import AssembleiaResumoPainel from "@/components/assembleia/AssembleiaResumoPainel.vue";
import AssembleiaCredores from "@/components/assembleia/AssembleiaCredores.vue";
import Format from "@/services/format";
import AssembleiaService from "@/services/assembleia/AssembleiaService";
import VotoService from "@/services/assembleia/voto";
import { assembleiaStore } from "@/store/assembleia/";
import { AssembleiaBase } from "../../services/assembleia/AssembleiaTypes";

@Component({
  components: { AssembleiaResumoPainel, AssembleiaCredores },
})
export default class Assembleias extends Vue {
  public search: string = "";
  public loading: boolean = true;
  public drawer: boolean = false;

  async mounted(): Promise<void> {
    this.loading = true;
    await assembleiaStore.carregaAssembleias();
    this.loading = false;
  }

  get headers() {
    return [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "id",
      },
      {
        text: "Título",
        align: "start",
        sortable: false,
        value: "titulo",
      },
      {
        text: "Empresa",
        align: "start",
        sortable: false,
        value: "empresa",
      },
      {
        text: "Data",
        align: "start",
        sortable: false,
        value: "data",
      },
      {
        text: "",
        align: "start",
        sortable: false,
        value: "acao",
      },
    ];
  }

  get assembleias(): AssembleiaBase[] {
    return assembleiaStore.assembleias;
  }

  get assembleiasFiltro(): AssembleiaBase[] {
    if (this.search === "") {
      return assembleiaStore.assembleias;
    }

    return (
      assembleiaStore.assembleias.filter(
        (assembleia) =>
          Format.search(assembleia.titulo, this.search) ||
          Format.search(assembleia.empresa, this.search)
      ) || []
    );
  }

}
