import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store'
import { ShowMessageMutation, SnackbarMessage } from '@/common/Type'
import loginService from '@/services/login/LoginService'
import { AuthDataUser } from '@/services/user/UserTypes'

@Module({
  name: 'commonModule',
  namespaced: true,
  store,
  dynamic: true,
})
class Common extends VuexModule {

  public loadingApp: boolean = false
  public message: SnackbarMessage | null = null
  public auth: AuthDataUser | null = null
  public me: any | null = null

  @Mutation
  public setAuthDataUser(authData: AuthDataUser | null): void {
    this.auth = authData
    loginService.saveAuthDataUser()
  }

  @Mutation
  public setLoadingApp(status: boolean): void {
    this.loadingApp = status
  }

  @Mutation
  public showMessage(showMessageMutation: ShowMessageMutation): void {
    this.message = { ...{ timeout: 5000, type: 'default', show: true }, ...showMessageMutation }
  }

  @Mutation
  public closeMessage(): void {
    this.message = null
  }
}

export const commonModule = getModule(Common)
