
import { Component, Vue, Watch } from 'vue-property-decorator'
import loginService from '@/services/login/LoginService'

@Component
export default class Bar extends Vue {

  public drawerAction: boolean = true

  public logout(): void {
    loginService.logout()
  }

  @Watch('drawerAction')
  public onChangeDrawer(drawerValue: boolean): void {
    this.$emit('drawerChanged', drawerValue)
  }
}
