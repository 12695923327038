
import { Component, Prop } from 'vue-property-decorator'
import ComponentSelectDefault from '@/components/form/select/ComponentSelectDefault'
import { DefaultSelect } from '@/common/Type'
import condicaoService from '@/services/condicao/CondicaoService'
import { CondicaoData } from '@/services/condicao/CondicaoTypes'

@Component
export default class SelectCondicoes extends ComponentSelectDefault {

  @Prop({ default: 'Condições de Pagamento' })
  public label?: string

  public condicoes: DefaultSelect[] = []
  public loading: boolean = true
  public disabled: boolean = false

  public mounted(): void {
    this.getCondicoes()
    this.checarPropsOpcionais()
  }

  public async getCondicoes(): Promise<void> {
    const condicoes: CondicaoData[] = await condicaoService.get()

    for (const p of condicoes) {
      this.condicoes.push({label: p.descricao, value: p.id})
    }

    this.loading = false
  }
}
