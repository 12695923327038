var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.$route.meta.isFullPage
        ? [_c("router-view")]
        : [
            _c("navigation", { attrs: { drawer: _vm.drawer } }),
            _c("bar", { on: { drawerChanged: _vm.drawerChanged } }),
            _c(
              "main-app",
              [_c("router-view", { key: _vm.$route.fullPath })],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }