<template>
  <div id="app">
    <v-app id="inspire">
      <v-app-bar app dark clipped-left>
        <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
          <span class="hidden-sm-and-down">RMA</span>
        </v-toolbar-title>
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <v-form ref="form">
            <fieldset class="fieldset-plano">
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="titulo"
                    outlined
                    dense
                    label="Título"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-autocomplete
                    dense
                    outlined
                    v-model="layoutsSelect"
                    :items="layouts"
                    label="Layouts"
                  />
                </v-col>
                <v-col cols="6" md="6">
                  <layout-1 v-if="layoutsSelect == 'Layout1'"></layout-1>
                  <layout-2 v-if="layoutsSelect == 'Layout2'"></layout-2>
                  <layout-3 v-if="layoutsSelect == 'Layout3'"></layout-3>
                  <layout-4 v-if="layoutsSelect == 'Layout4'"></layout-4>
                  <layout-5 v-if="layoutsSelect == 'Layout5'"></layout-5>
                  <layout-6 v-if="layoutsSelect == 'Layout6'"></layout-6>
                  <layout-7 v-if="layoutsSelect == 'Layout7'"></layout-7>
                </v-col>
                <v-col cols="6" md="6"> </v-col>
              </v-row>
            </fieldset>
          </v-form>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Layout1 from "./Layout1.vue";
import Layout2 from "./Layout2.vue";
import Layout3 from "./Layout3.vue";
import Layout4 from "./Layout4.vue";
import Layout5 from "./Layout5.vue";
import Layout6 from "./Layout6.vue";
import Layout7 from "./Layout7.vue";

export default {
  components: {
    Layout1,
    Layout2,
    Layout3,
    Layout4,
    Layout5,
    Layout6,
    Layout7,
  },
  data() {
    return {
      titulo: "Teste de Título",
      subtitulo: "Teste de Subtitulo",
      layoutsSelect: "Layout1",
      layouts: [
        "Layout1",
        "Layout2",
        "Layout3",
        "Layout4",
        "Layout5",
        "Layout6",
        "Layout7",
      ],
    };
  },
  methods: {
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
  },
  mounted() {
    console.log("this is current quill instance object", this.editor);
  },
};
</script>
<style scoped>
.page-print {
  width: 800px;
  height: 600px;
}
.quill-full-page {
  width: 832px;
  height: 625px;
}
</style>
