<template>
  <div class="quillEditorContainer">
    <div>
      <quill-editor
        class="quillEditor"
        ref="myQuillEditor"
        v-model="texto"
        :options="editorOption"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
      />
    </div>
    <!-- Two-way Data-Binding -->
    <div>
      <quill-editor
        class="quillEditor"
        ref="myQuillEditor"
        v-model="texto2"
        :options="editorOption"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
      />
    </div>
  </div>
</template>

<script>
// You can also register Quill modules in the component
import editorOption from "@/plugins/quill";

export default {
  data() {
    return {
      texto: "<h2>Texto 1</h2>",
      texto2: "<h2>Texto 2</h2>",
      editorOption,
    };
  },
  methods: {
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  mounted() {
    console.log("this is current quill instance object", this.editor);
  },
};
</script>
<style scoped>
.quillEditor {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 600px;
}

.quillEditorContainer {
  display: flex;
  justify-content: start;
  align-items: flex-start;
}
</style>
