
import { Component, Vue, Prop } from "vue-property-decorator";
import { AssembleiaCredor } from "../../services/assembleia/AssembleiaTypes";
import { assembleiaStore } from "@/store/assembleia/";

@Component({
  components: {},
})
export default class ActionVoto extends Vue {
  @Prop()
  public credor!: AssembleiaCredor;

  public loading: boolean = false;

  public async salvarObservacao(): Promise<void> {
    this.loading = true;
    try {
      await assembleiaStore.salvarObservacao({
        voto_id: this.credor.id,
        observacao: this.credor.observacao,
      });
    } finally {
      this.loading = false;
    }
  }
}
