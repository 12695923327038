/** @format */

import http from "@/common/HttpRequest";
import {
  PlanoRecuperacaoData,
  PlanoRecuperacaoEmpresasProcesso,
} from "@/services/plano-recuperacao/PlanoRecuperacaoTypes";
import FormatterHelper from "@/helper/FormatterHelper";
import DateHelper from "@/helper/DateHelper";
import { commonModule } from "@/store/common";
import { ProcessoEmpresa } from "@/services/processo/ProcessoTypes";

class PlanoRecuperacaoService {
  private readonly endpointBase: string = "plano-recuperacao";

  public async getPlanosRecuperacao(): Promise<PlanoRecuperacaoData[]> {
    return await http
      .get(this.endpointBase)
      .then((response: any) => response.data);
  }

  public async getCredores(id: number): Promise<any[]> {
    return await http
      .get(`${this.endpointBase}/${id}/credores`)
      .then((response: any) => response.data);
  }

  public async getCredor(id: string): Promise<any> {
    return await http
      .get(`${this.endpointBase}/credores/${id}`)
      .then((response: any) => response.data);
  }

  public async getEmpresasProcesso(
    idProcesso: number
  ): Promise<PlanoRecuperacaoEmpresasProcesso[]> {
    return await http
      .get(`${this.endpointBase}/processos/${idProcesso}/empresas`)
      .then((response: any) => response.data);
  }

  public async delete(plano_id: number): Promise<PlanoRecuperacaoData> {
    return await http
      .delete<PlanoRecuperacaoData[]>(`${this.endpointBase}/${plano_id}`)
      .then((response: any) => response.data.data);
  }

  public async save(
    plano: PlanoRecuperacaoData,
    plano_id: number | undefined = undefined
  ): Promise<PlanoRecuperacaoData> {
    if (typeof plano_id === "undefined") {
      return await http
        .post<PlanoRecuperacaoData[]>(this.endpointBase, plano)
        .then((response: any) => response.data.data);
    } else {
      plano.id = plano_id;
      return await http
        .put<PlanoRecuperacaoData[]>(`${this.endpointBase}/${plano_id}`, plano)
        .then((response: any) => response.data.data);
    }
  }

  public async pagar(pagamento: any): Promise<any> {
    return await http
      .post(`${this.endpointBase}/pagar`, pagamento)
      .then((response: any) => response.data);
  }

  public async estornar(pagamento: any): Promise<any> {
    return await http
      .delete(`${this.endpointBase}/estornar/${pagamento.id}`, pagamento)
      .then((response: any) => response.data);
  }

  public async calendario(condicao: any): Promise<any> {
    return await http
      .post(`${this.endpointBase}/simulador/calendario`, condicao)
      .then((response: any) => response.data);
  }
}

const planoRecuperacaoService = new PlanoRecuperacaoService();
export default planoRecuperacaoService;
