
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import moment from 'moment'
import { Processo } from '@/services/processo/ProcessoTypes'
import { DefaultSelect } from '@/common/Type'

interface DatasProcesso {
  dataPedido: string,
  dataDeferimento: string,
  dataConcessao: string,
}

@Component
export default class SelectAPartirDe extends Vue {

  @Prop() processo!: Processo

  @Prop()
  public rules?: []

  @Prop()
  public hideDetails?: {
    default: true,
    type: any
  }

  @Prop()
  public multiple?: {
    default: false,
    type: any
  }

  @Prop()
  public value: any

  public selecionado: any = this.value

  public selectOptions = {
    multiple: false,
    hideDetails: true,
    rules: [],
  }

  public loading: boolean = false
  public disabled: boolean = false

  public apartir: DefaultSelect[] = []

  public mounted(): void {

    const { dataPedido, dataDeferimento, dataConcessao } = this.getDatasProcesso()

    this.apartir = [
      { label: 'Data do Pedido - ' + moment(dataPedido).format('L'), value: dataPedido },
      { label: 'Data de Deferimento RJ - ' + moment(dataDeferimento).format('L'), value: dataDeferimento },
      { label: 'Data de Concessão RJ - ' + moment(dataConcessao).format('L'), value: dataConcessao },
      { label: 'Data Manual', value: 'NA' },
    ]

    this.checarPropsOpcionais()
  }

  public getDatasProcesso(): DatasProcesso {
    const dataPedido: string = moment(this.processo.data_pedido).format('YYYY-MM-DD')
    const dataDeferimento: string = moment(this.processo.data).format('YYYY-MM-DD')
    const dataConcessao: string = moment(this.processo.data_concessao).format('YYYY-MM-DD')

    return { dataPedido, dataDeferimento, dataConcessao }
  }

  @Watch('value')
  public onValueChanged(val: any): void {
    const exists = this.apartir.filter(item => item.value === val).shift()
    if (!exists && val !== '') {
      this.selecionado = 'NA'
    } else {
      this.selecionado = val
    }
    this.onSelecionadoChanged(val)
  }

  @Watch('selecionado')
  public onSelecionadoChanged(val: any): void {
    this.$emit('input', val)
    this.checarErrosValidacao(val)
  }

  public checarErrosValidacao(val: any): void {
    if (this.rules && this.rules.length > 0) {
      this.$emit('errors', val)
    }
  }

  public checarPropsOpcionais(): void {
    this.selectOptions.multiple = (typeof this.multiple !== 'undefined')
    this.selectOptions.hideDetails = (typeof this.hideDetails !== 'undefined')
    if (typeof this.rules !== 'undefined') {
      this.selectOptions.rules = this.rules
    }
  }

}
