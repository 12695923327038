
import { Component, Prop, Vue } from "vue-property-decorator";
import planoRecuperacaoService from "@/services/plano-recuperacao/PlanoRecuperacaoService";
import PlanoCredor from "@/components/plano-recuperacao/PlanoCredores/CardCredor.vue";

@Component({
  components: { PlanoCredor },
})
export default class PlanoCredores extends Vue {
  @Prop()
  public loading!: boolean;
  @Prop()
  public planoId!: number;

  public credores: any[] = [];

  public mounted(): void {
    this.getCredores();
  }

  public async getCredores(): Promise<void> {
    this.loading = true
    this.credores = await planoRecuperacaoService.getCredores(this.planoId);
    this.loading = false
  }
}
