
import { Component, Prop, Watch } from 'vue-property-decorator'
import ComponentSelectDefault from '@/components/form/select/ComponentSelectDefault'
import processoService from '@/services/processo/ProcessoService'
import planoRecuperacaoService from '@/services/plano-recuperacao/PlanoRecuperacaoService'
import { eventBus } from '@/main'
import { Processo } from '@/services/processo/ProcessoTypes'
import {
  PlanoRecuperacaoEmpresasProcesso,
  PlanoRecuperacaoEmpresasProcessoSelect
} from "@/services/plano-recuperacao/PlanoRecuperacaoTypes";

@Component
export default class SelectProcessoEmpresas extends ComponentSelectDefault {

  @Prop()
  public idProcesso!: number

  @Prop()
  public existePlano: boolean | undefined;

  public empresas: PlanoRecuperacaoEmpresasProcessoSelect[] = []
  public loading: boolean = false
  public disabled: boolean = false
  public proc: Processo | null = null

  public mounted(): void {
    this.getEmpresas()
    this.checarPropsOpcionais()
  }

  public async getEmpresas(): Promise <void> {
    this.loading = true
    const empresas = await planoRecuperacaoService.getEmpresasProcesso(this.idProcesso);
    this.empresas = empresas.map((e) : PlanoRecuperacaoEmpresasProcessoSelect => (
      Object.assign({ disabled: !e.existePlano === this.existePlano}, e)
    ));
    this.loading = false
  }

  public async getProcesso() : Promise<Processo> {
    return await processoService.getProcesso(this.idProcesso);
  }

  @Watch('value')
  public onChangeValue(idEmpresa: number[]): void {
    if (idEmpresa && idEmpresa.length > 0) {
      this.getProcesso().then(function(processo) {
        eventBus.$emit('empresaSelecionada', processo)
      });
    }
  }

  @Watch('idProcesso')
  public onChangeIdEmpresa(idProcesso: number | null): void {
    if (idProcesso) {
      this.getEmpresas()
    } else {
      this.empresas = [];
    }
  }
}
