
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class PlanoCredores extends Vue {
  @Prop()
  public stages;

  public filterEmpty(stage) {
   
    return Object.keys(stage)
      .filter(key => stage[key])
      .reduce((obj, key) => {
        obj[key] = stage[key];
        return obj;
      }, {});

  }
}
