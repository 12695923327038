var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "v-app",
        { attrs: { id: "inspire" } },
        [
          _vm.credores
            ? _c(
                "v-navigation-drawer",
                {
                  attrs: { width: 360, app: "", clipped: "" },
                  model: {
                    value: _vm.drawer,
                    callback: function ($$v) {
                      _vm.drawer = $$v
                    },
                    expression: "drawer",
                  },
                },
                [
                  _c("assembleia-resumo-painel", {
                    attrs: { resumo: _vm.resumo },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-app-bar",
            { attrs: { app: "", dark: "", "clipped-left": "" } },
            [
              _c("v-app-bar-nav-icon", {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.drawer = !_vm.drawer
                  },
                },
              }),
              _c("v-app-bar-title", { staticClass: "ml-0 pl-2" }, [
                _vm.assembleia
                  ? _c(
                      "span",
                      { staticClass: "hidden-sm-and-down text-subtitle-1" },
                      [_vm._v(_vm._s(_vm.assembleia.titulo))]
                    )
                  : _vm._e(),
              ]),
              _c("v-spacer"),
              _c("v-text-field", {
                attrs: {
                  flat: "",
                  "solo-inverted": "",
                  "hide-details": "",
                  "prepend-inner-icon": "mdi-magnify",
                  label: "Search",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c("v-checkbox", {
                staticClass: "ml-0 pl-4 mt-3",
                attrs: { label: "Presentes" },
                model: {
                  value: _vm.presentes,
                  callback: function ($$v) {
                    _vm.presentes = $$v
                  },
                  expression: "presentes",
                },
              }),
              _c("v-checkbox", {
                staticClass: "ml-0 pl-4 mt-3",
                attrs: { label: "Indefinidos" },
                model: {
                  value: _vm.presentesParaVotos,
                  callback: function ($$v) {
                    _vm.presentesParaVotos = $$v
                  },
                  expression: "presentesParaVotos",
                },
              }),
              _c("v-select", {
                staticClass: "ml-0 pl-4 mt-3",
                attrs: { items: _vm.classeSelect, label: "Classe" },
                model: {
                  value: _vm.classe,
                  callback: function ($$v) {
                    _vm.classe = $$v
                  },
                  expression: "classe",
                },
              }),
              _c("v-select", {
                staticClass: "ml-0 pl-4 mt-3",
                attrs: { items: _vm.empresaSelect, label: "Empresa" },
                model: {
                  value: _vm.empresa,
                  callback: function ($$v) {
                    _vm.empresa = $$v
                  },
                  expression: "empresa",
                },
              }),
              _c("v-spacer"),
              _c(
                "download-csv",
                {
                  attrs: {
                    data: _vm.credoresCsv,
                    labels: _vm.labelsCsv,
                    delimiter: ";",
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2 black--text",
                      attrs: { color: "white", title: "Percentual" },
                      on: {
                        click: function ($event) {
                          _vm.showTable = true
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-file-excel")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-main",
            [
              _c("v-progress-linear", {
                attrs: {
                  active: _vm.loading,
                  indeterminate: _vm.loading,
                  absolute: "",
                  top: "",
                },
              }),
              _vm.assembleia &&
              _vm.assembleia.consolidada == 1 &&
              _vm.assembleia.consolidado_usuario
                ? _c("v-alert", { attrs: { type: "success" } }, [
                    _vm._v(
                      " Consolidado " +
                        _vm._s(
                          _vm._f("moment")(_vm.assembleia.consolidada_data, "L")
                        ) +
                        " " +
                        _vm._s(
                          _vm._f("moment")(
                            _vm.assembleia.consolidada_hora,
                            "LT"
                          )
                        ) +
                        " " +
                        _vm._s(_vm.assembleia.consolidado_usuario.nome) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm.assembleia
                ? _c(
                    "v-card",
                    { attrs: { disabled: _vm.assembleia.consolidada == 1 } },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _vm.assembleia
                            ? _c("assembleia-credores", {
                                attrs: { credores: _vm.credoresSort },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("dialog-config"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }