var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-simple-table", {
    attrs: { dense: "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "text-left" }, [_vm._v("Moeda")]),
                _c("th", { staticClass: "text-right" }, [_vm._v("Valor")]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.valores, function (valor) {
                return _c("tr", { key: valor.moeda }, [
                  _c("td", [_vm._v(_vm._s(valor.moeda))]),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$n(valor.base_de_calculo, "decimal2")) +
                          " "
                      ),
                      _c("br"),
                      _c("conversao-text", {
                        attrs: { conversao: valor.conversao },
                      }),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }