
import { Component, Prop, Vue } from "vue-property-decorator";
import ConversaoText from "@/components/plano-recuperacao/PlanoCredores/ConversaoText.vue";
import ModalPagar from "@/components/plano-recuperacao/ModalPagar.vue";
import ModalEstornar from "@/components/plano-recuperacao/ModalEstornar.vue";
import PlanoStages from "@/components/plano-recuperacao/PlanoCredores/PlanoStages.vue";

@Component({
  components: { PlanoStages, ConversaoText, ModalPagar, ModalEstornar },
})
export default class TabelaParcelas extends Vue {
  @Prop()
  public valores: any;

  @Prop()
  public condicao: any;

  @Prop()
  public acumulador: number = 0;

  public dialogPagar: boolean = false;

  public mostrarCalculos: boolean = false;

  get indiceNome(): string {
    return this.condicao.tp_juros_indice || "Sem indice";
  }

  get indiceModificador(): number {
    return this.condicao.juros_indice || 0;
  }

  get desagio(): number {
    const desagio =  this.valores[0]?.parcelas[0]?.stages?.find(
      (stage) => stage.chave === "desagio"
    );

    if(desagio) {
      return desagio.desconto;
    } 

    return 0;
  }

  public adimplente(valor): boolean {
    return !(
      valor.parcelas.filter((parcela) => !parcela.adimplente).length > 0
    );
  }
}
