
import { Component, Vue } from "vue-property-decorator";
import PContent from "@/components/layout/PContent.vue";
import PlanoCredores from "@/components/plano-recuperacao/PlanoCredores/PlanoCredores.vue";
import PlanoParcela from "@/components/plano-recuperacao/PlanoCredores/PlanoParcela.vue";
import GrupoParcelas from "@/components/plano-recuperacao/PlanoCredores/GrupoParcelas.vue";
import CardCredor from "@/components/plano-recuperacao/PlanoCredores/CardCredor.vue";
import TabelaParcelas from "@/components/plano-recuperacao/PlanoCredores/TabelaParcelas.vue";
import planoRecuperacaoService from "@/services/plano-recuperacao/PlanoRecuperacaoService";

@Component({
  components: {
    PContent,
    PlanoCredores,
    PlanoParcela,
    GrupoParcelas,
    TabelaParcelas,
    CardCredor,
  },
})
export default class CredoresDetalhes extends Vue {
  public planoCredoresCondicoes: any = {};

  public mostrarCalculos: boolean = true;

  public mounted(): void {
    this.fetch();
  }

  public async fetch(): Promise<void> {
    this.planoCredoresCondicoes = await planoRecuperacaoService.getCredor(
      this.$route.params.id
    );
  }
}
