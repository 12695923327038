
import {Component, Vue} from 'vue-property-decorator'
import Modal from '@/components/modal/Modal.vue'
import FormCondicaoPagamento from './FormCondicaoPagamento.vue'
import {DefaultHeaderTable} from '@/common/Type'
import ModalCondicaoCredor from './ModalCondicaoCredor.vue'
import {PlanoRecuperacaoParcelas} from '@/services/parcela/ParcelaTypes'
import FormatterHelper from '@/helper/FormatterHelper'
import {ClassesParcela} from '@/services/classe/ClassesTypes'
import {EmpresaCredorParcelas} from '@/services/credor/CredorTypes'

@Component({
  components: { ModalCondicaoCredor, FormCondicaoPagamento, Modal },
})
export default class ModalParcelas extends Vue {
  public single: boolean = false
  public expanded = []
  public parcelas: PlanoRecuperacaoParcelas = {
    plano_id: 1,
    processo_id: 1,
    themis: '133/1.16.0000229-8',
    empresa: {
      empresa_id: 1,
      nm_empresa: 'LATICÍNIO SEBERI LTDA',
      is_principal_processo: true,
    },
    classes: [
      {
        nm_classe: 'Classe I',
        id_classe: 1,
        empresas: [
          {
            empresa_id: 1,
            nm_empresa: 'EMPRESA TESTE',
            credores: [
              {
                empresa_id: 2,
                nm_credor: 'CREDOR 1',
                valor: 500.00,
                valor_saldo: 400.00,
                parcelas: [
                  {
                    parcela: 1,
                    dt_vencimento: '2021-01',
                    valor: 50.00,
                    saldo: 200.00,
                    situacao: 'Paga',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 2,
                    dt_vencimento: '2021-02',
                    valor: 50.00,
                    saldo: 150.00,
                    situacao: 'Em Aberto',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 3,
                    dt_vencimento: '2021-03',
                    valor: 50.00,
                    saldo: 100.00,
                    situacao: 'Em Aberto',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 4,
                    dt_vencimento: '2021-04',
                    valor: 50.00,
                    saldo: 50.00,
                    situacao: 'Em Aberto',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 5,
                    dt_vencimento: '2021-05',
                    valor: 50.00,
                    saldo: 0.00,
                    situacao: 'Em Aberto',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 1,
                    dt_vencimento: '2021-06',
                    valor: 50.00,
                    saldo: 200.00,
                    situacao: 'Paga',
                    condicao: 'CONDIÇÃO TESTE',
                  },
                  {
                    parcela: 2,
                    dt_vencimento: '2021-07',
                    valor: 50.00,
                    saldo: 150.00,
                    situacao: 'Em Aberto',
                    condicao: 'CONDIÇÃO TESTE',
                  },
                  {
                    parcela: 3,
                    dt_vencimento: '2021-08',
                    valor: 50.00,
                    saldo: 100.00,
                    situacao: 'Em Aberto',
                    condicao: 'CONDIÇÃO TESTE',
                  },
                  {
                    parcela: 4,
                    dt_vencimento: '2021-09',
                    valor: 50.00,
                    saldo: 50.00,
                    situacao: 'Em Aberto',
                    condicao: 'CONDIÇÃO TESTE',
                  },
                  {
                    parcela: 5,
                    dt_vencimento: '2021-10',
                    valor: 50.00,
                    saldo: 0.00,
                    situacao: 'Em Aberto',
                    condicao: 'CONDIÇÃO TESTE',
                  }
                ]
              },
            ]
          },
          {
            empresa_id: 2,
            nm_empresa: 'EMPRESA TESTE 2',
            credores: [
              {
                empresa_id: 2,
                nm_credor: 'CREDOR 1',
                valor: 500.00,
                valor_saldo: 400.00,
                parcelas: [
                  {
                    parcela: 1,
                    dt_vencimento: '2021-01',
                    valor: 50.00,
                    saldo: 200.00,
                    situacao: 'Paga',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 2,
                    dt_vencimento: '2021-02',
                    valor: 50.00,
                    saldo: 150.00,
                    situacao: 'Em Aberto',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 3,
                    dt_vencimento: '2021-03',
                    valor: 50.00,
                    saldo: 100.00,
                    situacao: 'Em Aberto',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 4,
                    dt_vencimento: '2021-04',
                    valor: 50.00,
                    saldo: 50.00,
                    situacao: 'Em Aberto',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 5,
                    dt_vencimento: '2021-05',
                    valor: 50.00,
                    saldo: 0.00,
                    situacao: 'Em Aberto',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 1,
                    dt_vencimento: '2021-06',
                    valor: 50.00,
                    saldo: 200.00,
                    situacao: 'Paga',
                    condicao: 'CONDIÇÃO TESTE',
                  },
                  {
                    parcela: 2,
                    dt_vencimento: '2021-07',
                    valor: 50.00,
                    saldo: 150.00,
                    situacao: 'Em Aberto',
                    condicao: 'CONDIÇÃO TESTE',
                  },
                  {
                    parcela: 3,
                    dt_vencimento: '2021-08',
                    valor: 50.00,
                    saldo: 100.00,
                    situacao: 'Em Aberto',
                    condicao: 'CONDIÇÃO TESTE',
                  },
                  {
                    parcela: 4,
                    dt_vencimento: '2021-09',
                    valor: 50.00,
                    saldo: 50.00,
                    situacao: 'Em Aberto',
                    condicao: 'CONDIÇÃO TESTE',
                  },
                  {
                    parcela: 5,
                    dt_vencimento: '2021-10',
                    valor: 50.00,
                    saldo: 0.00,
                    situacao: 'Em Aberto',
                    condicao: 'CONDIÇÃO TESTE',
                  }
                ]
              },
            ]
          },
        ],
      },
      {
        nm_classe: 'Classe II',
        id_classe: 1,
        empresas: [
          {
            empresa_id: 1,
            nm_empresa: 'EMPRESA TESTE',
            credores: [
              {
                empresa_id: 2,
                nm_credor: 'CREDOR 1',
                valor: 500.00,
                valor_saldo: 400.00,
                parcelas: [
                  {
                    parcela: 1,
                    dt_vencimento: '2021-01',
                    valor: 50.00,
                    saldo: 200.00,
                    situacao: 'Paga',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 2,
                    dt_vencimento: '2021-02',
                    valor: 50.00,
                    saldo: 150.00,
                    situacao: 'Em Aberto',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 3,
                    dt_vencimento: '2021-03',
                    valor: 50.00,
                    saldo: 100.00,
                    situacao: 'Em Aberto',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 4,
                    dt_vencimento: '2021-04',
                    valor: 50.00,
                    saldo: 50.00,
                    situacao: 'Em Aberto',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 5,
                    dt_vencimento: '2021-05',
                    valor: 50.00,
                    saldo: 0.00,
                    situacao: 'Em Aberto',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 1,
                    dt_vencimento: '2021-06',
                    valor: 50.00,
                    saldo: 200.00,
                    situacao: 'Paga',
                    condicao: 'CONDIÇÃO TESTE',
                  },
                  {
                    parcela: 2,
                    dt_vencimento: '2021-07',
                    valor: 50.00,
                    saldo: 150.00,
                    situacao: 'Em Aberto',
                    condicao: 'CONDIÇÃO TESTE',
                  },
                  {
                    parcela: 3,
                    dt_vencimento: '2021-08',
                    valor: 50.00,
                    saldo: 100.00,
                    situacao: 'Em Aberto',
                    condicao: 'CONDIÇÃO TESTE',
                  },
                  {
                    parcela: 4,
                    dt_vencimento: '2021-09',
                    valor: 50.00,
                    saldo: 50.00,
                    situacao: 'Em Aberto',
                    condicao: 'CONDIÇÃO TESTE',
                  },
                  {
                    parcela: 5,
                    dt_vencimento: '2021-10',
                    valor: 50.00,
                    saldo: 0.00,
                    situacao: 'Em Aberto',
                    condicao: 'CONDIÇÃO TESTE',
                  }
                ]
              },
            ]
          },
          {
            empresa_id: 2,
            nm_empresa: 'EMPRESA TESTE 2',
            credores: [
              {
                empresa_id: 2,
                nm_credor: 'CREDOR 1',
                valor: 500.00,
                valor_saldo: 400.00,
                parcelas: [
                  {
                    parcela: 1,
                    dt_vencimento: '2021-01',
                    valor: 50.00,
                    saldo: 200.00,
                    situacao: 'Paga',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 2,
                    dt_vencimento: '2021-02',
                    valor: 50.00,
                    saldo: 150.00,
                    situacao: 'Em Aberto',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 3,
                    dt_vencimento: '2021-03',
                    valor: 50.00,
                    saldo: 100.00,
                    situacao: 'Em Aberto',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 4,
                    dt_vencimento: '2021-04',
                    valor: 50.00,
                    saldo: 50.00,
                    situacao: 'Em Aberto',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 5,
                    dt_vencimento: '2021-05',
                    valor: 50.00,
                    saldo: 0.00,
                    situacao: 'Em Aberto',
                    condicao: 'Condição A',
                  },
                  {
                    parcela: 1,
                    dt_vencimento: '2021-06',
                    valor: 50.00,
                    saldo: 200.00,
                    situacao: 'Paga',
                    condicao: 'CONDIÇÃO TESTE',
                  },
                  {
                    parcela: 2,
                    dt_vencimento: '2021-07',
                    valor: 50.00,
                    saldo: 150.00,
                    situacao: 'Em Aberto',
                    condicao: 'CONDIÇÃO TESTE',
                  },
                  {
                    parcela: 3,
                    dt_vencimento: '2021-08',
                    valor: 50.00,
                    saldo: 100.00,
                    situacao: 'Em Aberto',
                    condicao: 'CONDIÇÃO TESTE',
                  },
                  {
                    parcela: 4,
                    dt_vencimento: '2021-09',
                    valor: 50.00,
                    saldo: 50.00,
                    situacao: 'Em Aberto',
                    condicao: 'CONDIÇÃO TESTE',
                  },
                  {
                    parcela: 5,
                    dt_vencimento: '2021-10',
                    valor: 50.00,
                    saldo: 0.00,
                    situacao: 'Em Aberto',
                    condicao: 'CONDIÇÃO TESTE',
                  }
                ]
              },
            ]
          },
        ],
      },
    ],
  }

  public show: boolean = false

  public headersCredores: DefaultHeaderTable[] = [
    { value: 'nm_credor', text: 'Nome do Credor', align: 'start' },
    { value: 'valor', text: 'Valor a Receber', align: 'start' },
    { value: 'valor_saldo', text: 'Saldo Atualizado', align: 'start' },
    { value: 'data-table-expand', text: '', sortable: false, align: 'center' },
  ]

  public headersParcelas: DefaultHeaderTable[] = [
    { value: 'parcela', text: 'Número da Parcela', align: 'center' },
    { value: 'dt_vencimento', text: 'Data de Vencimento', align: 'center' },
    { value: 'valor', text: 'Valor', align: 'center' },
    { value: 'saldo', text: 'Valor de Saldo', align: 'center' },
    { value: 'situacao', text: 'Situação', align: 'center' },
    { value: 'condicao', text: 'Condição', align: 'center' },
    { value: 'acoes', text: 'Ações', sortable: false, align: 'center' },
  ]

  public getEmpresaPrincipal(): string {
    return (this.parcelas.empresa.is_principal_processo) ? 'Sim' : 'Não'
  }

  public getValorTotalClasse(classe: ClassesParcela): string {
    let valor = 0.00
    for (const i of classe.empresas) {
      for (const j of i.credores) {
        valor += j.valor
      }
    }

    return FormatterHelper.valorDecimalToBr(valor)
  }

  public getValorTotalEmpresa(empresa: EmpresaCredorParcelas): string {
    let valor = 0.00
    for (const i of empresa.credores) {
      valor += i.valor
    }
    return FormatterHelper.valorDecimalToBr(valor)
  }

}
