
import { Component, Prop, Vue } from 'vue-property-decorator'
import { commonModule } from '@/store/common'
import ModalCondicaoPagamento from './ModalCondicaoPagamento.vue'
import { Processo } from '@/services/processo/ProcessoTypes'
import ModalDistribuicaoValores from './ModalDistribuicaoValores.vue'
import FormatterHelper from '@/helper/FormatterHelper'

@Component({
  components: { ModalDistribuicaoValores, ModalCondicaoPagamento }
})
export default class Condicoes extends Vue {

  @Prop()
  public loading!: boolean

  @Prop()
  public processo!: Processo

  @Prop()
  public condicoes!: any[]

  @Prop()
  public classes!: any[]

  public deleteCondicao(index: number): void {
    this.condicoes.splice(index, 1)
    commonModule.showMessage({message: 'Condição de Pagamento removida com sucesso !', type: 'info'})
  }

  public getClasses(classes: any[]): string {
    return classes.map(condicao => condicao.label).join(', ')
  }

  public getModoPagamento(condicao: any): string {
    const operador = condicao.operador
    const valor = (operador === 'Entre')
      ? (FormatterHelper.currencyBRL(parseFloat(condicao.vl_limite_entre_inicio)) + ' - ' + FormatterHelper.currencyBRL(parseFloat(condicao.vl_limite_entre_fim)))
      : (operador === 'Acima de' ? FormatterHelper.currencyBRL(parseFloat(condicao.vl_limite_acima)) : FormatterHelper.currencyBRL(parseFloat(condicao.vl_limite_ate)))

    return `${'Valor ' + operador + ' ' + valor }`
  }

}
