<template>
  <v-row>
    <v-col lg="6" v-if="titulo != 'Total'">
      <div class="small">
        <bar-chart
          v-if="mostraDatacollectionCredores"
          :chart-data="datacollectionCredores"
          :options="optionsCredores"
        ></bar-chart>
      </div>
    </v-col>
    <v-col lg="6" v-if="mostraCredito">
      <div class="small">
        <bar-chart
          v-if="datacollectionCreditos"
          :chart-data="datacollectionCreditos"
          :options="optionsCreditos"
        ></bar-chart>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import BarChart from "./BarChart";
import { AssembleiaResumo } from "../../services/assembleia/AssembleiaTypes";

export default {
  components: {
    BarChart,
  },
  props: {
    titulo: {
      type: String,
      default: "",
    },
    resultado: {
      type: AssembleiaResumo,
      default: 0,
      require: true,
    },
    mostraDashboard: {
      type: String,
      default: "plano",
    },
  },
  data() {
    return {};
  },
  computed: {
    optionsCreditos() {
      return {
        legend: { display: false },
        title: {
          display: true,
          text: `${this.titulo} - Créditos`,
        },
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: 100,
                maxTicksLimit: 5,
                callback: function (value) {
                  return value + "%";
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: "white",
            font: {
              weight: "bold",
            },
            formatter: function (value) {
              return value + "%";
            },
          },
        },
      };
    },

    optionsCredores() {
      return {
        legend: { display: false },
        title: {
          display: true,
          text: `${this.titulo} - Cabeças`,
        },
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: 100,
                maxTicksLimit: 5,
                callback: function (value) {
                  return value + "%";
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: "white",
            font: {
              weight: "bold",
            },
            formatter: function (value) {
              return value + "%";
            },
          },
        },
      };
    },
    mostraDatacollectionCredores() {
      if (this.mostraDashboard == 'comite') {
        return false;        
      }      
      return this.datacollectionCredores;
    },
    mostraCredito() {
      if (this.mostraDashboard == 'comite') {
        return ["Classe 1", "Classe 4", "Classe 2", "Classe 3", "Total"].includes(this.titulo);        
      }      
      return ["Classe 2", "Classe 3", "Total"].includes(this.titulo);
    },
    datacollectionCreditos() {
      return {
        labels: [
          ["Aprova", this.$n(this.resultado.aprova.creditos, "currency")],
          [
            "Não aprova",
            this.$n(this.resultado.naoAprova.creditos, "currency"),
          ],
          [
            "Aguardando",
            this.$n(this.resultado.indefinido.creditos, "currency"),
          ],
        ],
        datasets: [
          {
            title: "Créditos",
            backgroundColor: ["#9E7830", "#676A6C", "#333333"],
            data: [
              this.percentual(this.resultado.aprova.creditos_perc),
              this.percentual(this.resultado.naoAprova.creditos_perc),
              this.percentual(this.resultado.indefinido.creditos_perc),
            ],
          },
        ],
      };
    },
    datacollectionCredores() {
      return {
        title: "Credores",
        labels: [
          ["Aprova", this.resultado.aprova.credores],
          ["Não aprova", this.resultado.naoAprova.credores],
          ["Aguardando", this.resultado.indefinido.credores],
        ],
        datasets: [
          {
            backgroundColor: ["#9E7830", "#676A6C", "#333333"],
            data: [
              this.percentual(this.resultado.aprova.credores_perc),
              this.percentual(this.resultado.naoAprova.credores_perc),
              this.percentual(this.resultado.indefinido.credores_perc),
            ],
          },
        ],
      };
    },
  },
  methods: {
    percentual(value) {
      return Math.round(value * 100 * 100) / 100;
    },
  },
};
</script>

<style>
.small {
  max-height: 300px;
  max-width: 300px;
}
</style>



