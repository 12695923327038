var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "menu" },
    [
      _c(
        "v-list",
        { staticClass: "bg-plano-black", attrs: { dense: "" } },
        [
          _c(
            "v-list-item",
            { attrs: { link: "", to: { name: "home" } } },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "white--text" },
                [_c("v-icon", [_vm._v("mdi-home")])],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("Página Inicial")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            { attrs: { link: "", to: { name: "plano-gestao" } } },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "white--text" },
                [_c("v-icon", [_vm._v("mdi-currency-usd")])],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("Gestão de Planos")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            { attrs: { link: "", to: { name: "periodicidade" } } },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "white--text" },
                [_c("v-icon", [_vm._v("mdi-calendar")])],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("Periodicidades")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            { attrs: { link: "", to: { name: "assembleias" } } },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "white--text" },
                [_c("v-icon", [_vm._v("mdi-calendar")])],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("Assembleia")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }