
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import { DefaultSelect } from '@/common/Type'
import {ClasseCredor, Processo, ProcessoClasseCredorClasse} from '@/services/processo/ProcessoTypes'
import {PlanoRecuperacaoEmpresasProcessoSelect} from "@/services/plano-recuperacao/PlanoRecuperacaoTypes";

@Component
export default class SelectClasses extends Vue {

  @Prop()
  public processo!: Processo

  @Prop()
  public rules?: []

  @Prop()
  public hideDetails?: any

  @Prop()
  public multiple?: any

  @Prop()
  public value: any

  public selecionado: any = this.value

  public selectOptions = {
    multiple: false,
    hideDetails: true,
    rules: [],
  }

  public items: DefaultSelect[] = []
  public classes: ProcessoClasseCredorClasse[] = [];
  public loading: boolean = false
  public disabled: boolean = false

  public mounted(): void {
    this.agruparClasses()
    this.checarPropsOpcionais()
  }

  public agruparClasses(): void {
    const filter = (classe: string) => (classes: any) => classes.nome === classe
    for (const c of this.processo.credores_assembleia) {
      const classeObj = c.classe_estrategica ? c.classe_estrategica : c.tipo.classe_assembleia;
      const existClasse = this.classes.filter(filter(classeObj.nome))

      if (existClasse.length === 0) {
        this.classes.push(Object.assign(classeObj, { id: c.id}));
      }
    }
    this.classes.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
    this.items = this.classes.map((c) : DefaultSelect => ({ label: c.nome, value: c.id}));
  }

  public checarPropsOpcionais(): void {
    if (typeof this.multiple !== 'undefined') {
      this.selectOptions.multiple = true
    }

    if (typeof this.rules !== 'undefined') {
      this.selectOptions.rules = this.rules
    }

    if (typeof this.hideDetails !== 'undefined') {
      this.selectOptions.hideDetails = false
    }
  }

  @Watch('value')
  public onValueChanged(val: any): void {
    this.selecionado = val
  }

  @Watch('selecionado')
  public onSelecionadoChanged(val: any): void {
    this.$emit('input', val)
    this.checarErrosValidacao(val)
  }

  public checarErrosValidacao(val: any): void {
    if (this.rules && this.rules.length > 0) {
      this.$emit('errors', val)
    }
  }
}
