
import { Component, Vue } from "vue-property-decorator";
import { commonModule } from "@/store/common";
import { AuthDataUser } from "@/services/user/UserTypes";
import router from "@/router";

@Component
export default class Login extends Vue {
  public mounted(): void {
    commonModule.setAuthDataUser(this.autenticate);
    router.push(this.route);
  }

  get autenticate(): AuthDataUser {
    const authData: string = String(router.currentRoute.query.authData);
    return JSON.parse(decodeURI(authData));
  }

  get route(): any {
    const rota: string = String(router.currentRoute.query.route);
    return JSON.parse(decodeURI(rota));
  }
}
