
import { Component, Vue } from 'vue-property-decorator'
import condicaoService from '@/services/condicao/CondicaoService'

@Component
export default class Home extends Vue {
  public loading: boolean = true
  public qtdPlanosAtivos: number = 0

  public mounted(): void {
    this.totalPlanosAtivos()
  }

  public async totalPlanosAtivos(): Promise<void> {
    this.qtdPlanosAtivos = (await condicaoService.getCondicoesAtivas()).length
    this.loading = false
  }
}
