<!-- @format -->



<template>
  <v-container class="assembleia-grafico border rounded-sm">
    <v-row>
      <v-col>
        <assembleia-classe
          v-if="showTable"
          :resultado="resultado"
          :titulo="titulo"
        ></assembleia-classe>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <grafico-votacao
          :titulo="titulo"
          :resultado="resultado"
          :mostraDashboard="mostraDashboard"
        ></grafico-votacao>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import AssembleiaClasse from "./AssembleiaClasse.vue";
import GraficoVotacao from "../charts/GraficoVotacao.vue";

export default {
  components: { AssembleiaClasse, GraficoVotacao },
  props: { resultado: Object, titulo: String, showTable: Boolean, mostraDashboard: String },
};
</script>
<style scoped>
.assembleia-grafico {
  min-width: 40vw;
}
</style>