var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "container",
    { staticClass: "assembleia-grafico text-center" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.titulo))]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("vue-speedometer", {
                attrs: {
                  value: _vm.dados.presentes,
                  segments: 400,
                  maxSegmentLabels: 1,
                  maxValue: _vm.total,
                  currentValueText: _vm.formatValue(_vm.dados.presentes),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }