
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class PlanoCredores extends Vue {
  @Prop()
  public conversao: any;

  get title(): string {
    return (
      this.conversao.cotacao.valor + " (" + this.conversao.cotacao.data + ")"
    );
  }
}
