var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-autocomplete", {
    attrs: {
      items: _vm.condicoes,
      "hide-details": _vm.selectOptions.hideDetails,
      multiple: _vm.selectOptions.multiple,
      rules: _vm.selectOptions.rules,
      loading: _vm.loading,
      disabled: _vm.disabled,
      label: _vm.label,
      outlined: "",
      dense: "",
      "item-value": "value",
      "item-text": "label",
    },
    model: {
      value: _vm.selecionado,
      callback: function ($$v) {
        _vm.selecionado = $$v
      },
      expression: "selecionado",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }