/** @format */

import { ClasseCredor } from "@/services/processo/ProcessoTypes";
import {
  CondicaoPagamento,
  CredorPlanos,
} from "@/services/condicao/CondicaoTypes";
import { v4 as uuidv4 } from "uuid";
import FormatterHelper from "@/helper/FormatterHelper";

interface CondicaoOperacao {
  isCondicao: (condicao: any) => boolean;
  regraValor: (credor: any, condicao: any, planos: any[]) => number;
}

export default class DistribuicaoCondicaoValorService {
  constructor(
    private classes: ClasseCredor[],
    private condicoes: CondicaoPagamento[]
  ) {
    this.condicoes.map(condicao => (condicao.uuid = uuidv4())).sort((a, b) => (
      b.valor_unico-a.valor_unico
    ));
  }

  public montarDistribuicaoValores(): CredorPlanos[] {
    const classesCondicao = this.getClasses();
    
    const credores = this.getCredoresByClasses(classesCondicao);
    for (const credor of credores) {
      credor.planos = this.distribuirPlanos(credor).filter(
        (p: any) => p.valor > 0
      );
    }

    return credores;
  }

  private getClasses(): string[] {
    const classesCondicao: string[] = [];
    for (const condicao of this.condicoes) {
      const classes = condicao.classes.map((c: any) => c.label);
      for (const classe of classes) {
        classesCondicao.push(classe);
      }
    }
    return classesCondicao;
  }

  private getCredoresByClasses(classesCondicao: string[]): any[] {
    const listaCredores: any[] = [];
    console.log(this.classes)
    const credores: any[] = this.classes.filter(
      (c: any) => classesCondicao.indexOf(c.nome) >= 0
    );
    for (const credor of credores) {
      for (const c of credor.credores) {
        c.planos = [];
        listaCredores.push(c);
      }
    }

    return listaCredores;
  }

  private distribuirPlanos(credor: any): any {
    const planosCredor: any[] = [];
    credor.carteira.total = parseFloat(credor.carteira.total);

    const condicoesOperacoes = this.getCondicoesOperacoes();

    for (const condicao of this.condicoes) {
      for (const co of condicoesOperacoes) {
        if (co.isCondicao(condicao) && this.classeCredorIsClassesCondicao(credor, condicao)) {

          const valor = (condicao.valor_unico ? co.regraValor(credor, condicao, []) : co.regraValor(credor, condicao, planosCredor));

          if(valor <= 0)
            continue;

          planosCredor.push({
            condicao: condicao.uuid,
            valor: valor,
            valor_unico: condicao.valor_unico || false
          })
        }
      }
    }

    const unicos = planosCredor.filter((plano) => (plano.valor_unico))

    if(unicos.length > 0)
      return [unicos.pop()];

    return planosCredor;
  }

  private classeCredorIsClassesCondicao(
    credor: any,
    condicao: CondicaoPagamento
  ): boolean {
    const classes = condicao.classes.map(c => c.label);
    const classeObj = credor.classe_estrategica ? credor.classe_estrategica : credor.tipo.classe_assembleia;
    const classeCredor = classeObj.nome;
    return classes.indexOf(classeCredor) >= 0;
  }

  private getCondicoesOperacoes(): CondicaoOperacao[] {
    return [
      {
        isCondicao: this.isCondicaoAte,
        regraValor: this.getValorReceberPlanoCondicaoAte
      },
      {
        isCondicao: this.isCondicaoEntre,
        regraValor: this.getValorReceberPlanoCondicaoEntre
      },
      {
        isCondicao: this.isCondicaoAcima,
        regraValor: this.getValorReceberPlanoCondicaoAcima
      }
    ];
  }

  private getValorReceberPlanoCondicaoAte(
    credor: any,
    condicao: any
  ): number {
    return credor.carteira.total > condicao.vl_limite_ate
      ? (condicao.valor_unico ? 0 : condicao.vl_limite_ate)
      : credor.carteira.total;
  }

  private getValorReceberPlanoCondicaoEntre(
    credor: any,
    condicao: any
  ): number {
    if (credor.carteira.total > condicao.vl_limite_entre_fim && !condicao.valor_unico) {
      return condicao.vl_limite_entre_fim;
    }

    return (credor.carteira.total > condicao.vl_limite_entre_inicio && credor.carteira.total < condicao.vl_limite_entre_fim)
      ? credor.carteira.total - (condicao.valor_unico ? 0 : condicao.vl_limite_entre_inicio)
      : 0;
  }

  private getValorReceberPlanoCondicaoAcima(
    credor: any,
    condicao: any,
    planosCredor: any[]
  ): number {
    const valorDistribuidoCredor = DistribuicaoCondicaoValorService.getTotalDistribuidoCredorPlanos(
      planosCredor
    );

    return credor.carteira.total >= condicao.vl_limite_acima
      ? credor.carteira.total - (condicao.valor_unico ? 0 : valorDistribuidoCredor)
      : 0;
  }

  private static getTotalDistribuidoCredorPlanos(
    planosCredor: any[]
  ): number {
    if (planosCredor.length > 0) {
      return planosCredor
        .map((p: any) => p.valor)
        .reduce((accum: any, value: any) => accum + value);
    }

    return 0;
  }

  private isCondicaoAte(condicao: any): boolean {
    return condicao.operador === "Até";
  }

  private isCondicaoEntre(condicao: any): boolean {
    return condicao.operador === "Entre";
  }

  private isCondicaoAcima(condicao: any): boolean {
    return condicao.operador === "Acima de";
  }
}
