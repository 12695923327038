var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "inspire", loading: _vm.loading } },
    [
      _c(
        "v-app-bar",
        { attrs: { app: "", dark: "", "clipped-left": "" } },
        [
          _c(
            "v-toolbar-title",
            { staticClass: "ml-0 pl-4", staticStyle: { width: "300px" } },
            [
              _vm.assembleia
                ? _c("span", { staticClass: "hidden-sm-and-down" }, [
                    _vm._v(
                      "#" +
                        _vm._s(_vm.assembleia.id) +
                        " " +
                        _vm._s(_vm.assembleia.titulo) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _c("v-spacer"),
          _c(
            "v-btn-toggle",
            { staticClass: "pr-6", attrs: { mandatory: "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { title: "Mostrar classes" },
                  on: {
                    click: function ($event) {
                      _vm.showTable = !_vm.showTable
                    },
                  },
                },
                [
                  _vm.showTable
                    ? _c("v-icon", [_vm._v("mdi-grid-large")])
                    : _c("v-icon", [_vm._v("mdi-grid-off")]),
                  _vm._v(" Resumo "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-btn-toggle",
            {
              attrs: { mandatory: "" },
              model: {
                value: _vm.mostraDashboard,
                callback: function ($$v) {
                  _vm.mostraDashboard = $$v
                },
                expression: "mostraDashboard",
              },
            },
            [
              _c("v-btn", { attrs: { value: "presenca" } }, [
                _vm._v(" Presença "),
              ]),
              _c("v-btn", { attrs: { value: "plano" } }, [_vm._v(" Plano ")]),
              _c("v-btn", { attrs: { value: "suspensao" } }, [
                _vm._v(" Suspensão "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c("v-progress-linear", {
            attrs: {
              active: _vm.loading,
              indeterminate: _vm.loading,
              absolute: "",
              top: "",
            },
          }),
          _vm.assembleia && _vm.assembleia.abrir == 1 && _vm.resumo
            ? _c(
                "div",
                [
                  _vm.mostraDashboard === "presenca"
                    ? _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c("h2", { staticClass: "text-center" }),
                          _c("h3", [_vm._v("Lista de Presenca")]),
                          _c(
                            "v-row",
                            _vm._l(
                              _vm.resumoMostrar,
                              function (resultado, classe) {
                                return _c(
                                  "v-col",
                                  {
                                    key: classe,
                                    attrs: { lg: "3", md: "3", sm: "12" },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _vm.showTable
                                          ? _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _vm.showTable
                                                      ? _c(
                                                          "assembleia-classe",
                                                          {
                                                            attrs: {
                                                              resultado:
                                                                resultado,
                                                              titulo:
                                                                _vm.classeTitle(
                                                                  classe
                                                                ),
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              [
                                                classe !== "Total"
                                                  ? _c(
                                                      "assembleia-graficos-presenca",
                                                      {
                                                        attrs: {
                                                          titulo:
                                                            _vm.classeTitle(
                                                              classe
                                                            ),
                                                          resultado: resultado,
                                                          graficoPercentual:
                                                            _vm.graficoPercentual,
                                                        },
                                                      }
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("assembleia-credores-recuperanda", {
                                    attrs: { credores: _vm.credores },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-container",
                        { ref: "printMe", attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            _vm._l(
                              _vm.resumoMostrar,
                              function (resultado, classe) {
                                return _c(
                                  "v-col",
                                  { key: classe },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _vm.mostraDashboard === "plano"
                                          ? _c("v-card-title", [
                                              _vm._v("Votação plano"),
                                            ])
                                          : _vm._e(),
                                        _vm.mostraDashboard === "suspensao"
                                          ? _c("v-card-title", [
                                              _vm._v("Votação suspensão"),
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "v-card-text",
                                          [
                                            _c("assembleia-graficos-classe", {
                                              attrs: {
                                                titulo:
                                                  classe === "Total"
                                                    ? classe
                                                    : "Classe " + classe,
                                                resultado: resultado,
                                                showTable: _vm.showTable,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                  _c("assembleia-lista-presenca", {
                    attrs: { credores: _vm.credores },
                  }),
                ],
                1
              )
            : _c("div", [_vm._v("Assembleia fechada")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }