var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.snackbarMessage()
    ? _c(
        "v-snackbar",
        {
          attrs: {
            timeout: _vm.snackbarMessage().timeout,
            color: _vm.snackbarMessage().type,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "action",
                fn: function ({}) {
                  return [
                    _c(
                      "v-btn",
                      {
                        staticStyle: { float: "right" },
                        attrs: { dark: "", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.fecharMensagem()
                          },
                        },
                      },
                      [_vm._v("Fechar")]
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            3216508939
          ),
          model: {
            value: _vm.snackbarMessage().show,
            callback: function ($$v) {
              _vm.$set(_vm.snackbarMessage(), "show", $$v)
            },
            expression: "snackbarMessage().show",
          },
        },
        [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.snackbarMessage().message) },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }