/** @format */
import _ from "lodash/lodash";

import {
  AssembleiaCredor,
  AssembleiaResumo,
} from "@/services/assembleia/AssembleiaTypes";

const VOTO_APROVA = "S";
const VOTO_NAO_APROVA = "N";
const VOTO_ABSTEM = "A";
const VOTO_INDEFINIDO = "I";
const PRESENCA_SIM = true;
const PRESENCA_NAO = false;

//votos
const contaVotos = (credores: AssembleiaCredor[], opcao: string): number =>
  credores.reduce(
    (acc: number, credor: AssembleiaCredor) =>
      credor.voto === opcao && credor.presente ? ++acc : acc,
    0
  );

const somaVotos = (credores: AssembleiaCredor[], opcao: string): number =>
  credores.reduce(
    (acc, credor) =>
      credor.voto === opcao && credor.presente ? acc + credor.total : acc,
    0
  );

//votos
const contaPresenca = (
  credores: AssembleiaCredor[],
  presente: boolean
): number =>
  credores.reduce(
    (acc: number, credor: AssembleiaCredor) =>
      credor.presente === presente ? ++acc : acc,
    0
  );

const somaPresenca = (
  credores: AssembleiaCredor[],
  presente: boolean
): number =>
  credores.reduce(
    (acc: number, credor: AssembleiaCredor) =>
      credor.presente === presente ? acc + credor.total : acc,
    0
  );

const calculaResultado = function(
  credores: AssembleiaCredor[]
): AssembleiaResumo {
  const resumo: AssembleiaResumo = {
    aprova: {
      credores: contaVotos(credores, VOTO_APROVA),
      creditos: somaVotos(credores, VOTO_APROVA),
      credores_perc: 0,
      creditos_perc: 0,
    },
    naoAprova: {
      credores: contaVotos(credores, VOTO_NAO_APROVA),
      creditos: somaVotos(credores, VOTO_NAO_APROVA),
      credores_perc: 0,
      creditos_perc: 0,
    },
    abstem: {
      credores: contaVotos(credores, VOTO_ABSTEM),
      creditos: somaVotos(credores, VOTO_ABSTEM),
      credores_perc: 0,
      creditos_perc: 0,
    },
    indefinido: {
      credores: contaVotos(credores, VOTO_INDEFINIDO),
      creditos: somaVotos(credores, VOTO_INDEFINIDO),
      credores_perc: 0,
      creditos_perc: 0,
    },
    presente: {
      credores: contaPresenca(credores, PRESENCA_SIM),
      creditos: somaPresenca(credores, PRESENCA_SIM),
      credores_perc: 0,
      creditos_perc: 0,
    },
    ausente: {
      credores: contaPresenca(credores, PRESENCA_NAO),
      creditos: somaPresenca(credores, PRESENCA_NAO),
      credores_perc: 0,
      creditos_perc: 0,
    },
    total: {
      credores: credores.length,
      creditos: credores.reduce((acc, credor) => acc + credor.total, 0),
      credores_perc: 0,
      creditos_perc: 0,
    },
  };

  return calculaPercentual(resumo);
};

const getPercental = (parte, todo): number => (todo ? parte / todo : 0);

const calculaPercentual = function(resumo: AssembleiaResumo): AssembleiaResumo {
  const {
    aprova,
    naoAprova,
    abstem,
    ausente,
    presente,
    indefinido,
    total,
  } = resumo;
  const qtdVotantes = aprova.credores + naoAprova.credores;
  const vlVotantes = aprova.creditos + naoAprova.creditos;
  //aprova
  aprova.credores_perc = getPercental(aprova.credores, qtdVotantes);
  aprova.creditos_perc = getPercental(aprova.creditos, vlVotantes);

  //nao aprova
  naoAprova.credores_perc = getPercental(naoAprova.credores, qtdVotantes);
  naoAprova.creditos_perc = getPercental(naoAprova.creditos, vlVotantes);

  //abstem
  abstem.credores_perc = getPercental(abstem.credores, total.credores);
  abstem.creditos_perc = getPercental(abstem.creditos, total.creditos);

  //nao presente
  ausente.credores_perc = getPercental(ausente.credores, total.credores);
  ausente.creditos_perc = getPercental(ausente.creditos, total.creditos);
  //presente
  presente.credores_perc = getPercental(presente.credores, total.credores);
  presente.creditos_perc = getPercental(presente.creditos, total.creditos);


  //presente
  indefinido.credores_perc = getPercental(indefinido.credores, presente.credores);
  indefinido.creditos_perc = getPercental(indefinido.creditos, presente.creditos);

  return resumo;
};

const resumo = function(credores: AssembleiaCredor[]): AssembleiaResumo[] {
  const grouped = _(credores)
    .groupBy("classe")
    .value();

  grouped["Total"] = credores;

  for (const classe in grouped) {
    grouped[classe] = calculaResultado(grouped[classe]);
  }
  return grouped;
};

const VotoService = {
  contaVotos,
  somaVotos,
  contaPresenca,
  somaPresenca,
  calculaResultado,
  resumo,
};

export default VotoService;
