
import { Component, Prop, Watch, Vue} from 'vue-property-decorator'
import { DefaultSelect } from '@/common/Type'

@Component
export default class SelectVencimento extends Vue {

  @Prop()
  public rules?: []

  @Prop()
  public hideDetails?: any

  @Prop()
  public multiple?: any

  @Prop()
  public value: any

  public selecionado: any = this.value

  public selectOptions = {
    multiple: false,
    hideDetails: true,
    rules: [],
  }

  public status: DefaultSelect[] = [
    { label: 'Homologação', value: 'homologacao' },
    { label: 'Último dia do mes', value: 'ultimo' },
    ...[...Array(31).keys()]
      .map(i =>  i.toString().padStart(2, '0'))
      .map(dia => ({ label: dia, value: dia }))
  ]

  public mounted(): void {
    this.checarPropsOpcionais()
  }

  @Watch('value')
  public onValueChanged(val: any): void {
    this.selecionado = val
  }

  @Watch('selecionado')
  public onSelecionadoChanged(val: any): void {
    this.$emit('input', val)
    this.checarErrosValidacao(val)
  }

  public checarErrosValidacao(val: any): void {
    if (this.rules && this.rules.length > 0) {
      this.$emit('errors', val)
    }
  }

  public checarPropsOpcionais(): void {
    if (typeof this.multiple !== 'undefined') {
      this.selectOptions.multiple = true
    }

    if (typeof this.rules !== 'undefined') {
      this.selectOptions.rules = this.rules
    }

    if (typeof this.hideDetails !== 'undefined') {
      this.selectOptions.hideDetails = false
    }
  }
}
