var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app-bar",
    { attrs: { app: "" } },
    [
      _c("v-app-bar-nav-icon", {
        on: {
          click: function ($event) {
            _vm.drawerAction = !_vm.drawerAction
          },
        },
      }),
      _c("v-toolbar-title", [_vm._v("Plano de Recuperação")]),
      _c("v-spacer"),
      _c("span", [_vm._v("admin@admin.com")]),
      _c(
        "v-btn",
        { attrs: { icon: "", link: "" }, on: { click: _vm.logout } },
        [_c("v-icon", { attrs: { size: 21 } }, [_vm._v("mdi-logout-variant")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }