var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.valores
    ? _c(
        "v-row",
        { staticClass: "d-flex flex-row m-2" },
        _vm._l(_vm.valores, function (valor) {
          return _c(
            "v-col",
            { key: valor.id, staticClass: "p-6" },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _vm._v(" " + _vm._s(valor.moeda) + " "),
                            _vm.adimplente(valor)
                              ? _c("span", [_vm._v("Adimplente")])
                              : _c("span", [_vm._v("Inadimplente")]),
                          ]),
                          _vm.desagio
                            ? _c(
                                "v-col",
                                { staticClass: "text-right caption" },
                                [
                                  _vm._v(
                                    " Deságio : " +
                                      _vm._s(_vm.$n(_vm.desagio, "currency")) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-simple-table", {
                    attrs: { dense: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c("thead", [
                                _c("tr", { staticClass: "mx-1" }, [
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v("Dias"),
                                  ]),
                                  _c("th", { staticClass: "text-left" }, [
                                    _vm._v("mês/AAAA"),
                                  ]),
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v("Saldo Acumulado"),
                                  ]),
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v("Correção"),
                                  ]),
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v("Juros"),
                                  ]),
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v("Saldo Mensal"),
                                  ]),
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v(_vm._s(_vm.indiceNome)),
                                  ]),
                                  valor.conversao
                                    ? _c("th", { staticClass: "text-right" }, [
                                        _vm._v("Conversão"),
                                      ])
                                    : _vm._e(),
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v("Parcela"),
                                  ]),
                                  _c("th", { staticClass: "text-right" }),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(valor.parcelas, function (row) {
                                  return _c(
                                    "tr",
                                    { key: row.id, staticClass: "mx-1" },
                                    [
                                      _c(
                                        "td",
                                        { staticClass: "text-right" },
                                        [
                                          row.stages.find(
                                            (stage) => stage.dias > 0
                                          )
                                            ? [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.stages.find(
                                                        (stage) =>
                                                          stage.dias > 0
                                                      ).dias || 0
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      _c("td", { staticClass: "text-left" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("moment")(row.date, "L")
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$n(
                                                row.saldo_anterior,
                                                "decimal2"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$n(row.correcao, "decimal2")
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$n(row.juros, "decimal2")
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(_vm.$n(row.saldo, "decimal2"))
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "text-right" },
                                        [
                                          row.stages.find(
                                            (stage) => stage.fator >= 0
                                          )
                                            ? [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$n(
                                                        row.stages.find(
                                                          (stage) =>
                                                            stage.fator >= 0
                                                        ).indice / 100 || 0,
                                                        "percent"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-right" },
                                        [
                                          row.stages.find(
                                            (stage) => stage.fator >= 0
                                          )
                                            ? [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$n(
                                                        row.stages.find(
                                                          (stage) =>
                                                            stage.fator >= 0
                                                        ).indice / 100 || 0,
                                                        "percent"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      row.plano_valor.conversao
                                        ? _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("conversao-text", {
                                                attrs: {
                                                  conversao: row.conversao,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "td",
                                        { staticClass: "text-right" },
                                        [
                                          row.pagamento
                                            ? [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$n(
                                                        row.pagamento.valor,
                                                        "decimal2"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            : row.pagamento_valor
                                            ? [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$n(
                                                        row.pagamento_valor,
                                                        "decimal2"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "td",
                                        [
                                          row.pagamento
                                            ? _c("modal-estornar", {
                                                attrs: {
                                                  dialog: _vm.dialogPagar,
                                                  parcela: row,
                                                  valores: valor,
                                                },
                                                on: {
                                                  "estorno:confirmar":
                                                    function ($event) {
                                                      _vm.dialogPagar = false
                                                    },
                                                  "estorno:cancelar": function (
                                                    $event
                                                  ) {
                                                    _vm.dialogPagar = false
                                                  },
                                                },
                                              })
                                            : row.pagamento_valor > 0
                                            ? _c("modal-pagar", {
                                                attrs: {
                                                  dialog: _vm.dialogPagar,
                                                  parcela: row,
                                                  valores: valor,
                                                },
                                                on: {
                                                  "pagamento:confirmar":
                                                    function ($event) {
                                                      _vm.dialogPagar = false
                                                    },
                                                  "pagamento:cancelar":
                                                    function ($event) {
                                                      _vm.dialogPagar = false
                                                    },
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm.mostrarCalculos
                                        ? _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("plano-stages", {
                                                attrs: { stages: row.stages },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              _vm.mostrarCalculos = !_vm.mostrarCalculos
                            },
                          },
                        },
                        [_vm._v(" Mostrar Cálculos ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }