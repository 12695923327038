import axios, { AxiosInstance, CancelTokenSource } from 'axios'
import { URL_BASE } from './Ambiente'
import { AllowMethods, HttpRequestPromise } from './Type'
import userService from '@/services/user/UserService'
import { commonModule } from '@/store/common'
import router from "@/router";
import loginService from "@/services/login/LoginService";

class HttpRequest {

  private axios: AxiosInstance
  private defaultErrorHandler: (error: any, context: any) => any

  constructor() {
    this.axios = axios.create()
    //TODO tratar melhor os erros
    this.defaultErrorHandler = (error: any, context: any) => {
      if(error.request.status === 401)
        loginService.logout()

      commonModule.showMessage({
        message: error.request.response.message ?? error.request.statusText,
        type: 'error'
      })
    }
  }

  public get<R = any>(url: string, params: any = {}, options: any = {}) {
    return this.request<R>('get', url, params, options)
  }

  public post<R = any>(url: string, params: any = {}, options: any = {}) {
    return this.request<R>('post', url, params, options)
  }

  public put<R = any>(url: string, params: any = {}, options: any = {}) {
    return this.request<R>('put', url, params, options)
  }

  public delete<R = any>(url: string, params: any = {}, options: any = {}) {
    return this.request<R>('delete', url, params, options)
  }

  private request<R>(method: AllowMethods, url: string, data: any = {}, options: any = {}): HttpRequestPromise<R> {
    url = URL_BASE + url
    options = this._defaultRequestOptions(options)

    if (userService.isLogged() && commonModule.auth) {
      options.headers.Authorization = 'Bearer ' + commonModule.auth.access_token
    }

    let params = {}

    if (method === 'get') {
      data = {}
      params = data
    }

    const source = axios.CancelToken.source()

    const request = this.axios({
      method,
      url,
      data,
      params,
      responseType: options.responseType,
      headers: options.headers,
      cancelToken: source.token,
    })

    return this._configureRequest(request, options, source)
  }

  public setDefaultErrorHandler(handler: (error: any) => void) {
    this.defaultErrorHandler = handler
  }

  private _defaultRequestOptions(options: any) {
    return {
      ...{
        defaultErrorHandler: true,
        headers: {},
        responseType: 'json',
      }, ...options,
    }
  }

  private _configureRequest<R>(request: any, options: any, source: CancelTokenSource): HttpRequestPromise<R> {

    if (options.defaultErrorHandler) {
      request.catch((error: any) => {
        if (options.defaultErrorHandler) {
          this._callDefaultErrorHandler(error, {request, options})
        }
        //throw error
      })
    }

    request.cancel = (msg: string = 'Operação cancelada pelo usuário') => {
      source.cancel()
    }

    return request
  }

  private _callDefaultErrorHandler(error: any, context: any = {}) {
    this.defaultErrorHandler(error, context)
  }
}

const http = new HttpRequest()
export default http
