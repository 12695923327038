var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "white--text bg-plano-orange",
                        attrs: { small: "" },
                        on: {
                          click: function ($event) {
                            _vm.show = true
                          },
                        },
                      },
                      on
                    ),
                    [
                      _vm._v(" Iniciar novo Plano "),
                      _c("v-icon", { attrs: { right: "", dark: "" } }, [
                        _vm._v(" mdi-plus "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Iniciar Plano de Recuperação")])]
      ),
      _c("modal", {
        attrs: {
          persistant: true,
          loading: _vm.loading,
          titulo: "Configurando o Plano de Recuperação",
          width: "1600",
        },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function () {
              return [
                _c(
                  "fieldset",
                  { staticClass: "fieldset-plano" },
                  [
                    _c("legend", [_vm._v("Processo")]),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "12" } },
                          [
                            _c("select-processos", {
                              attrs: { disabled: _vm.planoUpdate !== null },
                              model: {
                                value: _vm.plano.id_processo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.plano, "id_processo", $$v)
                                },
                                expression: "plano.id_processo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.isProcessoSelecionado()
                  ? _c(
                      "fieldset",
                      { staticClass: "fieldset-plano" },
                      [
                        _c("legend", [
                          _vm._v(
                            "Para qual empresa você deseja configurar o plano ?"
                          ),
                        ]),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("select-processo-empresas", {
                                  ref: "empresas",
                                  attrs: {
                                    "id-processo": _vm.plano.id_processo,
                                    multiple: "",
                                    "existe-plano":
                                      _vm.planoUpdate === null
                                        ? false
                                        : undefined,
                                  },
                                  model: {
                                    value: _vm.plano.id_empresa,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.plano, "id_empresa", $$v)
                                    },
                                    expression: "plano.id_empresa",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isEmpresaSelecionada() && _vm.isProcessoSelecionado()
                  ? [
                      _c(
                        "fieldset",
                        { staticClass: "fieldset-plano" },
                        [
                          _c("legend", [
                            _vm._v("Classes vinculadas a este Processo:"),
                          ]),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "col-10" },
                                _vm._l(_vm.classes, function (classe, index) {
                                  return _c("modal-credores", {
                                    key: index,
                                    attrs: {
                                      "classe-credor": classe,
                                      classes: _vm.classes,
                                    },
                                    on: {
                                      removeClass: function ($event) {
                                        return _vm.removeClass(classe)
                                      },
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "fieldset",
                        { staticClass: "fieldset-plano" },
                        [
                          _c("legend", [
                            _vm._v(
                              "Condições de pagamento vinculadas a este plano"
                            ),
                          ]),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("modal-condicao-pagamento", {
                                    attrs: {
                                      processo: _vm.processo,
                                      classes: "classes",
                                    },
                                  }),
                                  _c("div", { staticClass: "mb-3" }),
                                  _c("condicoes", {
                                    attrs: {
                                      processo: _vm.processo,
                                      condicoes: _vm.condicoes,
                                      classes: _vm.classes,
                                    },
                                  }),
                                  _vm.condicoes.length > 0
                                    ? _c("modal-distribuicao-valores", {
                                        attrs: {
                                          condicoes: _vm.condicoes,
                                          classes: _vm.classes,
                                          processo: _vm.processo,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "action",
            fn: function () {
              return [
                _c("div", { staticClass: "flex-grow-1" }),
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-2 bg-plano-cinza white--text",
                    attrs: { small: "" },
                    on: { click: _vm.limparDadosPlano },
                  },
                  [
                    _c("v-icon", [_vm._v("mdi-eraser")]),
                    _vm._v(" Limpar Dados "),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "bg-plano-orange white--text",
                    attrs: { small: "" },
                    on: { click: _vm.addPlano },
                  },
                  [
                    _c("v-icon", [_vm._v("mdi-plus")]),
                    _vm._v(" Lançar Condições do Plano "),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.show,
          callback: function ($$v) {
            _vm.show = $$v
          },
          expression: "show",
        },
      }),
      _c("modal-validacao-plano", {
        attrs: {
          show: _vm.showModalValidacaoPlano,
          credores: _vm.credores,
          classes: _vm.classes,
          condicoes: _vm.condicoes,
        },
        on: {
          "close-modal": function ($event) {
            _vm.showModalValidacaoPlano = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }