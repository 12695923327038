
import { assembleiaStore } from "@/store/assembleia/";
import { Component, Vue } from "vue-property-decorator";
import VotoService from "@/services/assembleia/voto";
import AssembleiaGraficosPresenca from "./AssembleiaGraficosPresenca.vue";
import AssembleiaListaPresenca from "./AssembleiaListaPresenca.vue";
import AssembleiaGraficosClasse from "./AssembleiaGraficosClasse.vue";
import AssembleiaClasse from "./AssembleiaClasse.vue";
import GraficoVotacao from "../charts/GraficoVotacao.vue";
import router from "@/router";
import {
  AssembleiaBase,
  AssembleiaCredor,
  AssembleiaResumo,
} from "../../services/assembleia/AssembleiaTypes";
import { broadcast } from "@/plugins/pusher";
import AssembleiaCredoresRecuperanda from "@/components/assembleia/AssembleiaCredoresRecuperanda.vue";
import AssembleiaListaPresencaVue from "./AssembleiaListaPresenca.vue";

@Component({
  components: {
    AssembleiaGraficosPresenca,
    AssembleiaClasse,
    GraficoVotacao,
    AssembleiaCredoresRecuperanda,
    AssembleiaListaPresenca,
    AssembleiaGraficosClasse,
  },
})
export default class DashboardRecuperanda extends Vue {
  public loading: boolean = true;
  public graficoPercentual: boolean = true;
  public showTable: boolean = true;
  public showTotal: boolean = false;
  public mostraDashboard: string = "presenca";
  async mounted(): Promise<void> {
    this.loading = true;
    const assembleiaId = Number(router.currentRoute.params.id);
    await assembleiaStore.getAssembleia(assembleiaId);
    await assembleiaStore.getCredores(assembleiaId);
    broadcast.subscribeAssembleia(60 * 1000);
    this.loading = false;
  }

  get assembleia(): AssembleiaBase | null {
    return assembleiaStore.assembleiaBase;
  }

  get credores(): AssembleiaCredor[] {
    return assembleiaStore.credores;
  }
  get resumo(): AssembleiaResumo[] {
    return VotoService.resumo(this.credores);
  }

  get ultimosPresentes(): AssembleiaCredor[] {
    return this.credores
      .filter((credor) => credor.presente)
      .sort((one, two) => (one.data > two.data ? -1 : 1))
      .slice(0, 10);
  }

  get resumoMostrar() {
    const allowed =
      this.mostraDashboard == "suspensao" ? ["Total"] : ["1", "2", "3", "4"];

    return Object.keys(this.resumo)
      .filter((key) => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.resumo[key];
        return obj;
      }, {});
  }

  get headers(): Array<any> {
    return [
      {
        text: "Nome",
        align: "start",
        value: "nome",
      },
      {
        text: "Valor",
        align: "end",
        value: "total",
      },
      {
        text: "Classe",
        align: "start",
        value: "classe",
      },
      {
        text: "Hora",
        align: "start",
        sortable: false,
        value: "data",
      },
    ];
  }
  public classeTitle(classe: string): string {
    return classe === "Total" ? classe : "Classe " + classe;
  }
}
