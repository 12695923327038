var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "bg-plano-black",
      attrs: { app: "" },
      model: {
        value: _vm.drawer,
        callback: function ($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer",
      },
    },
    [_c("account-info"), _c("menu-app")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }