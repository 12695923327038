var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "orange--text",
                        attrs: { small: "", icon: "" },
                        on: {
                          click: function ($event) {
                            _vm.show = true
                          },
                        },
                      },
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-eye")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Ver Plano de Configuração")])]
      ),
      _c("modal", {
        attrs: {
          titulo: "Visualização do Plano de Recuperação",
          width: "1500",
        },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-subtitle", [
                                      _vm._v("Identificador do Plano"),
                                    ]),
                                    _c("v-list-item-title", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.plano.plano_id),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-subtitle", [
                                      _vm._v("Processo:"),
                                    ]),
                                    _c("v-list-item-title", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.plano.themis),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { accordion: "" } },
                        _vm._l(_vm.plano.classes, function (classe, i) {
                          return _c(
                            "v-expansion-panel",
                            { key: i },
                            [
                              _c("v-expansion-panel-header", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      classe.nm_classe +
                                        " | Total a receber: R$ " +
                                        _vm.getTotalClasses(classe)
                                    ) +
                                    " "
                                ),
                              ]),
                              _c(
                                "v-expansion-panel-content",
                                _vm._l(classe.empresas, function (empresa, j) {
                                  return _c(
                                    "v-row",
                                    { key: j },
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              empresa.nm_empresa +
                                                " | Valor Total: R$ " +
                                                _vm.getValorTotalEmpresa(
                                                  empresa
                                                )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-data-table", {
                                            attrs: {
                                              items: empresa.credores,
                                              headers: _vm.headersCredores,
                                              search: _vm.searchCredores,
                                              "sort-by": "nm_credor",
                                              "calculate-widths": "",
                                              dense: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "top",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "v-container",
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "mx-4 mt-2",
                                                            attrs: {
                                                              dense: "",
                                                              outlined: "",
                                                              label:
                                                                "Pesquisar",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.searchCredores,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.searchCredores =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "searchCredores",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "item.empresa",
                                                  fn: function ({ item }) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.empresa
                                                              .nm_empresa
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.valor",
                                                  fn: function ({ item }) {
                                                    return [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "toCurrencyBRL"
                                                            )(item.valor)
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.acoes",
                                                  fn: function ({ item }) {
                                                    return [
                                                      _c(
                                                        "modal-condicao-credor",
                                                        {
                                                          attrs: {
                                                            planos: item.planos,
                                                          },
                                                        }
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ]
            },
            proxy: true,
          },
          {
            key: "action",
            fn: function () {
              return [
                _c("div", { staticClass: "flex-grow-1" }),
                _c(
                  "v-btn",
                  {
                    staticClass: "bg-plano-orange white--text",
                    attrs: { small: "" },
                    on: {
                      click: function ($event) {
                        _vm.showPlano = false
                      },
                    },
                  },
                  [
                    _c("v-icon", [_vm._v("mdi-close")]),
                    _vm._v(" Fechar Visualização "),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.show,
          callback: function ($$v) {
            _vm.show = $$v
          },
          expression: "show",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }