var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid grid-rows-2 grid-flow-col" }, [
    _c(
      "div",
      [
        _c("quill-editor", {
          ref: "myQuillEditor",
          staticClass: "quill-full-page mb-12",
          attrs: { options: _vm.editorOption },
          on: {
            blur: function ($event) {
              return _vm.onEditorBlur($event)
            },
            focus: function ($event) {
              return _vm.onEditorFocus($event)
            },
            ready: function ($event) {
              return _vm.onEditorReady($event)
            },
          },
          model: {
            value: _vm.texto,
            callback: function ($$v) {
              _vm.texto = $$v
            },
            expression: "texto",
          },
        }),
      ],
      1
    ),
    _vm._v(" " + _vm._s(_vm.texto) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }