import http from '@/common/HttpRequest'
import { Processo } from '@/services/processo/ProcessoTypes'

class ProcessoService {
  private readonly endpointBase: string = 'v1/processos'

  public async getProcessos(): Promise<Processo[]> {
    return await http
      .get<Processo[]>(this.endpointBase + '?search=tipo_id:2')
      .then((r: any) => r.data)
  }

  public async getProcesso(idProcesso: number): Promise<Processo> {
    return await http
      .get<Processo>(this.endpointBase +  '/' + idProcesso + '?include=credoresAssembleia')
      .then((r: any) => r.data)
  }
}

const processoService = new ProcessoService()
export default processoService
