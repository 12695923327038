
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ClasseCredor, ProcessoCredor } from '@/services/processo/ProcessoTypes'
import { DefaultHeaderTable } from '@/common/Type'
import Modal from '@/components/modal/Modal.vue'

@Component({
  components: { Modal },
})
export default class ModalCredores extends Vue {

  @Prop()
  public classeCredor!: ClasseCredor

  public headersCredores: DefaultHeaderTable[] = [
    { text: 'Nome Credor', value: 'nome', align: 'start' },
    { text: 'Valor a Receber', value: 'valor', align: 'start' }
  ]

  public credores: ProcessoCredor[] = []

  public searchCredores: string = ''
  public show: boolean = false

  public mounted(): void {
    this.credores = this.classeCredor.credores
  }

  public deleteClasseEstrategica(classe:ClasseCredor): void {
    this.$emit('removeClass', classe)
  }

}
