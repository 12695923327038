
import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator'
import { DefaultHeaderTable } from '@/common/Type'
import Modal from '@/components/modal/Modal.vue'
import { CondicaoPagamento } from '@/services/condicao/CondicaoTypes'
import { ClasseCredor, ProcessoCredor } from '@/services/processo/ProcessoTypes'
import DistribuicaoCondicaoValorService from "@/services/condicao/DistribuicaoCondicaoValorService"

@Component({
  components: { Modal },
})
export default class ModalValidacaoPlano extends Vue {

  @Prop()
  public condicoes!: CondicaoPagamento[]

  @Prop()
  public credores!: ProcessoCredor[]

  @Prop()
  public classes!: ClasseCredor[]

  @Prop()
  public show!: boolean

  public showModal: boolean = false

  public headersCredores: DefaultHeaderTable[] = [
    { text: 'Nome Credor', value: 'nome', align: 'start' },
    { text: 'Valor a Receber', value: 'valor', align: 'start' }
  ]

  public credoresSemPLano: ProcessoCredor[] = []

  @Watch('show')
  public onChangeShow(show: boolean): void {
    if (show) {
      this.popularCredoresSemPlano()
    }
    this.showModal = show
  }

  @Watch('showModal')
  public onChangeShowModal(show: boolean): void {
    if (!show) {
      this.closeModal()
    }
  }

  @Emit()
  public closeModal(): void {

  }

  public popularCredoresSemPlano(): void {
    this.credoresSemPLano = []
    const distribuicaoService = new DistribuicaoCondicaoValorService(this.classes, this.condicoes)
    const credorPLanos = distribuicaoService.montarDistribuicaoValores()

    for (const credor of this.credores) {
      const filterCredor = credorPLanos.filter((c) => c.id === credor.id)

      if (filterCredor.length === 0) {
        this.credoresSemPLano.push(credor)
      }
    }
  }

}
