var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c("modal", {
        attrs: { titulo: "Lista de Credores" },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mt-0 mb-0 subtitle-1",
                        attrs: { cols: "12" },
                      },
                      [_vm._v(" " + _vm._s(_vm.classeCredor.nome) + " ")]
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("v-data-table", {
                          attrs: {
                            items: _vm.credores,
                            headers: _vm.headersCredores,
                            search: _vm.searchCredores,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "top",
                              fn: function () {
                                return [
                                  _c("v-text-field", {
                                    staticClass: "mx-4",
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      label: "Pesquisar",
                                    },
                                    model: {
                                      value: _vm.searchCredores,
                                      callback: function ($$v) {
                                        _vm.searchCredores = $$v
                                      },
                                      expression: "searchCredores",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "item.valor",
                              fn: function ({ item }) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrencyBRL")(item.valor)
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.show,
          callback: function ($$v) {
            _vm.show = $$v
          },
          expression: "show",
        },
      }),
      _c(
        "v-chip",
        {
          staticClass: "ml-1",
          attrs: {
            "close-icon": "mdi-close-outline",
            color: "blue",
            outlined: "",
          },
        },
        [
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  _vm.show = true
                },
              },
            },
            [_vm._v(_vm._s(_vm.classeCredor.nome))]
          ),
          _vm.classeCredor.estrategica
            ? _c(
                "v-icon",
                {
                  staticClass: "ml-2",
                  attrs: { title: "Remover Classe", small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteClasseEstrategica(_vm.classeCredor)
                    },
                  },
                },
                [_vm._v(" mdi-delete ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }