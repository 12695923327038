/** @format */

import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import LoginAdmjudicial from "@/views/LoginAdmjudicial.vue";
import Condicao from "@/views/Condicao.vue";
import PlanoGestao from "@/views/PlanoGestao.vue";
import Periodicidade from "@/views/Periodicidade.vue";
import NotFound from "@/views/NotFound.vue";
import loginService from "@/services/login/LoginService";
import userService from "@/services/user/UserService";
import Credores from "@/views/Credores.vue";
import CredorDetalhes from "@/views/CredorDetalhes.vue";
import Assembleias from "@/components/assembleia/Assembleias.vue";
import Assembleia from "@/components/assembleia/Assembleia.vue";
import Dashboard from "@/components/assembleia/Dashboard.vue";
import DashboardPresenca from "@/components/assembleia/DashboardPresenca.vue";
import DashboardRecuperanda from "@/components/assembleia/DashboardRecuperanda.vue";
import LayoutPage from "@/components/rma/LayoutPage.vue";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      isFullPage: true,
    },
  },
  {
    path: "/login-admjudicial",
    name: "login-admjudicial",
    component: LoginAdmjudicial,
    meta: {
      isFullPage: true,
    },
  },
  {
    path: "/home",
    name: "home",
    component: Home,
  },
  {
    path: "/condicao",
    name: "condicao",
    component: Condicao,
    meta: {
      isFullPage: true,
    },
  },
  {
    path: "/plano-gestao",
    name: "plano-gestao",
    component: PlanoGestao,
    meta: {
      isFullPage: true,
    },
  },
  {
    path: "/periodicidade",
    name: "periodicidade",
    component: Periodicidade,
    meta: {
      isFullPage: true,
    },
  },
  {
    path: "/planos/:id",
    name: "credores",
    component: Credores,
    meta: {
      isFullPage: true,
    },
  },
  {
    path: "/credores/:id",
    name: "credores-detalhes",
    component: CredorDetalhes,
    meta: {
      isFullPage: true,
    },
  },
  {
    path: "/assembleias",
    name: "assembleias",
    component: Assembleias,
    meta: {
      isFullPage: true,
    },
  },
  {
    path: "/assembleias/:id",
    name: "assembleia",
    component: Assembleia,
    meta: {
      isFullPage: true,
    },
  },

  {
    path: "/assembleias/:id/dashboard",
    name: "assembleia-dashboard",
    component: Dashboard,
    meta: {
      isFullPage: true,
    },
  },
  {
    path: "/assembleias/:id/dashboard-presenca",
    name: "assembleia-presenca",
    component: DashboardPresenca,
    meta: {
      isFullPage: true,
    },
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound,
    meta: {
      isFullPage: true,
    },
  },
  {
    path: "/assembleias/:id/dashboard-recuperanda",
    name: "assembleia-recuperanda",
    component: DashboardRecuperanda,
    meta: {
      isFullPage: true,
    },
  },
  {
    path: "/rma/layout-page",
    name: "rma-layout",
    component: LayoutPage,
    meta: {
      isFullPage: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  loginService.tryRestoreAuthDataUser();
  next();
});

router.beforeEach((to, from, next) => {
  // verificação de rotas
  const semLogin = ["login", "login-admjudicial", "assembleia-recuperanda"];
  const isLogged: boolean = userService.isLogged();
  console.log(semLogin.includes(to.name || ""), to.name);
  if (!semLogin.includes(to.name || "")) {
    // acessando rotas do painel
    if (!isLogged) {
      console.log("redirecionar...");
      next({ name: "login" });
    }
  }

  next();
});

export default router;
