
import { Component, Prop, Vue } from "vue-property-decorator";
import CardValores from "@/components/plano-recuperacao/PlanoCredores/CardValores.vue";

@Component({
  components: { CardValores },
})
export default class CardCredor extends Vue {
  @Prop()
  public credor: any;

  @Prop()
  public valores: any;
}
