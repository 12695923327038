import Vue from 'vue'
import FormatterHelper from '@/helper/FormatterHelper'
import DateHelper from '@/helper/DateHelper'

Vue.filter('numberToPercent', (valor: number) => {
  return FormatterHelper.numberToPercent(valor)
})

Vue.filter('toCurrencyBRL', (valor: number | string) => {

  if (typeof valor === 'string') {
    valor = parseFloat(valor)
  }

  return FormatterHelper.currencyBRL(valor)
})

Vue.filter('toDtBr', (dt: string) => {
  return DateHelper.toDt(dt)
})
