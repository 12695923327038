
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import Modal from '@/components/modal/Modal.vue'
import FormCondicaoPagamento from './FormCondicaoPagamento.vue'
import {Processo} from '@/services/processo/ProcessoTypes'
import {eventBus} from '@/main'

@Component({
  components: { FormCondicaoPagamento, Modal },
})
export default class ModalCondicaoPagamento extends Vue {
  @Prop()
  public processo!: Processo

  @Prop({default: null})
  public condicao!: any

  @Prop({default: null})
  public indexUpdate?: number | null

  @Prop({default: false})
  public isUpdate?: boolean

  public show: boolean = false
  public form: any = null
  public formCondicaoPagamento: any = {}

  @Watch('show')
  public onChangeShow(): void {
    this.resetForm()
  }

  public async savePlano(): Promise<void> {
    if (this.form.validate()) {
      this.adicionarCondicao()
      this.show = false
    }
  }

  public adicionarCondicao(): void {
    eventBus.$emit('adicionarCondicao', {
      form: { ...this.formCondicaoPagamento },
      isUpdate: this.isUpdate,
      indexUpdate: this.indexUpdate,
    })
  }

  public formHandler(form: any): void {
    this.form = form
  }

  public resetForm(): void {
    eventBus.$emit('resetFormCondicao')

    if (this.form !== null) {
      this.form.resetValidation()
    }
  }
}
