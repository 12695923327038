
import { assembleiaStore } from "@/store/assembleia/";
import { Component, Vue } from "vue-property-decorator";
import VotoService from "@/services/assembleia/voto";
import AssembleiaGraficosPresenca from "./AssembleiaGraficosPresenca.vue";
import AssembleiaService from "@/services/assembleia/AssembleiaService";
import router from "@/router";
import {
  AssembleiaBase,
  AssembleiaCredor,
  AssembleiaResumo,
} from "../../services/assembleia/AssembleiaTypes";
import { broadcast } from "@/plugins/pusher";
import Print from "@/services/print";

@Component({
  components: { AssembleiaGraficosPresenca },
})
export default class DashboardPresenca extends Vue {
  public loading: boolean = true;
  public graficoPercentual: boolean = true;
  public output: string = '';
  public empresa: string = "";

  async mounted(): Promise<void> {
    this.loading = true;
    const assembleiaId = Number(router.currentRoute.params.id);
    await assembleiaStore.getAssembleia(assembleiaId);
    await assembleiaStore.getCredores(assembleiaId);
    broadcast.subscribeAssembleia(10*1000);
    this.loading = false;
  }

  get assembleia(): AssembleiaBase | null {
    return assembleiaStore.assembleiaBase;
  }

  get credores(): AssembleiaCredor[] {
    return assembleiaStore.credores;
  }

  get credoresFiltro(): AssembleiaCredor[] {
    let credores = assembleiaStore.credores;

    if (this.empresa !== "") {
      credores = credores.filter((credor) => credor.empresa === this.empresa);
    }
    return credores
  }

  get empresas(): Array<string> {
    return AssembleiaService.getEmpresas(this.credoresFiltro);
  }

  get empresaSelect(): Array<any> {
    let empresas = AssembleiaService.getEmpresas(this.credores)
      .sort()
      .map((empresa) => ({ value: empresa, text: empresa }));
    return [{ value: "", text: `Todas empresas` }, ...empresas];
  }

  get resumo(): AssembleiaResumo[] {
    return VotoService.resumo(this.credoresFiltro);
  }

  get ultimosPresentes(): AssembleiaCredor[] {
    return this.credores
      .filter((credor) => credor.presente)
      .sort((one, two) => (one.data > two.data ? -1 : 1))
      .slice(0, 10);
  }

  get headers(): Array<any> {
    return [
      {
        text: "Nome",
        align: "start",
        value: "nome",
      },
      {
        text: "Valor",
        align: "end",
        value: "total",
      },
      {
        text: "Classe",
        align: "start",
        value: "classe",
      },
      {
        text: "Hora",
        align: "start",
        sortable: false,
        value: "data",
      },
    ];
  }

  public async print(): Promise<void> {
    Print.el(this.$refs.printMe as HTMLCanvasElement)
  }
}
