/* eslint-disable */
<template>
    <v-dialog
      v-model="dialog"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          class="ml-2" 
        >
          Arquivos
        </v-btn>
      </template>
      <v-card>
       
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Arquivos</v-toolbar-title>
        </v-toolbar>
       <template>
          <v-row>
            <v-col>
              <v-alert type="error" v-if="messagemErro">
              {{ messagemErro }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <dashboard :uppy="uppy" :plugins="[]" :props="{ ...options}" />  
            </v-col>
            <v-col>
              <v-data-table
                v-if="arquivos"
                :headers="headers"
                :items="arquivos"
                :items-per-page="20"
                item-key="name"
                class="elevation-1"
              >

               <template v-slot:[`item.file_name`]="{ item }">
                <a :href="item.url" target="_blank">
                  {{ item.file_name }}
                </a>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                small
                icon
                class="red--text"
                @click="deletar(item)"
                target="_blank"
                title="Excluir"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
              </v-data-table>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-dialog>

</template>

<script>
import { Dashboard } from '@uppy/vue'
import { clientApi8 } from "../../services/api";

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import pt_BR from '@uppy/locales/lib/pt_BR'

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import store from '../../store'
import { commonModule } from '@/store/common'

export default {
  props: {      
    id: {type: Number},
    model: {type: String},
    colecao: {type: String},
  },
  components: {
    Dashboard
  },
  data () {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      armazenamento: {
        id: this.id,
        model: this.model,
        colecao: this.colecao,
      },
      arquivos: [],
      options: {
        note: "doc,docx,pdf,xls,jpg,png,gif,odt. Máximo 10mb."
      },
      headers: [
        {
          text: 'Arquivo',
          align: 'center',
          value: 'file_name',
        },
        
        {
          text: 'Extensão',
          align: 'start',
          value: 'extension',
        },
        {
          text: 'Tamanho',
          align: 'center',
          value: 'size',
        },

        {
          text: '',
          align: 'center',
          value: 'actions',
        },
      ],
    }
  },
  computed: {
    uppy() { 
      return new Uppy({
        locale: pt_BR,
        meta: {
          ...this.armazenamento
        },
        
      }).use(XHRUpload, {
        endpoint: process.env.VUE_APP_API_BASE_URL_PHP8 + '/api/v1/upload',
        headers: () => ({authorization: `Bearer ${commonModule.auth.access_token}`}),
      })
    },
    messagemErro() {
      return store.state.error;
    }
  },
  mounted(){
    this.uppy.on('complete', async () => {
      this.getFiles();
    }).on('upload-error', function (file, error, response) {
      console.log(response.body.message);
      store.commit('messageError', response.body.message)
    })
    this.getFiles();
  },
  methods: {
    async getFiles(){
      this.arquivos = await clientApi8.get(`/api/v1/upload/${this.armazenamento.id}/${encodeURI(this.armazenamento.model)}/${this.armazenamento.colecao}`)
        .then((response) => response.data.data) || [];
    },
    
    async deletar(item) {
      if (confirm(`Apagar arquivos?`)) {
        await clientApi8.delete(`/api/v1/upload/${this.armazenamento.id}/${item.uuid}`)
          .then((response) => response.data.data);
        this.getFiles();
      }
    },
  },
  beforeDestroy () {
    this.uppy.close()
  }
}
</script>