
import { Component, Vue } from "vue-property-decorator";
import AssembleiaResumoPainel from "@/components/assembleia/AssembleiaResumoPainel.vue";
import AssembleiaCredores from "@/components/assembleia/AssembleiaCredores.vue";
import Format from "@/services/format";
import AssembleiaService from "@/services/assembleia/AssembleiaService";
import VotoService from "@/services/assembleia/voto";
import DialogConfig from "@/components/assembleia/DialogConfig.vue";
import { assembleiaStore } from "@/store/assembleia/";
import {
  AssembleiaBase,
  AssembleiaCredor,
  AssembleiaCredorCsv,
  AssembleiaResumo,
} from "../../services/assembleia/AssembleiaTypes";
import i18n from "@/i18n";
import router from "@/router";
import { broadcast } from "@/plugins/pusher";
import _ from "lodash";
import FormatterHelper from "../../helper/FormatterHelper";

@Component({
  components: {
    DialogConfig,
    AssembleiaResumoPainel,
    AssembleiaCredores,
  },
})
export default class Assembleia extends Vue {
  public search: string = "";
  public presentes: boolean = false;
  public presentesParaVotos: boolean = false;
  public classe: string = "";
  public empresa: string = "";
  public loading: boolean = false;
  public drawer: boolean = false;

  async mounted(): Promise<void> {
    this.loading = true;
    const assembleiaId = Number(router.currentRoute.params.id);
    await assembleiaStore.getAssembleia(assembleiaId);
    await assembleiaStore.getCredores(assembleiaId);
    broadcast.subscribeAssembleia(60 * 1000);
    this.loading = false;
  }

  get credores(): AssembleiaCredor[] {
    return assembleiaStore.credores;
  }

  get assembleia(): AssembleiaBase | null {
    return assembleiaStore.assembleiaBase;
  }

  get credoresFiltro(): AssembleiaCredor[] {
    let credores = assembleiaStore.credores;
    if (this.presentes) {
      credores = credores.filter((credor) => credor.presente);
    }

    if (this.presentesParaVotos) {
      credores = credores.filter(
        (credor) => credor.presente && credor.voto == "I"
      );
    }

    if (this.classe !== "") {
      credores = credores.filter((credor) => credor.classe === this.classe);
    }

    if (this.empresa !== "") {
      credores = credores.filter((credor) => credor.empresa === this.empresa);
    }

    if (this.search === "") {
      return credores;
    }

    return (
      credores.filter(
        (credor) =>
          Format.search(credor.nome, this.search) ||
          Format.search(credor.email, this.search) ||
          Format.search(credor.observacao, this.search) ||
          Format.search(
            FormatterHelper.valorDecimalToBr(credor.total),
            this.search
          ) ||
          Format.search(
            Format.documentoLimpo(credor.documento),
            Format.documentoLimpo(this.search)
          )
      ) || []
    );
  }

  get credoresSort(): AssembleiaCredor[] {
    return _.sortBy(this.credoresFiltro, ["classe", "nome"]);
  }

  get classes(): Array<string> {
    return AssembleiaService.getClasses(this.credoresFiltro);
  }

  get empresas(): Array<string> {
    return AssembleiaService.getEmpresas(this.credoresFiltro);
  }

  get classeSelect(): Array<any> {
    let classes = AssembleiaService.getClasses(this.credores)
      .sort()
      .map((classe) => ({ value: classe, text: `Classe ${classe}` }));
    return [{ value: "", text: `Todas` }, ...classes];
  }

  get empresaSelect(): Array<any> {
    let empresas = AssembleiaService.getEmpresas(this.credores)
      .sort()
      .map((empresa) => ({ value: empresa, text: empresa }));
    return [{ value: "", text: `Todas empresas` }, ...empresas];
  }

  get groupClasse(): Array<AssembleiaCredor[]> {
    return AssembleiaService.groupClasse(this.credoresFiltro);
  }

  get resumo(): AssembleiaResumo[] {
    return VotoService.resumo(this.credoresFiltro);
  }

  get credoresCsv(): any {
    return this.credoresSort.map(function (credor: any): AssembleiaCredorCsv {
      let voto;
      switch (credor.voto) {
        case "A":
          voto = "ABSTENÇÂO";
          break;
        case "S":
          voto = "SIM";
          break;
        case "N":
          voto = "NÃO";
          break;
        default:
          voto = "INDEFINIDO";
          break;
      }
      return {
        presente: credor.presente ? "SIM" : "NÃO",
        voto: voto,
        classe: credor.classe,
        nome: credor.nome.toUpperCase(),
        empresa: credor.empresa.toUpperCase(),
        valor: "R$ " + i18n.n(credor.total, "csv"),
        ip: credor.ip,
        email: credor.email,
        senha: credor.senha,
        observacao: credor.observacao.toUpperCase(),
      };
    });
  }

  get labelsCsv(): any {
    return {
      presente: "PRESENTE",
      voto: "VOTO",
      classe: "CLASSE",
      nome: "NOME",
      empresa: "EMPRESA",
      valor: "VALOR",
      ip: "IP",
      email: "EMAIL",
      senha: "SENHA",
      observacao: "PROCURADOR",
    };
  }
}
