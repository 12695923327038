import { commonModule } from '@/store/common'

class UserService {
  public isLogged(): boolean {
    return commonModule.auth !== null
  }
}

const userService = new UserService()
export default userService
