
import {Component, Prop, Watch} from 'vue-property-decorator'
import ComponentSelectDefault from '@/components/form/select/ComponentSelectDefault'
import { DefaultSelect } from '@/common/Type'
import processoService from '@/services/processo/ProcessoService'
import { Processo } from '@/services/processo/ProcessoTypes'
import { eventBus } from '@/main'

@Component
export default class SelectProcessos extends ComponentSelectDefault {

  public processos: DefaultSelect[] = []
  public loading: boolean = false

  @Prop({default : false})
  public disabled!: boolean

  public listaProcessos: Processo[] = []

  public mounted(): void {
    this.getProcessos()
    this.checarPropsOpcionais()
  }

  public async getProcessos(): Promise<void> {
    this.loading = true
    this.listaProcessos = await processoService.getProcessos()

    for (const p of this.listaProcessos) {
      const label: string = p.empresa.nome + ' - ' + p.cnj + ' - ' + p.themis + ' - ' + p.tipo.nome
      this.processos.push({ label: label, value: p.id })
    }

    this.loading = false
  }

  get isDisabled() {
    return this.disabled;
  }

  @Watch('value')
  public onChangeValue(idProcesso: number | number[]): void {
    if (idProcesso) {
      eventBus.$emit('processoSelecionado', idProcesso)

      const processo = this.listaProcessos.filter((p) => p.id === idProcesso)[0]
      eventBus.$emit('getProcesso', { ...processo })
    }
  }
}
