import Html2Canvas  from "html2canvas";
const Print = {
  el(el) {
    const output = Html2Canvas(el).then(canvas => {
      var a = document.createElement('a');
      a.href = canvas.toDataURL();
      a.download = 'chart.png';
      a.click();
    });
  }
};
export default Print;