var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      ref: "nowMenu",
      attrs: {
        "nudge-right": 40,
        "close-on-content-click": false,
        transition: "scale-transition",
        "min-width": "290px",
        "offset-y": "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    attrs: {
                      "hide-details": _vm.hideDetailsInput,
                      disabled: _vm.disabledField,
                      color: "#015b41",
                      rules: _vm.rules,
                      label: _vm.label,
                      dense: "",
                      "append-icon": "mdi-calendar",
                      readonly: "",
                      outlined: "",
                    },
                    model: {
                      value: _vm.computedDateFormatted,
                      callback: function ($$v) {
                        _vm.computedDateFormatted = $$v
                      },
                      expression: "computedDateFormatted",
                    },
                  },
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.datepicker,
        callback: function ($$v) {
          _vm.datepicker = $$v
        },
        expression: "datepicker",
      },
    },
    [
      _c("v-date-picker", {
        attrs: { locale: "pt-br", "no-title": "" },
        on: {
          input: function ($event) {
            _vm.datepicker = false
          },
        },
        model: {
          value: _vm.editableDate,
          callback: function ($$v) {
            _vm.editableDate = $$v
          },
          expression: "editableDate",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }