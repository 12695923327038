var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "green--text",
                        attrs: { small: "", icon: "" },
                        on: {
                          click: function ($event) {
                            _vm.show = true
                          },
                        },
                      },
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-eye")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Visualizar Condições deste Credor")])]
      ),
      _c("modal", {
        attrs: {
          titulo: "Configuração de pagamento vinculada a este Credor",
          width: "800",
        },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("v-data-table", {
                          attrs: {
                            dense: "",
                            items: _vm.planos,
                            headers: _vm.headers,
                            "items-per-page": _vm.planos.length,
                            "hide-default-footer": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "item.valor",
                              fn: function ({ item }) {
                                return [
                                  item.descricao === "CONDIÇÃO TESTE"
                                    ? [
                                        _c("td", [
                                          _vm._v(
                                            "Saldo - " +
                                              _vm._s(
                                                _vm._f("toCurrencyBRL")(
                                                  item.valor
                                                )
                                              )
                                          ),
                                        ]),
                                      ]
                                    : [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("toCurrencyBRL")(
                                                item.valor
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "action",
            fn: function () {
              return [
                _c("div", { staticClass: "flex-grow-1" }),
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-2 bg-plano-cinza white--text",
                    attrs: { small: "" },
                    on: {
                      click: function ($event) {
                        _vm.show = false
                      },
                    },
                  },
                  [
                    _vm._v(" Fechar "),
                    _c("v-icon", { staticClass: "ml-1" }, [
                      _vm._v("mdi-close"),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.show,
          callback: function ($$v) {
            _vm.show = $$v
          },
          expression: "show",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }