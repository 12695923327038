/** @format */

const documentoRegexp = /[|/\\.-]+/g;

import humanFormat from "human-format";
// Custom scales can be created!
var numberScale = new humanFormat.Scale({
  mil: 1000,
  mi: 1000000,
  bi: 1000000000,
  tri: 1000000000000,
});

const Format = {
  numberHuman: (number) =>
    humanFormat(number, { scale: numberScale }).replace(".", ","),
  documentoLimpo: (string) => string.replace(documentoRegexp, ""),
  removeAcentos: (string) =>
    string.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  normalizeSearch: (string) => string ? string.toLowerCase() : "",
  search: (string, search) =>
    Format.normalizeSearch(string).indexOf(Format.normalizeSearch(search)) !==
    -1,
    
};

export default Format;
