/** @format */
import {
  getModule,
  Module,
  Mutation,
  Action,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import {
  AssembleiaCredor,
  AssembleiaBase,
  AssembleiaPresencaCreate,
  AssembleiaPresencaTodosCreate,
  AssembleiaVotoCreate,
  AssembleiaObservacaoCreate,
} from "@/services/assembleia/AssembleiaTypes";
import AssembleiaService from "@/services/assembleia/AssembleiaService";

@Module({
  name: "AssembleiaStore",
  namespaced: true,
  store,
  dynamic: true,
})
class AssembleiaStoreModule extends VuexModule {
  public assembleiaAberta: boolean = false;
  public votacaoAberta: boolean = false;
  public credores: AssembleiaCredor[] = [];
  public assembleiaBase: AssembleiaBase | null = null;
  public assembleias: AssembleiaBase[] = [];

  @Mutation
  public setAssembleia(assembleia: AssembleiaBase): void {
    this.assembleiaBase = assembleia;
  }

  @Mutation
  public setAssembleiaAberta(flag): void {
    this.assembleiaAberta = flag;
  }

  @Mutation
  public setVotacaoAberta(flag): void {
    this.votacaoAberta = flag;
  }
  @Mutation
  public setCredores(credores: AssembleiaCredor[]): void {
    this.credores = credores;
  }

  @Mutation
  public setAssembleias(assembleias: AssembleiaBase[]): void {
    this.assembleias = assembleias;
  }

  @Mutation
  public setVoto(voto: AssembleiaVotoCreate): void {
    const index = this.credores.findIndex((_credor) => _credor.id === voto.id);

    if (index >= 0) {
      this.credores[index].voto = voto.opcao;
    }
  }

  @Mutation
  public setPresenca(presenca: AssembleiaPresencaCreate): void {
    const index = this.credores.findIndex(
      (_credor) => _credor.id === presenca.voto_id
    );
    if (index >= 0) {
      this.credores[index].presente = presenca.presente;
    }
  }

  @Mutation
  public setVotoTodos(voto: AssembleiaVotoCreate): void {
    for (let _i = 0; _i < this.credores.length; _i++) {
      this.credores[_i].voto = voto.opcao;
    }
  }

  @Mutation
  public setPresencaTodos(presencaTodos: AssembleiaPresencaTodosCreate): void {
    for (let _i = 0; _i < this.credores.length; _i++) {
      this.credores[_i].presente = presencaTodos.presente;
    }
  }

  @Action
  public async getCredores(assembleiaId: number): Promise<void> {
    const credores = await AssembleiaService.buscarCredores(assembleiaId);
    this.context.commit("setCredores", credores);
  }

  @Action
  public async getAssembleias(): Promise<void> {
    const assembleiasResposta = await AssembleiaService.index();
    this.context.commit("setAssembleias", assembleiasResposta);
  }

  @Action
  public async getAssembleia(id): Promise<void> {
    const assembleiaResposta = await AssembleiaService.show(id);
    this.context.commit("setAssembleia", assembleiaResposta);
  }

  @Action
  public async votar(voto: AssembleiaVotoCreate): Promise<void> {
    const assembleiaState = this.context.state["assembleiaBase"];
    if (!assembleiaState) {
      return;
    }
    const votoResult = await AssembleiaService.votar(assembleiaState.id, voto);
    this.context.commit("setVoto", votoResult);
  }

  @Action
  public async marcarPresenca(
    presenca: AssembleiaPresencaCreate
  ): Promise<void> {
    const assembleiaState = this.context.state["assembleiaBase"];
    if (!assembleiaState) {
      return;
    }
    const presencaResult = await AssembleiaService.marcarPresenca(
      assembleiaState.id,
      presenca
    );
    this.context.commit("setPresenca", presencaResult);
  }

  @Action
  public async salvarObservacao(
    observacao: AssembleiaObservacaoCreate
  ): Promise<void> {
    const assembleiaState = this.context.state["assembleiaBase"];
    if (!assembleiaState) {
      return;
    }
    const presencaResult = await AssembleiaService.salvarObservacao(
      assembleiaState.id,
      observacao
    );
    this.context.commit("setPresenca", presencaResult);
  }

  @Action
  public async votarTodos({opcao, classeAssembleia}): Promise<void> {
    console.log(classeAssembleia);
    const assembleiaState = this.context.state["assembleiaBase"];
    if (!assembleiaState) {
      return;
    }
    const voto = {
      assembleia_id: assembleiaState.id,
      opcao: opcao,
      classe: classeAssembleia
    };
    await AssembleiaService.votarTodos(voto);
    this.context.dispatch("getCredores", assembleiaState.id);
  }

  @Action
  public async marcarPresencaTodos({ presente, classeAssembleia} ): Promise<void> {
    console.log(classeAssembleia)
    const assembleiaState = this.context.state["assembleiaBase"];
    if (!assembleiaState) {
      return;
    }
    console.log(classeAssembleia)
    const presenca = {
      assembleia_id: assembleiaState.id,
      presente: presente,
      classe: classeAssembleia,
    };
    await AssembleiaService.marcarPresencaTodos(presenca);
    this.context.dispatch("getCredores", assembleiaState.id);
  }

  @Action
  public async carregaAssembleias(): Promise<void> {
    this.context.dispatch("getAssembleias");
  }
}

export const assembleiaStore = getModule(AssembleiaStoreModule);
