/** @format */

import http from "@/common/HttpRequest";
import _ from "lodash/lodash";
import {
  AssembleiaBase,
  AssembleiaCredor,
  AssembleiaObservacao,
  AssembleiaObservacaoCreate,
  AssembleiaPresenca,
  AssembleiaPresencaCreate,
  AssembleiaPresencaTodosCreate,
  AssembleiaVoto,
  AssembleiaVotoCreate,
  AssembleiaVotoTodosCreate,
} from "@/services/assembleia/AssembleiaTypes";

class Assembleia {
  private readonly endpointBase: string = "v1/assembleia";

  public async buscarCredores(
    assemleiaId: number
  ): Promise<AssembleiaCredor[]> {
    return await http
      .get(this.endpointBase + "/" + assemleiaId + "/votos")
      .then((response: any) => response.data);
  }

  public async votar(
    assembleiaId: number,
    voto: AssembleiaVotoCreate
  ): Promise<AssembleiaVoto> {
    const uri = this.endpointBase + "/" + assembleiaId + "/votar";
    return await http
      .post<AssembleiaVoto>(uri, voto)
      .then((response: any) => response.data);
  }

  public async marcarPresenca(
    assembleiaId: number,
    presenca: AssembleiaPresencaCreate
  ): Promise<AssembleiaPresenca> {
    const uri = this.endpointBase + "/" + assembleiaId + "/presenca";
    return await http
      .post<AssembleiaPresenca>(uri, presenca)
      .then((response: any) => response.data);
  }

  public async salvarObservacao(
    assembleiaId: number,
    observacao: AssembleiaObservacaoCreate
  ): Promise<AssembleiaObservacao> {
    const uri = this.endpointBase + "/" + assembleiaId + "/observacao";
    return await http
      .post<AssembleiaObservacao>(uri, observacao)
      .then((response: any) => response.data);
  }

  public async index(): Promise<Assembleia[]> {
    const uri = this.endpointBase;
    return await http.get(uri).then((response: any) => response.data);
  }

  public async show(id: number): Promise<AssembleiaBase> {
    const uri = this.endpointBase;
    return await http
      .get(`${uri}/${id}`)
      .then((response: any) => response.data);
  }

  public async marcarPresencaTodos(
    presenca: AssembleiaPresencaTodosCreate
  ): Promise<any> {
    console.log(presenca);
    const uri =
      this.endpointBase + "/" + presenca.assembleia_id + "/presenca-todos";
    return await http
      .post<AssembleiaPresencaTodosCreate>(uri, presenca)
      .then((response: any) => response.data);
  }

  public async votarTodos(voto: AssembleiaVotoTodosCreate): Promise<any> {
    console.log(voto);
    const uri = this.endpointBase + "/" + voto.assembleia_id + "/votar-todos";
    return await http
      .post<AssembleiaPresencaTodosCreate>(uri, voto)
      .then((response: any) => response.data);
  }

  getClasses(credores: AssembleiaCredor[]): Array<string> {
    return [
      ...new Set(credores.map((credor: AssembleiaCredor) => credor.classe)),
    ];
  }

  getEmpresas(credores: AssembleiaCredor[]): Array<string> {
    return [
      ...new Set(credores.map((credor: AssembleiaCredor) => credor.empresa)),
    ];
  }

  //credores
  groupClasse = (credores: AssembleiaCredor[]): Array<AssembleiaCredor[]> =>
    _(credores)
      .groupBy("classe")
      .value();
}

const AssembleiaService = new Assembleia();
export default AssembleiaService;
