
import {Component, Vue, Watch} from "vue-property-decorator";
import PContent from "@/components/layout/PContent.vue";
import { DefaultHeaderTable } from "@/common/Type";
import ModalPlanoRecuperacaoAdd from "@/components/plano-recuperacao/ModalPlanoRecuperacaoAdd.vue";
import { PlanoRecuperacaoData } from "@/services/plano-recuperacao/PlanoRecuperacaoTypes";
import Modal from "@/components/modal/Modal.vue";
import ModalCondicaoCredor from "@/components/plano-recuperacao/ModalCondicaoCredor.vue";
import ModalPlanoRecuperacao from "@/components/plano-recuperacao/ModalPlanoRecuperacao.vue";
import ModalParcelas from "@/components/plano-recuperacao/ModalParcelas.vue";
import planoRecuperacaoService from "@/services/plano-recuperacao/PlanoRecuperacaoService";
import SelectProcessos from "@/components/form/select/SelectProcessos.vue";
import SelectProcessoEmpresas from "@/components/form/select/SelectProcessoEmpresas.vue";
import ModalPlanoDetalhes from "@/components/plano-recuperacao/ModalPlanoDetalhes.vue";
import {eventBus} from "@/main";
import {Processo} from "@/services/processo/ProcessoTypes";
import {commonModule} from "@/store/common";
import {assembleiaStore} from "@/store/assembleia";

@Component({
  components: {
    ModalPlanoDetalhes,
    ModalParcelas,
    ModalPlanoRecuperacao,
    ModalCondicaoCredor,
    Modal,
    ModalPlanoRecuperacaoAdd,
    PContent,
    SelectProcessos,
    SelectProcessoEmpresas,
  },
})
export default class PlanoGestao extends Vue {
  public loading: boolean = false;
  public search: string = "";

  public filtro: any = {
    id_processo: null,
    id_empresa: [],
  };

  public headers: DefaultHeaderTable[] = [
    { value: "created_at", text: "Data", align: "start" },
    { value: "processo_id", text: "ID do Processo", align: "start" },
    { value: "plano_condicoes", text: "Condições", align: "start" },
    //{ value: "empresas[0].nome", text: "Empresa", align: "start" },
    { value: "acoes", text: "Ações", sortable: false, align: "center" },
  ];

  public planosRecuperacao: PlanoRecuperacaoData[] = [];
  public planoSelecionado: PlanoRecuperacaoData | null = null;
  public showModalPlano : boolean = false;
  public showModalEdit : boolean = false;

  public clickPlano(plano : any) {
    this.planoSelecionado = plano;
    this.showModalPlano = true;
  }

  public editPlano(plano : any) {
    this.planoSelecionado = plano;
    this.showModalEdit = true;
  }

  public deletePlano(plano: any) {
    if (confirm("Tem certeza que deseja excluir o plano? Esta ação é irreversível")) {
      planoRecuperacaoService.delete(plano.id);
      this.getPlanosRecuperacao();
    }
    return false;
  }

  public isProcessoSelecionado(): boolean {
    return this.filtro.id_processo !== null;
  }

  public isEmpresaSelecionada(): boolean {
    return this.filtro.id_empresa.length > 0;
  }

  public mounted(): void {
    this.getPlanosRecuperacao();
    //this.planoSelecionado = this.planosRecuperacao[0];
  }

  public async getPlanosRecuperacao(): Promise<void> {
    this.loading = true;
    this.planosRecuperacao = await planoRecuperacaoService.getPlanosRecuperacao();
    this.loading = false;
  }

  public addPlano(): void {
    this.getPlanosRecuperacao();
  }

  public mostrarCredor(id: string): void {
    this.$router.push({ name: "credores", params: { id: id } });
  }

  @Watch('filtro.id_processo')
  public onChangeFilterIdProcesso() {
    this.filtro.id_empresa = [];
  }

  get planosFiltrados(): PlanoRecuperacaoData[] {

    if(this.filtro.id_processo === null || this.filtro.id_empresa.length === 0) {
      return [];
    }

    return this.planosRecuperacao.filter((item : PlanoRecuperacaoData) => (
      item.processo_id === this.filtro.id_processo
      && item.empresas.filter(empresa => this.filtro.id_empresa.includes(empresa.id)).length > 0
    ))
  }
}
