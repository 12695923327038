
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { DefaultSelect } from '@/common/Type'
import periodicidadeService from '@/services/periodicidade/PeriodicidadeService'
import { PeriodicidadeData } from '@/services/periodicidade/PeriodicidadeTypes'

@Component
export default class SelectPeriodicidades extends Vue {

  @Prop()
  public rules?: []

  @Prop()
  public hideDetails?: any

  @Prop()
  public multiple?: any

  @Prop()
  public value: any

  public selecionado: any = this.value

  public selectOptions = {
    multiple: false,
    hideDetails: true,
    rules: [],
  }

  public periodicidades: DefaultSelect[] = []
  public loading: boolean = true
  public disabled: boolean = false

  public mounted(): void {
    this.getPeriodicidades()
    this.checarPropsOpcionais()
  }

  public periodicidadeChanged(val: any): void {
    const periodicidade: any = this.periodicidades.filter((e) => {
      return e.value === val
    })
    this.$emit('label', periodicidade)
  }

  public async getPeriodicidades(): Promise<void> {
    const periodicidades: PeriodicidadeData[] = await periodicidadeService.getPeriodos()

    for (const p of periodicidades) {
      this.periodicidades.push({ label: p.descricao, value: p.id })
    }

    if (periodicidades.length === 0) {
      this.disabled = true
    }

    this.loading = false
  }

  @Watch('value')
  public onValueChanged(val: any): void {
    this.selecionado = val
  }

  @Watch('selecionado')
  public onSelecionadoChanged(val: any): void {
    this.$emit('input', val)
    this.checarErrosValidacao(val)
  }

  public checarErrosValidacao(val: any): void {
    if (this.rules && this.rules.length > 0) {
      this.$emit('errors', val)
    }
  }

  public checarPropsOpcionais(): void {
    if (typeof this.multiple !== 'undefined') {
      this.selectOptions.multiple = true
    }

    if (typeof this.rules !== 'undefined') {
      this.selectOptions.rules = this.rules
    }

    if (typeof this.hideDetails !== 'undefined') {
      this.selectOptions.hideDetails = false
    }
  }
}
