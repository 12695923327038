var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    !_vm.loading
      ? _c(
          "div",
          [
            _c("v-text-field", {
              attrs: { label: "Observação" },
              on: {
                blur: function ($event) {
                  return _vm.salvarObservacao()
                },
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.salvarObservacao()
                },
              },
              model: {
                value: _vm.credor.observacao,
                callback: function ($$v) {
                  _vm.$set(_vm.credor, "observacao", $$v)
                },
                expression: "credor.observacao",
              },
            }),
          ],
          1
        )
      : _vm.loading
      ? _c(
          "div",
          { staticClass: "text-center" },
          [
            _c("v-progress-circular", {
              attrs: { color: "primary", indeterminate: "" },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }