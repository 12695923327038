import axios from "axios";
import store from "../store";
import { commonModule } from '@/store/common'

const clientApi = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

// Add a request interceptor
clientApi.interceptors.request.use(async function (config) {
  store.commit('clearError');
  const token = commonModule.auth.access_token;
  config.headers.Authorization = "bearer " + token;
  return config;
});

clientApi.interceptors.response.use(
  res => res,
  err => {
    if (err) {
      store.commit('messageError', err.response.data.message)
    }
  }
)

const clientApi8 = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL_PHP8,
});

// Add a request interceptor
clientApi8.interceptors.request.use(async function (config) {
  store.commit('clearError');
  const token = commonModule.auth.access_token;
  config.headers.Authorization = "bearer " + token;
  return config;
});

clientApi8.interceptors.response.use(
  res => res,
  err => {
    if (err) {
      store.commit('messageError', err.response.data.message)
    }
  }
)

export { clientApi, clientApi8 };
