import { TimeInterval } from '@/services/periodicidade/PeriodicidadeTypes'
import moment from 'moment-interval'

export default new class DateHelper {
  public createIntervalString(interval: TimeInterval): string {
    const duration = { years: interval.anos, months: interval.meses, days: interval.dias }
    return moment.duration(duration).toISOString()
  }

  public parseIntervalStringToTimeInterval(interval: string): TimeInterval {
    const durationInterval = moment.duration(interval)._data
    return { anos: +durationInterval.years, meses: +durationInterval.months, dias: +durationInterval.days }
  }

  public toDt(dt: string): string {
    return dt.split('-').reverse().join('/')
  }

  public strToDt(dt: string): string {
    return dt.split('/').reverse().join('-')
  }

  public isValidDate(date: string): boolean {
    return moment(date).isValid();
  }
}()
